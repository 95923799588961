import { updateOrderConfirmFlag } from '@/services/orderBook';

import {
  makeState,
  makeReducer,
  makeDealingType,
  makeFailType,
  makeSuccType,
} from '@/utils/modelUtils';

export const ORDER_CONFIRM_FLAG = 'orderConfirmFlag';

const CommonModel = {
  namespace: 'common',
  state: {
    collapsed: false,
    notices: [],
    symbols: [],
    currencies: [],
    isDisplayKycTip: false,
    unReadMsgCount: 0,
    ...makeState(ORDER_CONFIRM_FLAG),
  },
  effects: {
    *updateOrderConfirmFlag({ payload }, { put, call }) {
      const response = yield call(updateOrderConfirmFlag, payload);
      yield put({
        type: makeDealingType(ORDER_CONFIRM_FLAG),
      });

      if (response.status === 'success') {
        yield put({
          type: makeSuccType(ORDER_CONFIRM_FLAG),
          payload: response.data,
        });
        return { error: null, data: response.data };
      }

      const error = response.code || response.message || '-';
      yield put({
        type: makeFailType(ORDER_CONFIRM_FLAG),
        payload: error,
      });
      return { error, data: null };
    },
  },
  reducers: {
    ...makeReducer(ORDER_CONFIRM_FLAG),
  },
};
export default CommonModel;
