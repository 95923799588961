import React from 'react';
import { ConfigProvider, Empty } from 'antd';
import originRenderEmpty from 'antd/es/config-provider/renderEmpty';
import styles from './style.less';

import emptyImg from '@/assets/custom_empty.svg';
import darkEmptyImg from '@/assets/custom_dark_empty.svg';

const COMPONENT_TABLE = 'Table';

// eslint-disable-next-line no-unused-vars, arrow-body-style
const customRenderEmpty = (
  description = 'No Data',
  dark = false,
  // eslint-disable-next-line no-unused-vars
  componentName = COMPONENT_TABLE
) => {
  let props = {
    className: dark ? styles.themeDark : styles.themeLight,
    image: dark ? darkEmptyImg : emptyImg,
  };

  if (description) {
    props = {
      ...props,
      description,
    };
  }

  return <Empty {...props} />;
};

export default props => {
  const { componentNames = [COMPONENT_TABLE], dark = false, description = null, children } = props;

  const renderEmpty = componentName => {
    if (!Array.isArray(componentNames)) return originRenderEmpty(componentName);

    const find = componentNames.some(cName => cName === componentName);
    if (find) return customRenderEmpty(description, dark, componentName);
    return originRenderEmpty(componentName);
  };

  return <ConfigProvider renderEmpty={renderEmpty}>{children}</ConfigProvider>;
};

export { customRenderEmpty };
