/**
 *
 * orderHistoryBox
 *
 */

import React from 'react';
import { FormattedMessage, formatMessage } from 'umi-plugin-react/locale';
// import Moment from 'react-moment';
import moment from 'moment-timezone';
import { Spin } from 'antd';

// import Table from '@/components/Table';
// import Empty from '@/components/Empty';

import { customRenderEmpty } from '@/components/RenderEmptyConfigProvider';
import {
  //  isZeroValue,
  isMarketPrice,
} from '@/utils/valueUtils';
import styles from './style.less';
// import messages from './messages';

// const MARKET_PRICE = formatMessage({ id: 'component.common.marketOrder' });

const getMarketPriceMsg = () => formatMessage({ id: 'component.common.marketOrder' });

// eslint-disable-next-line no-unused-vars
const Header = props => (
  <thead className={styles.thead}>
    <tr>
      <th className="date" title={formatMessage({ id: 'pages.OpenOrderPage.date' })}>
        <FormattedMessage id="pages.OpenOrderPage.date" />
      </th>
      <th className="symbol" title={formatMessage({ id: 'pages.OpenOrderPage.symbol' })}>
        <FormattedMessage id="pages.OpenOrderPage.symbol" />
      </th>
      <th className="type" title={formatMessage({ id: 'pages.OpenOrderPage.type' })}>
        <FormattedMessage id="pages.OpenOrderPage.type" />
      </th>
      <th className="side" title={formatMessage({ id: 'pages.OpenOrderPage.side' })}>
        <FormattedMessage id="pages.OpenOrderPage.side" />
      </th>
      <th className="price" title={formatMessage({ id: 'pages.OpenOrderPage.price' })}>
        <FormattedMessage id="pages.OpenOrderPage.price" />
      </th>
      <th
        className="averagePrice"
        title={formatMessage({ id: 'pages.OpenOrderPage.averagePrice' })}
      >
        <FormattedMessage id="pages.OpenOrderPage.averagePrice" />
      </th>
      <th className="amount" title={formatMessage({ id: 'pages.OpenOrderPage.amount' })}>
        <FormattedMessage id="pages.OpenOrderPage.amount" />
      </th>
      <th className="finished" title={formatMessage({ id: 'pages.OpenOrderPage.finished' })}>
        <FormattedMessage id="pages.OpenOrderPage.finished" />
      </th>
      <th className="filled" title={formatMessage({ id: 'pages.OpenOrderPage.filled' })}>
        <FormattedMessage id="pages.OpenOrderPage.filled" />
        (%)
      </th>
      <th className="triggerOn" title={formatMessage({ id: 'pages.OpenOrderPage.triggerOn' })}>
        <FormattedMessage id="pages.OpenOrderPage.triggerOn" />
      </th>
      <th className="status" title={formatMessage({ id: 'pages.OpenOrderPage.status' })}>
        <FormattedMessage id="pages.OpenOrderPage.status" />
      </th>
    </tr>
  </thead>
);

const DataTable = ({ orders }) => (
  <table className="order-box-table">
    <Header />
    <tbody className={styles.tbody}>
      {orders.map(item => (
        <tr key={item.id}>
          <td className="date">
            {/* <Moment unix format="HH:mm:ss DD/MM/YYYY">
              {item.createdAt / 1000}
            </Moment> */}
            {moment(item.createdAt).format('HH:mm:ss DD/MM/YYYY')}
          </td>
          <td className="symbol">{item.symbol}</td>
          <td className="type">{formatMessage({ id: `pages.OpenOrderPage.type.${item.type}` })}</td>
          <td className="side">{formatMessage({ id: `pages.OpenOrderPage.side.${item.side}` })}</td>
          <td className="price">{isMarketPrice(item.type) ? getMarketPriceMsg() : item.price}</td>
          <td className="averagePrice">{item.averagePrice}</td>
          <td className="amount">{item.amount}</td>
          <td className="finished">{item.filledAmount}</td>
          <td className="filled">{item.filledRate || '0.00'}%</td>
          <td className="triggerOn">{item.triggerOn}</td>
          <td className="status">
            {formatMessage({ id: `pages.OpenOrderPage.status.${item.status}` })}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

/* eslint-disable react/prefer-stateless-function */
export default ({ orders, loading, isFixedHeader = false }) => (
  <div style={{ height: '100%', width: '100%', display: 'flex' }}>
    <Spin spinning={loading} wrapperClassName="order-list-spin">
      {isFixedHeader && (
        <div className="fixed-header-wrapper">
          <table className="order-box-table fixed-header">
            <Header />
          </table>
          <div className="scroll-pane">
            <DataTable orders={orders} />
            {orders.length === 0 && (
              <div className="none-data">
                {customRenderEmpty(formatMessage({ id: 'pages.OrderHistoryPage.empty' }), true)}
              </div>
            )}
          </div>
        </div>
      )}

      {!isFixedHeader && <DataTable orders={orders} />}

      {!isFixedHeader && orders.length === 0 && (
        <div className="none-data">
          {customRenderEmpty(formatMessage({ id: 'pages.OrderHistoryPage.empty' }), true)}
        </div>
      )}
    </Spin>
  </div>
);
