import React from 'react';
import { pathOr } from 'ramda';
// import router from 'umi/router';
import { message, /* notification, */ Button } from 'antd';
import Counterdown from 'react-countdown-now';
import { formatMessage } from 'umi-plugin-react/locale';
import get from 'lodash/get';
import {
  signIn,
  signOut,
  tokenRefresh,
  getProfile,
  gaSignIn,
  gaCheck,
  seTimezone,
  cookieChooseSet,
} from '@/services/api';
import { ctor, log, showResponseError, localeMessage } from '@/utils/utils';
import ModalContainer from '@/components/ModalContainer';
import styles from '@/components/ModalContainer/style.less';

let expireTimeout = null;
let expiresMsgTimeout = null;
let modal = null;

function showExpiresMsg(millisecond) {
  const refresh = () => {
    // notification.close('tokenExpireNotify');
    modal.destroy();
    // eslint-disable-next-line no-underscore-dangle
    window.g_app._store.dispatch({
      type: 'auth/tokenRefresh',
    });
    clearTimeout(expiresMsgTimeout);
  };

  function Content() {
    return (
      <div className={styles.content}>
        <div className={styles.couterClock}>
          <div className={styles.clock}>
            <div className={styles.minuteHand} />
            <div className={styles.secondHand} />
          </div>
          <div className={styles.hourglass}>
            <div className={styles.hourglassTop} />
            <div className={styles.hourglassBottom} />
            <div className={styles.hourglassline} />
          </div>
        </div>
        {formatMessage({ id: 'message.warning.tokenExpire' })}
        <div className={styles.counter}>
          <Counterdown
            zeroPadTime={2}
            date={Date.now() + millisecond}
            renderer={({ minutes, seconds }) => (
              <span>
                {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </span>
            )}
          />
        </div>
        <div>
          <Button type="primary" onClick={() => refresh()}>
            {formatMessage({ id: 'button.refresh' })}
          </Button>
        </div>
      </div>
    );
  }

  // notification.warning({
  //   key: 'tokenExpireNotify',
  //   message: formatMessage({ id: 'message.title.tip' }),
  //   duration: null,
  //   description: <Content />,
  // });
  modal = ModalContainer({
    type: 'info',
    width: 503,
    className: styles.tokenModal,
    title: formatMessage({ id: 'message.title.tip' }),
    content: <Content />,
    hasBtn: false,
  });

  clearTimeout(expiresMsgTimeout);
  expiresMsgTimeout = setTimeout(() => {
    // notification.close('tokenExpireNotify');
    modal.destroy();
    let expires = localStorage.getItem('MATRIX_TOKEN_EXPIRES');
    if (!expires) {
      return;
    }
    expires = new Date(Number(expires));
    const diff = expires - new Date();
    if (diff <= 0) {
      // eslint-disable-next-line no-underscore-dangle
      window.g_app._store.dispatch({
        type: 'auth/emptyTokenAndProfile',
      });
    }
  }, millisecond);
}

function startTokenExpires() {
  // const oneMinute = 60 * 1000;
  const limitTime = 5 * 60 * 1000;
  let expires = localStorage.getItem('MATRIX_TOKEN_EXPIRES');
  if (!expires) {
    return;
  }
  expires = new Date(Number(expires));
  const diff = expires - new Date();
  // if (diff >= oneMinute && diff <= limitTime) {
  if (diff < 10000) {
    setTimeout(() => {
      // eslint-disable-next-line no-underscore-dangle
      window.g_app._store.dispatch({
        type: 'auth/emptyTokenAndProfile',
      });
    }, 0);
  } else if (diff <= limitTime) {
    showExpiresMsg(diff);
  } else if (diff > limitTime) {
    const timeout = diff - limitTime;
    clearTimeout(expireTimeout);
    expireTimeout = setTimeout(() => {
      showExpiresMsg(limitTime);
    }, timeout);
  }
}

setTimeout(() => {
  // for refresh page
  startTokenExpires();
}, 0);

const getToken = () => localStorage.getItem('MATRIX_TOKEN');

export default {
  namespace: 'auth',

  state: {
    token: getToken(),
    profile: JSON.parse(localStorage.getItem('MATRIX_PROFILE') || '{}'),
    error: null,
    GAState: null,
    GAChecked: false,
  },

  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(() => {
        console.log('setup-history')
        if (getToken()) {
          dispatch({
            type: 'fetchProfile',
          });
        }
      });
    },
  },

  effects: {
    *saveTokenToStateAndLocalStorage(action, { put }) {
      const token = pathOr(null, ['data', 'token'], action.payload);
      const expiresIn = pathOr(null, ['data', 'expiresIn'], action.payload);
      yield put({
        type: 'saveToken',
        payload: token,
      });
      if (token) {
        localStorage.setItem('MATRIX_TOKEN', token);
        localStorage.setItem('MATRIX_TOKEN_EXPIRES', expiresIn);
        startTokenExpires();
        yield put({
          type: 'fetchProfile',
        });
      } else {
        localStorage.removeItem('MATRIX_TOKEN');
        localStorage.removeItem('MATRIX_TOKEN_EXPIRES');
        clearTimeout(expireTimeout);
        // router.replace('/');
        // notification.close('tokenExpireNotify');
        modal.destroy();
      }
    },

    *saveProfileToStateAndLocalStorage({ payload }, { put }) {
      // const profile = pathOr(null, ['data', 'profile'], action.payload);
      yield put({
        type: 'saveProfile',
        payload,
      });
      if (payload) {
        localStorage.setItem('MATRIX_PROFILE', JSON.stringify(payload));
      } else {
        localStorage.removeItem('MATRIX_PROFILE');
      }
    },

    *signIn({ payload, metaSuccess, metaFailure }, { call }) {
      const { grecaptcha, password = '', username = '', cookieChoose} = payload;
      const data = {
        username: username.toLocaleLowerCase(),
        password,
        grecaptcha,
        cookieChoose
      };
      const response = yield call(signIn, data);
      if (response.status === 'success') {
        if (response.data.token) {
          // eslint-disable-next-line no-underscore-dangle
          yield window.g_app._store.dispatch({
            type: 'auth/saveTokenToStateAndLocalStorage',
            payload: response,
          });
        }
        metaSuccess(response.data);
      } else {
        metaFailure();
        const code = get(response, 'code');
        const dataNum = get(response, 'data');
        if (code === 'USERNAME_NOT_CAN_SIGN_IN' || code === 'USERNAME_CLOSED') {
            message.error(localeMessage('Server.codes.USERNAME_NOT_CAN_SIGN_IN_USERNAME_CLOSED'));
            return
        }
        if (code === 'USER_EMAIL_OR_PASSWORD_INVALID') {
          if (dataNum === '0') {
            message.error(localeMessage('Server.codes.PASSWORD_ERROR_UPPER_LIMIT'));
            return;
          }
          message.error(
            localeMessage('Server.codes.USER_EMAIL_OR_PASSWORD_INVALID.num', {
              max: dataNum,
            })
          );
          return;
        }
        showResponseError(response);
      }
    },
    *gaSignIn({ payload, meta }, { call }) {
      const response = yield call(gaSignIn, payload);
      if (response.status === 'success') {
        // eslint-disable-next-line no-underscore-dangle
        yield window.g_app._store.dispatch({
          type: 'auth/saveTokenToStateAndLocalStorage',
          payload: response,
        });
        meta.success(response);
      } else {
        showResponseError(response);
        meta.fail(response.code);
      }
    },

    *gaCheck({ payload, meta }, { call, put }) {
      const { success = ctor, fail = ctor } = meta || {};
      const response = yield call(gaCheck, payload);
      if (response.status === 'success') {
        success(response);
        yield put({
          type: 'setGACheckedStatus',
          payload: true,
        });
      } else {
        showResponseError(response);
        fail(response.code);
        yield put({
          type: 'setGACheckedStatus',
          payload: false,
        });
      }
    },

    *seTimezone({ payload, meta }, { call }) {
      const { success = ctor /* fail = ctor */ } = meta || {};
      const response = yield call(seTimezone, payload);
      if (response.status === 'success') {
        success(response);
      } else {
        showResponseError(response);
        // fail(response.code);
      }
    },

    *setGaChecked({ payload }, { put }) {
      const { checked = false } = payload;
      yield put({
        type: 'setGACheckedStatus',
        payload: checked,
      });
    },

    *signOut(_, { call, put }) {
      try {
        yield call(signOut);
      } catch (e) {
        log('signOut error', e);
      }
      yield put({
        type: 'emptyTokenAndProfile',
      });
    },
    *emptyTokenAndProfile({ idDirect }, { put }) {
      if (idDirect !== 'no') {
        // 只要退出就要弹出 cookie 提示框
        localStorage.removeItem("cookieAgreement")
        window.location.href = '/';
      }
      yield put({
        type: 'saveTokenToStateAndLocalStorage',
        payload: null,
      });
      yield put({
        type: 'saveProfileToStateAndLocalStorage',
        payload: {},
      });
    },
    *tokenRefresh(_, { call, put }) {
      const response = yield call(tokenRefresh);
      if (response.status === 'success') {
        // message.success('token refreshed Success');
        message.success(formatMessage({ id: 'auth.token.tip.refreshed_success' }));
        yield put({
          type: 'saveTokenToStateAndLocalStorage',
          payload: response,
        });
      } else {
        showResponseError(response);
      }
    },
    *fetchProfile(_, { call, put }) {
      const response = yield call(getProfile);
      if (response.status === 'success') {
        yield put({
          type: 'saveProfileToStateAndLocalStorage',
          payload: response.data,
        });
      }

      return response;
    },
    *fetchProfileforkycinit({payload, cb}, { call, put }) {
      const response = yield call(getProfile);
      if (response.status === 'success') {
        yield put({
          type: 'saveProfileToStateAndLocalStorage',
          payload: response.data,
        });
        cb()
      }

      // return response;
    },
    *resetCookie({payload,metaSuccess}, { call }){
      const response = yield call(cookieChooseSet, { ...payload });
      metaSuccess()
      
    }
  },

  reducers: {
    saveToken(state, action) {
      return {
        ...state,
        token: action.payload,
        error: null,
      };
    },

    setGACheckedStatus(state, action) {
      return {
        ...state,
        GAChecked: action.payload,
      };
    },

    saveProfile(state, action) {
      return {
        ...state,
        profile: action.payload || {},
      };
    },
    saveError(state, action) {
      return {
        ...state,
        error: action.payload || {},
      };
    },
    saveGAauth(state, action) {
      return {
        ...state,
        GAState: action.payload || {},
      };
    },
  },
};
