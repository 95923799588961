import get from 'lodash/get';
import { delay } from 'dva/saga';
import { getUnReadMsgCount, setFlagInfo, getFlagInfo } from '@/services/api';
import { getTrades, getMarketTrades } from '@/services/trade';

const GlobalModel = {
  namespace: 'global',
  state: {
    collapsed: false,
    notices: [],
    symbols: [],
    marketSymbols: [],
    currencies: [],
    isDisplayKycTip: false,
    unReadMsgCount: 0,
    assetsFlag: {
      showStar: false,
      hideBalance: false,
    },
    newIncreasedCurrency:['AAVE','MKR','LINK','USDC','DAI','ERC']

  },
  effects: {
    *fetchMarketTrades(_, { call, put }) {
      try {
        const res = yield call(getMarketTrades);
        const savedRes = res.data.map(i => ({ name: i }));
        if (res.status === 'success') {
          yield put({
            type: 'setMarketSymbols',
            payload: savedRes,
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    *fetchTrades({ callback }, { call, put }) {
      try {
        const trades = yield call(getTrades);
        const symbols = get(trades, 'data.symbols', []);
        const currencies = get(trades, 'data.currencies', []);
        yield put({
          type: 'saveSymbols',
          payload: {
            symbols,
            currencies,
          },
        });
        yield delay(200);
        yield put({ type: 'TRADEISREADY' });
        if (callback) callback(currencies);
      } catch (error) {
        console.log(error.message);
      }
    },
    *setFlagInfo({ payload, meta }, { call, put, select }) {
      const response = yield call(setFlagInfo, payload);
      const { data, status } = response;
      if (status) {
        const { type } = payload;
        const assetsFlag = yield select(state => state.global.assetsFlag);
        const name = type === 'CURRENCY' ? 'hideBalance' : 'showStar';
        const thisFlag = {
          ...assetsFlag,
          [name]: !data,
        };
        yield put({
          type: 'setFlag',
          payload: thisFlag,
        });
        if (meta) meta();
      }
    },

    *getFlag(_, { call, put }) {
      const response = yield call(getFlagInfo);
      if (response.status) {
        const {
          data: { assertsFlag, currencyFlag },
        } = response;
        yield put({
          type: 'setFlag',
          payload: {
            showStar: !assertsFlag,
            hideBalance: !currencyFlag,
          },
        });
      }
    },

    fetchUnReadMsgCount: [
      // eslint-disable-next-line func-names
      function* (_, { call, put, select }) {
        const auth = yield select(state => state.auth);
        if (!auth.token) return;

        yield delay(1000);
        yield put({
          type: 'unReadMsgCount',
          // payload: response.data,
          payload: 0,
        });
        const response = yield call(getUnReadMsgCount);
        if (response.status === 'success') {
          yield put({
            type: 'unReadMsgCount',
            payload: response.data.count,
          });
        }
      },
      { type: 'takeLatest' },
    ],
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    changeLayoutCollapsed(
      state = {
        notices: [],
        collapsed: true,
      },
      { payload }
    ) {
      return { ...state, collapsed: payload };
    },

    setMarketSymbols(state, { payload }) {
      return {
        ...state,
        marketSymbols: payload,
      };
    },

    setFlag(state, { payload }) {
      return {
        ...state,
        assetsFlag: payload,
      };
    },

    saveNotices(state, { payload }) {
      return {
        collapsed: false,
        ...state,
        notices: payload,
      };
    },

    saveClearedNotices(
      state = {
        notices: [],
        collapsed: true,
      },
      { payload }
    ) {
      return {
        collapsed: false,
        ...state,
        notices: state.notices.filter(item => item.type !== payload),
      };
    },
    saveSymbols(state, { payload }) {
      const { symbols, currencies } = payload;
      state.symbols = symbols;
      state.currencies = currencies;
    },
    toggleKycTip(state, { payload }) {
      state.isDisplayKycTip = payload;
    },
    unReadMsgCount(state, { payload }) {
      state.unReadMsgCount = payload;
    },
  },
  /* eslint-enable */
  subscriptions: {
    // eslint-disable-next-line no-unused-vars
    setup({ history, dispatch }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      history.listen(({ pathname, search }) => {
        // eslint-disable-next-line no-unused-expressions
        window.zE && typeof window.zE === 'function' && window.zE('webWidget', 'close');
        if (pathname.startsWith('/trade/')) {
          // eslint-disable-next-line no-unused-expressions
          window.zE && typeof window.zE === 'function' && window.zE('webWidget', 'hide');
        } else {
          // eslint-disable-next-line no-unused-expressions
          window.zE && typeof window.zE === 'function' && window.zE('webWidget', 'show');
        }
        if (typeof window.ga !== 'undefined') {
          // eslint-disable-next-line no-unused-expressions
          window.ga &&
            typeof window.ga === 'function' &&
            window.ga('send', 'pageview', pathname + search);
        }
        if (pathname.startsWith('/kyc')) {
          dispatch({
            type: 'toggleKycTip',
            payload: false,
          });
          dispatch({
            type: 'kycIndividual/getCountryList',
          });
        } else {
          dispatch({
            type: 'toggleKycTip',
            payload: true,
          });
        }
        if (pathname.startsWith('/login') || pathname.startsWith('/register')) {
          return;
        }
        dispatch({
          type: 'fetchUnReadMsgCount',
        });
        if (
          pathname.startsWith('/assets/crypto') ||
          pathname.startsWith('/password') ||
          pathname.startsWith('/message/list') ||
          pathname.startsWith('/message/detail') ||
          pathname.startsWith('/api')
        ) {
          return;
        }
        dispatch({ type: 'fetchTrades' });
      });
    },
  },
};
export default GlobalModel;
