/* eslint-disable */
import Big from 'big.js';
import map from 'lodash/map';
import pick from 'lodash/pick';
import floor from 'lodash/floor';
import ceil from 'lodash/ceil';
import isNumber from 'lodash/isNumber';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import find from 'lodash/find';

export const DEFAULT_TIME_FRAME = '60';
// K_1_MIN,K_5_MIN,K_15_MIN,K_30_MIN,K_1_HOUR,K_4_HOUR,K_8_HOUR,K_1_DAY,K_1_WEEK
export const TIME_FRAMES = {
  '1': 'K_1_MIN',
  '5': 'K_5_MIN',
  '15': 'K_15_MIN',
  '30': 'K_30_MIN',
  '60': 'K_1_HOUR',
  '240': 'K_4_HOUR',
  '480': 'K_8_HOUR',
  '1D': 'K_1_DAY',
  '1W': 'K_1_WEEK',
};

export const DISABLE_FEATURE = [
  'timeframes_toolbar',
  'go_to_date',
  // 'header_chart_type',
  'header_compare',
  'header_undo_redo',
  'header_symbol_search',
  // 'header_indicators',
];

export const parseOrderPrice = ({ baseScale, quoteScale, orders, depth, type }) => {
  if (depth === null) {
    return sortBy(
      map(orders, ({ price, amount }) => ({
        price: Big(price).toFixed(quoteScale),
        amount: Big(amount).toFixed(baseScale),
        total: Big(price)
          .times(amount)
          .toFixed(baseScale + quoteScale),
      })),
      order => parseFloat(order.price)
    );
  }

  const handleBuy = p => floor(p.price, parseInt(depth));
  const handleSell = p => ceil(p.price, parseInt(depth));
  const action = type === 'buy' ? handleBuy : handleSell;

  const ordersBy = groupBy(orders, action);
  const result = map(ordersBy, (v, k) => {
    const depthResult = pick(
      reduce(
        v,
        (a, b) => ({
          amount: Big(a.amount)
            .plus(b.amount)
            .valueOf(),
          total: Big(a.total).plus(
            Big(b.price)
              .times(b.amount)
              .valueOf()
          ),
        }),
        { price: 0, amount: 0, total: 0 }
      ),
      ['amount', 'total']
    );
    return {
      price: Big(k).toFixed(quoteScale),
      amount: Big(depthResult.amount).toFixed(baseScale),
      total: Big(depthResult.total).toFixed(baseScale + quoteScale),
    };
  });

  return type === 'buy'
    ? reverse(sortBy(result, order => parseFloat(order.price)))
    : sortBy(result, order => parseFloat(order.price));
};

export const createDepthOptions = ({ formatMessage, quoteScale, locale, preLocale }) => {
  const depthOption = [
    { label: formatMessage({ id: `${preLocale}3` }), value: '0' },
    { label: formatMessage({ id: `${preLocale}4` }), value: '-1' },
    { label: formatMessage({ id: `${preLocale}5` }), value: '-2' },
    { label: formatMessage({ id: `${preLocale}6` }), value: '-3' },
  ];
  for (let size = 0; size < quoteScale; size++) {
    depthOption.unshift({
      label: formatMessage(
        { id: `${preLocale}1` },
        {
          // why special treatment to en-US?
          // size: locale === 'en-US' ? `0.${'0'.repeat(size)}1` : size,
          size: `0.${'0'.repeat(size)}1`,
        }
      ),
      value: `${size + 1}`,
    });
  }

  if (depthOption.length > 8) {
    depthOption.length = 8;
  }

  return depthOption;
};

export const formatPricesDisplay = (price, scale, display) => {
  if (isNil(price)) {
    return display;
  }
  let round = Big(price)
    .round(scale, 3)
    .valueOf();
  // return Big(price).toFixed(scale);
  return round;
};

export const getPriceStatus = item => {
  const up = '#3CBD72';
  const down = '#CF4242';
  const flat = '#d8d8d8';
  if (size(item) === 6) {
    const open = item[1];
    const close = item[4];
    if (close && open) {
      if (Big(close).eq(open)) {
        return flat;
      } else if (Big(close).gt(open)) {
        return up;
      } else {
        return down;
      }
    }
  }
  return flat;
};

export const get24ChangeStatus = change => {
  const up = '#3CBD72';
  const down = '#CF4242';
  const flat = '#d8d8d8';

  try {
    return Big(change).eq(0) ? flat : Big(change).gt(0) ? up : down;
  } catch (error) {
    return flat;
  }
};

export const formatSymbolDecimal = (price, symbol, symbols) => {
  const target = find(symbols, { name: symbol.name });
  if (target) {
    // return Big(price).toFixed(target.quoteScale);
    return Big(price)
      .round(target.quoteScale, 3)
      .valueOf();
  }
  return price;
};

export const formatAmountDecimal = (amount, symbol, symbols) => {
  const target = find(symbols, { name: symbol.name });
  if (target) {
    // return Big(amount).toFixed(target.baseScale);
    return Big(amount)
      .round(target.baseScale, 3)
      .valueOf();
  }
  return amount;
};
