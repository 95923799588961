import { formatMessage } from 'umi-plugin-react/locale';

const { console } = window;

export const SERVER_CODE_FRONTKEY_PREFIX = 'Server.codes';

/**
 * Translate data with intl message
 *
 * @param {object} json server response data
 * @returns {object}
 */
export const translateData = json => {
  try {
    const { code, data } = json;

    // Only handle which has `code` field
    if (code) {
      const values = data && typeof data === 'object' ? data : undefined;

      const translatedJson = {
        ...json,
        // Translate locale message for UI
        message:code === 'INSUFFICIENT_BALANCE'? json.message:formatMessage({ id: `${SERVER_CODE_FRONTKEY_PREFIX}.${code}` }, values),
      };

      if (process.env.NODE_ENV === 'development') {
        console.log(`\n`);
        console.log(
          `The matching intl's key is %c${SERVER_CODE_FRONTKEY_PREFIX}.${code}`,
          'color: yellow; font-style: italic; background-color: #000; padding: 2px 3px'
        );
        console.log(`Translate code: ${JSON.stringify(json)} => ${JSON.stringify(translatedJson)}`);
        console.log(`\n`);
      }

      return translatedJson;
    }

    return json;
  } catch (e) {
    window.console.log('Translate fallback: ', e);
    return json;
  }
};

/**
 * Get data with thenable
 *
 * @param {object} json response data
 * @returns {Thenable}
 */
// eslint-disable-next-line arrow-body-style
export const translateResultWithIntlInThenable = json => {
  return translateData(json);
};

export default translateResultWithIntlInThenable;
