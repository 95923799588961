import get from 'lodash/get';
import { message } from 'antd';
import { formatMessage } from 'umi/locale';
import {
  fetchFiatRule,
  fiatWithdrawSubmit,
  sendFiatWithdrawEmailCode,
  getWithdrawnAmount,
  fiatWithdrawLimit,
} from '@/services/fiat';
import { showResponseError } from '@/utils/utils';

export default {
  namespace: 'fiatWithdraw',

  state: {
    withdrawnAmount: {},
    limit: 0,
    data: {
      rule: {},
      account: {},
      currencyInfo: {},
    },
  },

  effects: {
    *getWithdrawnAmount({ payload }, { call, put }) {
      const response = yield call(getWithdrawnAmount, payload);
      if (response.status === 'success') {
        yield put({
          type: 'setWithdrawnAmount',
          payload: response.data,
        });
      } else {
        showResponseError(response);
      }
    },
    *fetchFiatRule(_, { call, put }) {
      const res = yield call(fetchFiatRule);
      if (res.status === 'success') {
        yield put({
          type: 'setFiatData',
          payload: res.data,
        });
      } else {
        showResponseError(res);
      }
    },

    *fiatWithdrawSubmit({ payload, metaSuccess }, { call }) {
      const res = yield call(fiatWithdrawSubmit, payload);
      if (res.status === 'success') {
        metaSuccess();
      } else {
        showResponseError(res);
      }
    },
    *sendFiatWithdrawEmailCode({ payload, metaSuccess }, { call }) {
      const response = yield call(sendFiatWithdrawEmailCode, payload);
      if (response.status === 'success') {
        message.success(formatMessage({ id: 'pages.account.bindGAEmalCodeSuccess' }));
        if (metaSuccess) metaSuccess();
      } else {
        showResponseError(response);
      }
    },
    *getFiatwithdrawLimit({ payload }, { call, put }) {
      const response = yield call(fiatWithdrawLimit, payload);
      if (response.status === 'success') {
        const limit = get(response.data, 'limit', 0);
        yield put({
          type: 'setWithdrawLimit',
          payload: limit,
        });
      } else {
        showResponseError(response);
      }
    },
  },

  reducers: {
    setWithdrawnAmount(state, action) {
      return {
        ...state,
        withdrawnAmount: action.payload,
      };
    },
    setFiatData(state, action) {
      return {
        ...state,
        data: {
          rule: action.payload.rule || {},
          account: action.payload.account || {},
          currencyInfo:
            (action.payload.currencies || []).filter(
              item => item.name === action.payload.currency
            )[0] || {},
        },
      };
    },
    setWithdrawLimit(state, action) {
      return {
        ...state,
        limit: action.payload,
      };
    },
  },
};
