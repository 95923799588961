import request from '@/utils/request';

export async function getOrders(params) {
  return request(`/v1/trade/orders-by-pagination?${params}`);
}
export async function cancelOrders(params) {
  return request(`/v1/trade/orders/${params.id}/cancel`, {
    method: 'POST',
  });
}
export async function getTrades(params) {
  return request(`/v1/trade/matchDetails-by-pagination?${params}`);
}
export async function getBlockTradeApi(data) {
  return request(`/v1/rfq/order/trades/list`, {
    method: 'POST',
    data
  });
}

