/* eslint-disable */
import styled from 'styled-components';

const OrderTabButton = styled.div`
  height: 28px;
  line-height: 28px;
  margin-top: 6px;
  padding: 0 6px;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  background-color: #283744;
  border-radius: 4px;

  color: ${({ active }) => (active ? '#3CBD72' : '#D8D8D8')};
  border: ${({ active }) => (active ? '1px solid #3EAF79' : '1px solid #36495C')};
`;

export default function Button(props) {
  return (
    <OrderTabButton active={props.active} small outline {...props}>
      {props.children}
    </OrderTabButton>
  );
}
