import get from 'lodash/get';
import keys from 'lodash/keys';
import { message } from 'antd';
import moment from 'moment-timezone';

import WriteFile from '@/utils/downloadExcel';
import { getTrades, cancelOrders } from '@/services/orders';
import { paramStringify } from '@/utils/url';
import { formatMessage } from 'umi/locale';
export default {
  namespace: 'tradeHistoryPage',
  state: {
    getTrades: {
      data: null,
      error: null,
    },
  },
  effects: {
    *downloadOrders({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });
        const response = yield call(getTrades, paramStringify(payload));
        const orders = get(response, 'data.items', []);
        // Use table render data fn or fallback
        const historyList = (payload && payload.renderDataFn(orders)) || orders;
        console.log('historyList: ', historyList);
        const headers = keys(get(historyList, 0, null));
        WriteFile(
          `${moment(payload.start).format('YYYYMMDD')}-${moment(payload.end).format(
            'YYYYMMDD'
          )}__trade`,
          headers,
          historyList
        );
        yield put({
          type: 'downloadFinish',
          payload: true,
        });
      } catch (err) {
        console.log('Error: ', err);
        yield put({
          type: 'handleError',

          payload: err,
        });
      }
    },
    *getOrders({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });
        const response = yield call(getTrades, paramStringify(payload));

        yield put({
          type: 'updateOrders',
          payload: response.data,
        });
      } catch (err) {
        yield put({
          type: 'handleError',
          payload: err,
        });
      }
    },
    *cancelOrder({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });
        const response = yield call(cancelOrders, paramStringify(payload));
        if (response.status === 'success') {
          payload.callback();
          // message.success(formatMessage({ id: 'menu.result.success' }));
          message.success('Cancelled', 5);
        } else {
          payload.callback();
          message.error(response.message || response.code);
        }
      } catch (err) {
        payload.callback();
        message.error(err);
      }
    },
  },
  reducers: {
    updateOrders(state, { payload }) {
      return {
        ...state,
        data: payload,
        fetching: false,
      };
    },
    handleError(state, { payload }) {
      return {
        ...state,
        data: payload,
        fetching: false,
      };
    },
    fetching(state, { payload }) {
      return {
        ...state,
        fetching: payload,
      };
    },
    // eslint-disable-next-line no-unused-vars
    downloadFinish(state, { payload }) {
      return {
        ...state,
        fetching: false,
      };
    },
  },
};
