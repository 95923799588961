import { pathOr } from 'ramda';
import * as utils from '@/utils/utils';
import { delay } from 'dva/saga';
import { size, each, get, find } from 'lodash';
import Big from 'big.js';

import {
  getCryptoAssets,
  getMarketTrades,
  getAssetsMarketPrices,
  getBalance,
  getPriceIncomeBy24Hours,
} from '@/services/api';

const DEFAULT_ITEM = {
  available: 0,
  currency: '-',
  estimate: 0,
  frozen: 0,
  legal: false,
  locked: 0,
};

const formatList = (assetsRes, tradeRes) => {
  const assets = pathOr([], ['data', 'accounts'], assetsRes);
  const trades = pathOr([], ['data', 'currencies'], tradeRes);

  const assetsMap = (assets || []).reduce((m, c) => {
    // eslint-disable-next-line
    m[c.currency] = c;
    return m;
  }, {});

  const result = trades
    .map(item => ({
      ...DEFAULT_ITEM,
      ...(assetsMap[item.name] || null),
      currency: item.name,
      meta: item,
    }))
    .filter(item => !item.legal);

  return result;
};

export default {
  namespace: 'crypto',

  state: {
    list: [],
    totalEstimate: [],
    allPrice: [],
    pricesCache: {},
    balanceList: [],
    allPrice24Hours:[]
  },

  effects: {
    *fetch({ meta }, { call, put }) {
      const assetsRes = yield call(getCryptoAssets);
      if (assetsRes.status === 'fail') {
        utils.showResponseError(assetsRes);
        return;
      }
      const tradeRes = yield call(getMarketTrades);
      if (tradeRes.status === 'fail') {
        utils.showResponseError(tradeRes);
        return;
      }

      const symbols = pathOr(null, ['data', 'symbols'], tradeRes);
      if (!symbols) return;

      yield put({
        type: 'totalEstimate',
        payload: assetsRes.data.cryptoTotalEstimate || [],
      });

      const list = formatList(assetsRes, tradeRes);

      yield put({
        type: 'list',
        payload: list,
      });
      if (meta) meta(list);
    },
    *getAssetsPrices({ callback }, { put, select }) {
      let currencies;
      while (true) {
        currencies = yield select(state => state.global.currencies);
        if (size(currencies) > 0) {
          break;
        } else {
          yield delay(100);
        }
      }
      yield put({
        type: 'getAllPrices',
        payload: { currencies },
        callback,
      });
      yield put({
        type: 'get24HoursAllPrices',
      });
    },
    *getBalance({ meta }, { call, put }) {
      let data = [];
      try {
        const response = yield call(getBalance);
        if (response.status === 'success') {
          data = get(response, 'data', []) || [];
        }
      } catch (error) {
        console.log(error);
      }
      yield put({
        type: 'setBalance',
        data,
      });
      if (meta) meta(data);
    },
    *get24HoursAllPrices({},{call, put}){
        try{
          const response = yield call(getPriceIncomeBy24Hours);
          if(response.status == 'success'){
            let { data } = response
            let  restructureData = []
                each(data, item=>{
                    if(`${item.currency}_USD` === item.symbol){
                        restructureData.push({
                        quote_currency:item.currency,
                        data:[
                            {...item }
                        ]
                    })
                  }
                })
              

           
            yield put({
              type: 'update24HoursAllPirces',
              data: restructureData,
            });
          }
        }catch(err){

        }
    },
    *getAllPrices({ payload }, { call, put }) {
      try {
        const { currencies } = payload;
        const response = yield call(getAssetsMarketPrices);
        if (response.status === 'success') {
          const pricesCache = {};
          each(response.data, list => {
            each(list.data, item => {
              const [baseCoin, quoteCoin] = item.symbol.split('_');
              const prices = get(JSON.parse(item.prices), 4);
              const fex = get(find(currencies, { name: quoteCoin }), 'fex');
              const marketPrice =
                fex && prices
                  ? Big(fex)
                      .times(prices)
                      .toFixed(2)
                  : '--';
              if (fex) {
                pricesCache[baseCoin] = marketPrice;
              }
            });
          });

          yield put({
            type: 'updatePricesCache',
            data: pricesCache,
          });
          yield put({
            type: 'updateAllPrice',
            data: response.data,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  reducers: {
    totalEstimate(state, { payload }) {
      return {
        ...state,
        totalEstimate: payload,
      };
    },
    list(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    setBalance(state, { data }) {
      return {
        ...state,
        balanceList: data,
      };
    },
    updatePricesCache(state, { data }) {
      return {
        ...state,
        pricesCache: data,
      };
    },
    updateAllPrice(state, { data }) {
      return {
        ...state,
        allPrice: data,
      };
    },
    update24HoursAllPirces(state, {data}){
      return {
        ...state,
        allPrice24Hours: data
      }
    }
  },
};
