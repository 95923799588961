// DO NOT modify this file
// ** auto generated by "npm run get-text2" (Or "yarn get-text2")

export default {
  "1": null,
  "222": "2",
  "Server.codes.DRIVERLICENSENUMBER_HAS_ALREADY_REGISTERED": null,
  "Server.codes.IDENTIFICATIONNUMBER_HAS_ALREADY_REGISTERED": null,
  "fiat.MEMO": null,
  "Server.codes.INSUFFICIENT_FUNDS": null,
  "page-block-trading-create-SuitabilityQuestionnaire": null,
  "pages.kycIndividual.englishfieldError3": null,
  "page.Tag.copied": null,
  "page.MemoId.copied": null,
  "pages.RechargePage.tip12": null,
  "fiat.MinimumSUDDeposit": null,
  "Server.codes.USERNAME_NOT_CAN_SIGN_IN_USERNAME_CLOSED": null,
  "pages.RechargePage.tip11": null,
  "pages.RechargePage.tip10": null,
  "Server.codes.GA_USED": null,
  "pages.kyc.TooltipExplainingIBANsubTips": null,
  "pages.kyc.form.tagRules3": null,
  "pages.kyc.form.tagRules2": null,
  "components.Footer.footerExplainNew": null,
  "page.cryptoAssets.table.blockTrade": null,
  "components.Header.BlockTrade": null,
  "page-block-trading-create-RequestAQuote": null,
  "page-block-trading-email-context": null,
  "page-block-trading-wrap2-MatrixGuarantee-cont": null,
  "page-block-trading-wrap2-MatrixGuarantee": null,
  "page-block-trading-wrap2-Rules-cont": null,
  "page-block-trading-wrap2-Rules": null,
  "page.HomePage.BTtopItemContent4": null,
  "page.HomePage.BTtopItemTitle4": null,
  "page.HomePage.BTtopItemContent3": null,
  "page.HomePage.BTtopItemTitle3": null,
  "page.HomePage.BTtopItemContent2": null,
  "page.HomePage.BTtopItemTitle2": null,
  "page.HomePage.BTtopItemContent1": null,
  "page.HomePage.BTtopItemTitle1": null,
  "page-block-trading-wrap3-t": null,
  "page-block-trading-wrap2-cont2": null,
  "page-block-trading-wrap2-cont1": null,
  "page-block-trading-wrap2-t": null,
  "app.containers.LoginPage.Eligibility": null,
  "page.homepage.ipaTextbt": null,
  "page.HomePage.BTb2": null,
  "page.HomePage.BTb1": null,
  "components.Header.PRO": null,
  "components.Header.BlockTrading": null,
  "page.tradingView.midpoint.price.tip": null,
  "Server.codes.REFUND_RULES_NOT_FOUND": null,
  "page.WithdrawForm.XRP2.content": null,
  "pages.component.model-xlm-copy.content": null,
  "pages.component.model-xml.content": null,
  "pages.kyc.form.tagRules": null,
  "input.placeholder.enterTag": null,
  "component.priceOrder.priceTitle": null,
  "component.priceOrder.title": null,
  "pages.component.model.XRPcontent": null,
  "page.WithdrawForm.XML.content": null,
  "page.WithdrawForm.XRP.warningTip": null,
  "page.WithdrawForm.XRP.content": null,
  "page.WithdrawForm.XLM.warningTip": null,
  "pages.kycIndividual.requiredDocument.desc6": null,
  "pages.kyc.verificationContent6": null,
  "components.Footer.Social": null,
  "components.Footer.Medium": null,
  "components.Footer.Linkedln": null,
  "components.Footer.Twitter": null,
  "pages.kycIndividual.withdrawVirtualAssetsNumber": null,
  "pages.kycIndividual.withdrawVirtualAsset": null,
  "pages.kyc.sideBarMessage7": null,
  "Server.codes.MEMO_INVALID": null,
  "page.WithdrawForm.warningTip": null,
  "page.WithdrawForm.content": null,
  "pages.component.model.content": null,
  "pages.component.model.title": null,
  "pages.api.form.refundMemoIdLabel": null,
  "input.placeholder.enterMemoId": null,
  "pages.kycInstitution.form.NationalIDExpiryDate": null,
  "pages.kycIndividual.Multiple": null,
  "pages.kyc.TooltipExplainingIBAN": null,
  "pages.kyc.bankAccountNumberTip": null,
  "pages.kyc.tip.imgVolumeSize": null,
  "pages.kyc.submitDeclareAll": null,
  "pages.kyc.tip.fileSize": null,
  "pages.kyc.VerifyIdentityTips2": null,
  "pages.kyc.VerifyIdentityTips1": null,
  "pages.kyc.tip.imgEdgeLength": null,
  "pages.kyc.tip.imgVolume": null,
  "pages.kyc.tip.imgTypeTip": null,
  "pages.kyc.useResidentialAddress": null,
  "pages.step5.marketDes": null,
  "pages.step5.marketTitle": null,
  "pages.step5.professinalDes": null,
  "pages.step5.professinalTitle": null,
  "pages.step5.retailDes2": null,
  "pages.step5.retailDes1": null,
  "pages.step5.retailTitle": null,
  "pages.step5.kycLevel.reminder": null,
  "pages.kyc.form.addVisa": null,
  "pages.kyc.kycEditMessage2": null,
  "pages.kyc.kycEditMessage1": null,
  "pages.kyc.kycEditTitle": null,
  "pages.kyc.sliderBarDeclaration": null,
  "pages.kyc.sliderBarMessage4": null,
  "pages.kyc.sliderBarMessage3": null,
  "pages.kyc.sliderBarMessage2": null,
  "pages.kyc.sliderBarMessage1": null,
  "pages.kyc.verifyYourIdentity": null,
  "pages.kyc.sideBarMessage6": null,
  "pages.kyc.sideBarMessage5": null,
  "pages.kyc.sideBarMessage4": null,
  "pages.kyc.sideBarMessage3": null,
  "pages.kyc.sideBarMessage2": null,
  "pages.kyc.sideBarMessage1": null,
  "pages.kyc.sideBarTitle": null,
  "pages.kyc.completed": null,
  "pages.kyc.inProgress": null,
  "pages.kyc.unstarted": null,
  "pages.kyc.Senior_Management.Add": null,
  "pages.kyc.Board_of_Directors.Add": null,
  "pages.kyc.Shareholders_Entity.Add": null,
  "pages.kyc.otherPosition.placeholder": null,
  "pages.kyc.form.countryOfNationality": null,
  "pages.kyc.shareholders_Individual.Add": null,
  "pages.kyc.choseManageType.atLeastOne": null,
  "pages.kyc.changeAccountStatusDetail": null,
  "pages.kyc.changeAccountStatus": null,
  "pages.kyc.imgProfessional": null,
  "pages.kyc.imgRetail": null,
  "pages.kyc.exposedToMoreRisk": null,
  "pages.kyc.levelOfProtection": null,
  "page.CookieAlert.agreement": null,
  "page.CookieAlert.alink": null,
  "page.CookieAlert.description": null,
  "pages.kyc.clientClassification": null,
  "pages.kyc.uploadDocuments": null,
  "pages.kyc.whereYouBank": null,
  "pages.kyc.yourAssets": null,
  "pages.kyc.aboutYou": null,
  "pages.kyc.idDocumentSelﬁe": null,
  "pages.kyc.idDocumentPhoto": null,
  "pages.kyc.idDocumentPassport": null,
  "pages.kyc.someGuidanceContent4": null,
  "pages.kyc.someGuidanceContent3": null,
  "pages.kyc.someGuidanceContent2": null,
  "pages.kyc.someGuidanceContent1": null,
  "pages.kyc.someGuidanceTitle": null,
  "pages.kyc.verificationContent5": null,
  "pages.kyc.verificationContent4": null,
  "pages.kyc.verificationContent3": null,
  "pages.kyc.verificationContent2": null,
  "pages.kyc.verificationContent1": null,
  "pages.kyc.verificationTitle": null,
  "pages.kyc.houseNumberAndStreet": null,
  "pages.kyc.addressOfBank": null,
  "pages.kyc.uploadRecentBank": null,
  "pages.kyc.nameOfBank": null,
  "pages.kyc.addressOfAccountHolder": null,
  "pages.kyc.nameOfAccountHolder": null,
  "page.kyc.accountHolderDetails": null,
  "page.kyc.tradingTip": null,
  "page.kyc.liquidityManagementTip": null,
  "page.kyc.investingTip": null,
  "page.kyc.hedgingTip": null,
  "page.kyc.arbitrageTip": null,
  "page.kyc.virtualAssetsTip": null,
  "page.kyc.fiatCurrencyTip": null,
  "page.kyc.netWorthTip": null,
  "page.kyc.addAdditionalType": null,
  "pages.kyc.ResidentialAddressOption5": null,
  "pages.kyc.ResidentialAddressOption4": null,
  "pages.kyc.ResidentialAddressOption3": null,
  "pages.kyc.ResidentialAddressOption2": null,
  "pages.kyc.ResidentialAddressOption1": null,
  "pages.kyc.ResidentialAddressMsg3": null,
  "pages.kyc.ResidentialAddressMsg2": null,
  "pages.kyc.ResidentialAddressMsg1": null,
  "pages.kycIndividual.emailMessage2": null,
  "pages.kycIndividual.emailMessage1": null,
  "pages.kyc.MailingSameAsResidential": null,
  "pages.kyc.domicileSameAsResidential": null,
  "pages.kyc..usPersonDeclarationTips": null,
  "pages.kyc.label.faitCurrency": null,
  "pages.kyc.label.depositAndWithdraw": null,
  "pages.kycIndividual.purposeAccount": null,
  "pages.kycIndividual.withdrawVirtualNumber": null,
  "pages.kycIndividual.withdrawVirtualTime": null,
  "pages.kycIndividual.depositVirtualNumber": null,
  "pages.kycIndividual.depositVirtualTime": null,
  "pages.kycIndividual.withdrawFiatNumber": null,
  "pages.kycIndividual.withdrawFiatTime": null,
  "pages.kycIndividual.depositFiatNumber": null,
  "pages.kycIndividual.depositFiatTime": null,
  "pages.kycIndividual.Background": null,
  "pages.kycIndividual.VirtualAssets": null,
  "pages.kycIndividual.Deposits": null,
  "pages.kycIndividual.NetWorth": null,
  "pages.kycIndividual.Politically": null,
  "pages.kycIndividual.TaxAndEmployment": null,
  "pages.fiels.required": null,
  "label.editPropfile": null,
  "pages.kyc.overview.declaration": null,
  "pages.kyc.bankinfo": null,
  "pages.kyc.estimate": null,
  "pages.overview.kycLevel.MARKET_COUNTERPARTY": null,
  "pages.overview.kycLevel.PROFESSIONAL": null,
  "pages.overview.kycLevel.RETAIL": null,
  "pages.label.uploadDocuments": null,
  "pages.kyc.individual.update": null,
  "pages.kyc.company.update": null,
  "pages.kyc.individual": null,
  "pages.kyc.company": null,
  "input.error.emailNotMatch": null,
  "label.repeatEmail": null,
  "input.error.confirmEmailEmpty": null,
  "app.containers.found.timeOut": null,
  "app.containers.found.resend": null,
  "pages.monthlyStatements.endStatementTip": null,
  "pages.monthlyStatements.endStatement": null,
  "pages.monthlyStatements.closeBalance": null,
  "pages.monthlyStatements.openBalance": null,
  "pages.monthlyStatements.detailedSummary": null,
  "pages.monthlyStatements.credit": null,
  "pages.monthlyStatements.debit": null,
  "pages.monthlyStatements.particular": null,
  "pages.monthlyStatements.transactionType": null,
  "pages.monthlyStatements.transactionConfirmationDate": null,
  "pages.monthlyStatements.transactionDate": null,
  "pages.monthlyStatements.viewDetail": null,
  "pages.monthlyStatements.usdv": null,
  "pages.monthlyStatements.vat": null,
  "pages.monthlyStatements.fiatAsset": null,
  "pages.monthlyStatements.virtualAssetNotification": null,
  "pages.monthlyStatements.closing": null,
  "pages.monthlyStatements.fee": null,
  "pages.monthlyStatements.trading": null,
  "pages.monthlyStatements.refund": null,
  "pages.monthlyStatements.balanceStar": null,
  "pages.monthlyStatements.withdrawal": null,
  "pages.monthlyStatements.deposit": null,
  "pages.monthlyStatements.balance": null,
  "pages.monthlyStatements.opening": null,
  "pages.monthlyStatements.ofMonth": null,
  "pages.monthlyStatements.assetValAtTheEnd": null,
  "pages.monthlyStatements.exchangeRate": null,
  "pages.monthlyStatements.amount": null,
  "pages.monthlyStatements.asset": null,
  "pages.monthlyStatements.virtual": null,
  "pages.monthlyStatements.virtualAsset": null,
  "pages.monthlyStatements.separatorTitle": null,
  "pages.monthlyStatements.netAssetValNotification": null,
  "pages.monthlyStatements.fiat": null,
  "pages.monthlyStatements.virtualAssetStar": null,
  "pages.monthlyStatements.assetValInUsd": null,
  "pages.monthlyStatements.assetType": null,
  "pages.monthlyStatements.netAssetVal": null,
  "pages.monthlyStatements.fromToTo": null,
  "pages.monthlyStatements.dataOfIssue": null,
  "pages.monthlyStatements.clientEmail": null,
  "pages.monthlyStatements.fromTo": null,
  "pages.monthlyStatements.clientName": null,
  "pages.monthlyStatements.accountInfo": null,
  "Server.codes.SYSTEM_BUSY": null,
  "Server.codes.NOT_ALLOWED_SELF_TRADE": null,
  "component.modal.title.attention": null,
  "pages.OrderBook.errors.StopIsMoreThanLimit": null,
  "pages.OrderBook.errors.StopIsLessThanLimit": null,
  "input.error.8_11.number": null,
  "Server.codes.bankDetails.swiftCode": null,
  "page.home.kycGotoVerify": null,
  "components.Footer.RiskDisclosures": null,
  "components.Footer.MarketRuleBook": null,
  "pages.kyc.server_code.IMAGE_LENGTH_IS_TOO_LONG": null,
  "pages.uploader.imageSize": null,
  "fiat.history.swiftCode": null,
  "page.withdraw.beyondMax": null,
  "pages.kyc.server_code.IMAGE_SIZE_IS_TOO_SMALL": null,
  "Server.codes.KYC_PROCESS_UNFINISHED": null,
  "Server.codes.VCODE_LIMIT_COUNT": null,
  "link.deleteTitle": null,
  "page.taxInvoice.pageTitle": null,
  "components.Header.menuInvoice": null,
  "NATIONALITY_COUNTRY_IS_NOT_ALLOWED": null,
  "test127": "",
  "test126": "中文126",
  "test124": "中文3",
  "test123": "中文2",
  "test111": "中文测试1",
  "table.title.vat": null,
  "label.vatText": null,
  "label.withdraw": null,
  "label.vat": null,
  "test1": null,
  "page.FundPassword.open": null,
  "table.title.amountUsd": null,
  "Server.codes.SYMBOL_HALTED": null,
  "page.trade.Halted": null,
  "Server.codes.VISA_DOCUMENT_IS_MISSING": null,
  "Server.codes.ENTITY_DOCUMENT_IS_MISSING": null,
  "Server.codes.DRIVING_DOCUMENT_IS_MISSING": null,
  "Server.codes.IDENTITY_DOCUMENT_IS_MISSING": null,
  "Server.codes.SELFIE_DOCUMENT_IS_MISSING": null,
  "Server.codes.PASSPORT_DOCUMENT_IS_MISSING": null,
  "Server.codes.PROOF_OF_ADDRESS_DOCUMENT_IS_MISSING": null,
  "Server.codes.BANK_DOCUMENT_IS_MISSING": null,
  "Server.codes.REFUND_AMOUNT_NEED_GREATER_THAN_OR_EQUAL_TO_MINIMUM_AMOUNT": null,
  "Server.codes.BEYOND_WEEKLY_LIMIT": null,
  "page.kyc.ControllingPersonStatusTip": null,
  "page.kyc.typeOfControllingPerson.m": null,
  "page.kyc.typeOfControllingPerson.l": null,
  "page.kyc.typeOfControllingPerson.k": null,
  "page.kyc.typeOfControllingPerson.j": null,
  "page.kyc.typeOfControllingPerson.i": null,
  "page.kyc.typeOfControllingPerson.h": null,
  "page.kyc.typeOfControllingPerson.g": null,
  "page.kyc.typeOfControllingPerson.f": null,
  "page.kyc.typeOfControllingPerson.e": null,
  "page.kyc.typeOfControllingPerson.d": null,
  "page.kyc.typeOfControllingPerson.c": null,
  "page.kyc.typeOfControllingPerson.b": null,
  "page.kyc.typeOfControllingPerson.a": null,
  "page.kyc.typeOfControllingPerson.ControllingPersonOfALegalArrangementOtherThanATrust": null,
  "page.kyc.typeOfControllingPerson.ControllingPersonOfATrust": null,
  "page.kyc.typeOfControllingPerson.ControllingPersonOfALegalPerson": null,
  "faitDeposit.DepositFiatFundsSec": null,
  "Server.codes.AGREE_EXEMPTION_OPERATION_FREQUENT": null,
  "page.kyc.GIIN.EX": null,
  "pages.kyc.form.invalid_GIIN": null,
  "page.kyc.GIIN": null,
  "pages.Account.idl.limited_account.description2": null,
  "pages.Account.idl.limited_account.description1": null,
  "fiat.bankBranchAddress": null,
  "fiat.deposit.importantInformation.c": null,
  "fiat.deposit.importantInformation.b": null,
  "fiat.deposit.importantInformation.a": null,
  "fiat.deposit.fiat.funds.d": null,
  "fiat.deposit.fiat.funds.h": null,
  "fiat.deposit.fiat.funds.g": null,
  "fiat.deposit.fiat.funds.f": null,
  "fiat.deposit.fiat.funds.e": null,
  "fiat.deposit.fiat.funds.c": null,
  "fiat.deposit.fiat.funds.b": null,
  "fiat.deposit.fiat.funds.a": null,
  "fiat.withdraw.importantInformation.e": null,
  "fiat.withdraw.importantInformation.d": null,
  "fiat.withdraw.importantInformation.c": null,
  "fiat.withdraw.importantInformation.b": null,
  "fiat.withdraw.importantInformation.a": null,
  "page.deposit.addressLabel": null,
  "page.deposit.addressTipGA": null,
  "faitDeposit.DepositFiatFunds": null,
  "fiat.AccountNumber": null,
  "page.account.FaitWithdrawalLimit": null,
  "page.account.FaitDepositLimit": null,
  "pages.Account.Preferences": null,
  "page.account.KYC.LevelOfAuthentication": null,
  "account_history_SAVE_TIME_ZONE": null,
  "page.account.VirtualAssetDepositWithdrawalLimit": null,
  "page.account.FaitDepositWithdrawalLimit": null,
  "page.error.uploading": null,
  "pages.Account.TimeZone.description": null,
  "pages.Account.TimeZone": null,
  "pages.kyc.form.legalEntityTypeOptions.Trust": null,
  "account_history_GET_API_SECRET": null,
  "page.kyc.PleaseProvideABreakDownOfYourNetWorth": null,
  "page.kyc.AmountUSD": null,
  "page.kyc.NetWorth.amount": null,
  "page.kyc.NetWorth.name": null,
  "page.Limit.AccountHoldingLimit.tip": null,
  "page.Limit.FiatWithdrawalLimit": null,
  "page.Limit.FiatDepositLimit": null,
  "page.Limit.VirtualAssetWithdrawalLimit": null,
  "page.Limit.VirtualAssetDepositLimit": null,
  "page.Limit.AccountHoldingLimit": null,
  "page.fiat.Withdrawal.ImportantInformation.desc5": null,
  "page.fiat.Withdrawal.ImportantInformation.desc4": null,
  "page.fiat.Withdrawal.ImportantInformation.desc3": null,
  "page.fiat.Withdrawal.ImportantInformation.desc2": null,
  "page.fiat.Withdrawal.ImportantInformation.desc1": null,
  "page.fiat.Withdrawal.ImportantInformation": null,
  "page.fiat.WeeklyWithdrawalLimit": null,
  "page.fiat.WeeklyDepositLimit": null,
  "page.Fiat.Important.Information.desc3": null,
  "page.Fiat.Important.Information.desc2": null,
  "page.Fiat.Important.Information.desc1": null,
  "page.Fiat.Important.Information.title": null,
  "page.Fiat.Deposit.Instructions.desc8": null,
  "page.Fiat.Deposit.Instructions.desc7": null,
  "page.Fiat.Deposit.Instructions.desc6": null,
  "page.Fiat.Deposit.Instructions.desc5": null,
  "page.Fiat.Deposit.Instructions.desc4": null,
  "page.Fiat.Deposit.Instructions.desc3": null,
  "page.Fiat.Deposit.Instructions.desc2": null,
  "page.Fiat.Deposit.Instructions.desc1": null,
  "page.Fiat.Deposit.Instructions.title": null,
  "page.kyc.form.LegalEntityUAEResidence": null,
  "page.kyc.form.ConsultantBrokerSwimmingCoach": null,
  "page.kyc.form.NonBusinessOwner": null,
  "page.kyc.form.BusinessOwner": null,
  "page.kyc.form.AreYouABusinessOwnerOrNonBusinessOwner": null,
  "page.kyc.form.SelfieTips.desc": null,
  "page.kyc.form.SelfieTips.title": null,
  "page.kyc.form.SelfieTips": null,
  "pages.kyc.form.ManagementDetails.SeniorManagement": null,
  "pages.kyc.form.ManagementDetails.ShareholdersEntity": null,
  "pages.kyc.form.ManagementDetails.ShareholdersIndividual": null,
  "page.kyc.form.AreYouAHolderOfAUAEResidenceVisaFor5YearsOrMore": null,
  "page.kyc.form.IsTheControllingPersonAHolderOfAUAEResidenceVisaFor5YearsOrMore": null,
  "fiat.IntermediaryBankBIC": null,
  "fiat.IntermediaryBankAddress": null,
  "fiat.IntermediaryBankName": null,
  "fiat.BeneficiaryAccountNumber": null,
  "fiat.BeneficiaryAccountCity": null,
  "fiat.BeneficiaryBankCountry": null,
  "fiat.BeneficiaryAccountCountry": null,
  "fiat.BeneficiaryBankName": null,
  "page.kyc.controllingPerson.LegalNameTitle": null,
  "page.kyc.form.legalNameOfEntity": null,
  "fiat.BeneficiaryBankAddress": null,
  "fiat.BeneficiaryBankBIC": null,
  "fiat.BeneficiaryAccountName": null,
  "fiat.BeneficiaryAccountAddress": null,
  "fiat.transitNumber": null,
  "pages.kyc..usPersonDeclaration": null,
  "page.form.err.EnglishOrSpace": null,
  "pages.kyc.form.Management.AddDifferentManagementDetails": null,
  "pages.Account.vatNotification.description": null,
  "pages.Account.vatNotification": null,
  "table.title.vatInUsd": null,
  "page.MonthlyStatements.vatInUsd": null,
  "pages.OpenOrderPage.vatInUsd": null,
  "pages.kyc.new.form.TRNNo": null,
  "pages.kycIndividual.requiredDocument.desc5": null,
  "pages.kycIndividual.requiredDocument.desc4": null,
  "pages.kycIndividual.requiredDocument.desc3": null,
  "pages.kycIndividual.requiredDocument.desc2": null,
  "pages.kycIndividual.requiredDocument.desc1": null,
  "page.form.kyc.update.secruity.tip": null,
  "page.form.expiredDate": null,
  "page.kyc.form.UAE_VISA": null,
  "pages.kyc.form.error_file_format": null,
  "page.kyc.form.PEPConfirm": null,
  "page.form.instructions.desc3": null,
  "page.form.instructions.desc2": null,
  "page.form.instructions.desc1": null,
  "page.form.Instructions": null,
  "page.form.security.tip1": null,
  "page.form.err.EnglishOrNumberOrSpace": null,
  "page.form.err.SpecialCharactersForbidden": null,
  "Server.codes.ERROR_KYC_STATUS": null,
  "Server.codes.EXPIRED_NATIONAL_ID": null,
  "Server.codes.EXPIRED_PASSPORT": null,
  "Server.codes.EXPIRED_DRIVING_LICENSE": null,
  "pages.kyc.AuthorizedPersonType.SeniorManager": null,
  "page.kyc.form.ShareholdersEntityDetails": null,
  "page.kyc.form.ShareholdersIndividualDetails": null,
  "pages.kyc.form.AreYouAPoliticallyExposedPerson": null,
  "pages.kycIndividual.POLITICAL_PARTY_OFFICIALS_label": null,
  "pages.kycIndividual.GOVERNMENT_OFFICIALS_label": null,
  "pages.kyc.server_code.CONNECTION_TIMEOUT": null,
  "pages.kyc.legalEntityDocumentType.UtilityBillOfThePOA": null,
  "pages.kyc.legalEntityDocumentType.Board_resolution_B": null,
  "aaa": null,
  "Server.codes.PERMANENT_COUNTRY_IS_NOT_ALLOWED": null,
  "Server.codes.MAIL_COUNTRY_IS_NOT_ALLOWED": null,
  "Server.codes.BANK_COUNTRY_IS_NOT_ALLOWED": null,
  "Server.codes.ACCOUNT_COUNTRY_IS_NOT_ALLOWED": null,
  "Server.codes.driverValue is repeat": null,
  "Server.codes.IdValue is repeat": null,
  "Server.codes.passportValue is repeat": null,
  "Server.codes.driverValue or IdValue is repeat": null,
  "Server.codes.SYMBOL_TRADE_OFF": null,
  "Server.codes.AGREEMENT_INVALID_TIME": null,
  "Server.codes.USER_AGREEMENT_NOT_AGREED": null,
  "Server.codes.AGE_AGREEMENT_NOT_AGREED": null,
  "menu.dashboard.cancelOrder": null,
  "Server.codes.API_KEY_CAN_NOT_SEE": null,
  "Server.codes.API_KEY_TRANSFINITE": null,
  "test.test": null,
  "app.containers.RegisterPage.agreeTip": null,
  "app.containers.RegisterPage.agree": null,
  "app.containers.RegisterPage.userAgreementTip": null,
  "app.containers.RegisterPage.userAgreement": null,
  "Server.codes.RESIDENCY_COUNTRY_IS_NOT_ALLOWED": null,
  "Server.codes.INCORPORATION_COUNTRY_IS_NOT_ALLOWED": null,
  "Server.codes.OPERATIONS_COUNTRY_IS_NOT_ALLOWED": null,
  "test111111": null,
  "Server.codes.OPERATION_FREQUENT": "操作过于频繁",
  "ga.set.googleLink": "https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en",
  "page.kyc.form.addMoreDocuments": "添加更多文档",
  "page.crypto.title": "Assets Segment",
  "pages.kyc.form.idCardAndDriverLicense": "身份证和驾驶执照",
  "pages.kyc.form.driverLicense": "驾驶执照",
  "pages.kyc.form.idCard": "身份证",
  "pages.kycIndividual.CLOSE_ASSOCIATES.title": "密友",
  "pages.kycIndividual.CLOSE_ASSOCIATES.desc": "与PEP共同拥有法人实体，法律安排或密切业务关系的个人； 拥有法人实体的唯一实益拥有权或为PEP的实际利益建立的法律安排的任何个人。",
  "pages.kycInstitution.form.registeredAddress3": "注册地址（注册国家）",
  "pages.kyc.form.countryOfBirth": "出生国家",
  "pages.kyc.form.cityOfBirth": "出生城市",
  "Server.codes.ADDRESS_BIND_FAILED": "当前币种不存在",
  "pages.asset": "资产",
  "Server.codes.ACCOUNT_BALANCE_NOT_ENOUGH": "提现失败，请联系客服。\n",
  "fiat.deposit.PendingReview": "待审核",
  "fiat.withdraw.SUBMITTED": "已提交",
  "fiat.withdraw.DENIED": "已拒绝",
  "fiat.withdraw.WAITING_FOR_APPROVAL": "\n等待批准\n",
  "fiat.withdraw.WAITING_FOR_WALLET": "\n等待钱包",
  "fiat.withdraw.CANCELLED": "已取消",
  "fiat.withdraw.NEED_CANCELLATION": "需要取消",
  "fiat.withdraw.PENDING": "待定",
  "fiat.withdraw.FAILED": "失败",
  "fiat.deposit.SUBMITTED": "已提交",
  "fiat.deposit.DENIED": "已拒绝",
  "fiat.deposit.WAITING_FOR_APPROVAL": "等待批准",
  "fiat.deposit.CANCELLED": "已取消",
  "fiat.deposit.NEED_CANCELLATION": "需要取消",
  "fiat.deposit.DEPOSITED": "已充值",
  "fiat.deposit.PENDING": "待定",
  "pages.history": "历史记录",
  "pages.status": "状态",
  "pages.withdraw": "提现",
  "pages.deposit": "充值",
  "pages.type": "类型",
  "pages.assets": "资产",
  "pages.RechargePage.tip5": "存款时请确保Gas≥34000，否则存款将无法到达。",
  "page.cryptoAssets.hideZeroDes": "价值低于1000美元的资产将被隐藏。",
  "fiat.message.notice": "你需在银行的充值信息中包含此备注",
  "fiat.history.applicationAmount": "申请金额",
  "page.HomePage.slogan2": "to Virtual Assets",
  "page.HomePage.slogan1": "Your Trusted Gateway",
  "components.Header.VirtualAssets": "Virtual Assets",
  "fiat.withdraw.SENDING_FOR_PUT_DONE": "处理中",
  "fiat.withdraw.SENDING_FOR_PUT": "处理中",
  "fiat.withdraw.SENDING_FOR_POST_DONE": "处理中",
  "fiat.withdraw.SENDING_FOR_POST": "处理中",
  "fiat.withdraw.SENDING_TRANSFER_DONE": "处理中",
  "fiat.withdraw.SENDING_TRANSFER": "处理中",
  "fiat.withdraw.WAITING_FOR_BANK_CONFIRM": "处理中",
  "fiat.withdraw.APPROVED": "处理中",
  "page.kyc.uploadTip": "只支持上传单个文件，请将多个文件合并为一个文件。（文件大小不能超过5M）",
  "pages.kycIndividual.CountryCode": "国家代码",
  "pages.kycIndividual.PersonalInformation": "个人信息",
  "input.placeholder.ValidateNumCode": "只能输入6位数字",
  "input.error.numberisNull": "请输入验证码",
  "input.error.numberLengthlimit": "只能输入6个字符。",
  "Server.codes.CURRENCY_ERROR": "未找到该币种。",
  "Server.codes.PARAMS_ERROR": "参数错误",
  "pages.kycIndividual.englishfieldError2": "需要英文或数字字符",
  "test-test": "test-test",
  "pages.kyc.AuthorizedPersonType.AuthorsiedTrader2": "授权人（交易商，如果不同于POA）",
  "components.Footer.SubmitRequest": "Submit request",
  "components.Footer.CustomerService": "Customer Service:support@matrix.co",
  "components.Footer.MarketingMedia": "Marketing:media@matrix.co",
  "components.Footer.Contact": "Contact",
  "components.Footer.Resources": "Resources",
  "components.Footer.PrivacyPolicy": "Privacy Policy",
  "components.Footer.UserAgreement": "User Agreement",
  "components.Footer.AMLCompliancePolicy": "AML & Compliance policy",
  "components.Footer.RiskStatement": "Risk Statement",
  "components.Footer.LegalAgreementStatement": "Legal Agreement / Statement",
  "components.Footer.FAQ": "FAQ",
  "components.Header.PressRelease": "Press Release",
  "components.Header.ManagementTeam": "Management Team",
  "components.Header.Contact": "Contact Us",
  "components.Header.Security": "Security",
  "components.Header.Fees": "Fees",
  "components.Footer.footerExplain3": "本网站上的信息不针对美国居民，也不供任何国家或司法管辖区内违反当地法律或法规的人员使用。",
  "components.Footer.footerExplain2": "矩阵交易所有限公司是一家注册于阿联酋阿布扎比Al Maryah岛阿布扎比全球市场广场Maqam大厦3519号的公司，由金融服务监管局根据阿布扎比全球市场法律授权和监管（注册号：000001876）。",
  "components.Footer.footerExplain1": "虚拟资产可能不稳定，可能不适合所有人。请确保您完全了解所涉及的风险。矩阵交易所不就收购、持有或处置虚拟资产发出建议、提出建议或意见。矩阵交易所不是财务顾问，所有服务都是在执行的基础上提供的。",
  "pages.Kyc.DeclarationForEntity3": "所提供的信息和文件真实，准确和完整，并同意将所提供的任何信息的任何修改立即通知Matrix Exchange。",
  "pages.kycInstitution.form.Expiration2": "驾驶执照有效期限",
  "pages.kyc.MultipleOptionsV3.no": "我们确认实体是美国人",
  "pages.kyc.MultipleOptionsV3.yes": "我们确认该实体不是美国人",
  "pages.kycInstitution.form.PEP.desc": "指受过或已经受托担任重要公共职能的自然人（包括相关的家庭成员或亲戚），包括但不限于国家元首或政府首脑，高级官员和公务员。 国际或超国家组织，高级政治人物，高级政府，司法或军事官员，大使，国有公司的高级管理人员或重要的政党官员，但在这些类别中不属于中级或以上初级人员。",
  "page.HomePage.3step3Content": "购买、出售和转让领先的虚拟资产。",
  "page.HomePage.3step2Content": "使用您的信用卡或一系列全球银行存款方法。",
  "page.HomePage.3step1Content": "为您选择正确的帐户并验证您的身份。",
  "page.HomePage.topItemTitle4": "管制的",
  "page.HomePage.topItemContent4": "Matrix获得阿布扎比全球市场（ADGM）金融服务监管局（FSRA）的许可。",
  "page.HomePage.1step": "世界级交易平台",
  "pages.kyc.SourceOfWealth.SaleRevenue": "销售收入",
  "pages.kyc.SourceOfFunds2": "资金来源-（虚拟资产/法定资产）",
  "pages.Account.idl.approved_account": "已批准",
  "pages.Account.idl.rejected_account": "被拒绝",
  "pages.kycIndividual.credentialsDocument2": "身份证",
  "pages.kycIndividual.SelfieDocument2": "授权人的自拍照文件",
  "pages.Institution.individualTypeOptions.Other": "其他",
  "pages.kycInstitution.form.CompanyListed2": "在交易所上市的公司？（帐户的授权交易员）",
  "pages.kycInstitution.form.CompanyWebsite2": "公司官网（帐户的授权交易员）",
  "pages.kycInstitution.form.OfficialEmail2": "官方邮箱（帐户的授权交易员）",
  "pages.kycInstitution.form.CountryOfOperations2": "运营国家（帐户的授权交易员）",
  "pages.kycInstitution.form.principalBusinessAddress2": "营业地址（帐户的授权交易员）\n",
  "pages.kycInstitution.form.registeredAddress2": "注册地址（帐户的授权交易员）",
  "pages.Account.kycType.PendingReview": "待审核",
  "account_history_CHANGE_FUND_PASSWORD": "修改资金密码",
  "account_history_BIND_FUND_PASSWORD": "设置资金密码",
  "account_history_OPEN_FUND_PASSWORD": "开启资金密码",
  "account_history_CLOSE_FUND_PASSWORD": "关闭资金密码",
  "pages.kycIndividual.POSITIONS": "职位",
  "pages.kycIndividual.POLITICALLY_EXPOSED_PERSON.desc": "在过去的十年中，您是否曾是政治人物？",
  "pages.kycIndividual.FAMILY_MEMBERS.desc": "政府或政治官员或高级管理人员的直系亲属，即配偶、父母、兄弟姐妹、子女以及配偶的父母和兄弟姐妹。",
  "pages.kycIndividual.FAMILY_MEMBERS.title": "家庭成员",
  "pages.kycIndividual.SENIOR_EXECUTIVES.desc": "在国有商业企业或国际组织中担任高级管理职务的个人，如董事或董事会成员，即由任何此类个人或为任何此类个人的利益而成立的公司、企业或其他实体。",
  "pages.kycIndividual.SENIOR_EXECUTIVES.title": "高级管理人员",
  "pages.kycIndividual.POLITICAL_PARTY_OFFICIALS.desc": "在国内外主要政党任职的高级官员。",
  "pages.kycIndividual.POLITICAL_PARTY_OFFICIALS.title": "政党官员",
  "pages.kycIndividual.GOVERNMENT_OFFICIALS.desc": "被任命担任国内政府职位或外国政府职位的现任或前任官员。这可能包括在行政、立法、行政、军事或司法部门工作的国家元首或个人，担任民选和非民选职务。",
  "pages.kycIndividual.GOVERNMENT_OFFICIALS.title": "政府官员",
  "pages.kyc.form.legalEntityTypeOptions.OtherPosition": "其他职位",
  "pages.kyc.form.ManagementDetails.SeniorManagementDetails": "高级管理人员详细信息",
  "pages.kyc.form.ManagementDetails.BoardOfDirectors": "董事会",
  "pages.Kyc.DeclarationForEntity2": "声明-我们确认提供的银行帐户详细信息是准确和完整的。提供的法人实体银行帐户详细信息将用于所有法定存款和取款。",
  "pages.Kyc.DeclarationForEntity1": "声明：我确认我是作为授权人代表该法人行事，而不是代表任何第二方行事",
  "pages.kyc.SourceOfWealth.ExternalInvestment": "外部投资",
  "pages.kyc.SourceOfWealth.ParentOrSisterCompany": "母/姊妹公司",
  "pages.kyc.SourceOfWealth.ShareholderInvestment": "股东投资",
  "pages.kyc.SourceOfWealth.SaleOfShares": "出售股份",
  "pages.kyc.SourceOfWealth.CompanyProfit": "公司利润",
  "pages.kycInstitution.form.IncorporationDate": "成立日期",
  "pages.kycIndividual.AccountDetails": "帐户详细资料",
  "pages.kyc.form.Definition.source_of_funds": "虚拟资产/法定资产",
  "account_history_CHANGE_LOGIN_PASSWORD": "修改登陆密码",
  "label.repeatNewPassword": "确认新密码",
  "label.newpassword": "新密码",
  "label.oldpassword": "旧密码",
  "pages.kycIndividual.form.error.source_of_funds": "请选择资金来源",
  "pages.kycIndividual.Business Activity": "商业活动",
  "pages.kycIndividual.SourceOfIncome.CapitalGains": "资本收益",
  "pages.kycIndividual.SourceOfIncome.RetirementFunding": "退休基金",
  "pages.kycIndividual.SourceOfIncome.Pensions": "退休金",
  "pages.kycIndividual.SourceOfIncome.Inheritance": "遗产",
  "pages.kycIndividual.SourceOfIncome.Royalty": "版税",
  "pages.kycIndividual.SourceOfIncome.Dividends": "股利",
  "pages.kycIndividual.SourceOfIncome.Rentals": "租金",
  "pages.kycIndividual.SourceOfIncome.Interests": "利息",
  "pages.kycIndividual.SourceOfIncome.Savings": "积蓄",
  "pages.kycIndividual.SourceOfIncome.Business": "商业",
  "pages.kycIndividual.SourceOfIncome.Salary": "薪资",
  "pages.kycIndividual.SourceOfIncome": "收入来源",
  "pages.kycIndividual.DriverLicenseDocument": "驾驶执照文件",
  "pages.kycIndividual.NationIDDocument": "身份证号码文件",
  "pages.kycIndividual.credentialsDocument.Back": "背面",
  "pages.kycIndividual.credentialsDocument.Front": "正面",
  "pages.kyc.new.form.PassportProvince": "签发地点",
  "pages.kycIndividual.AccountHolderResidentialAddress": "帐户持有人居住地址",
  "pages.kycIndividual.bankIBAN": "IBAN",
  "pages.kycIndividual.bankSwiftCode": "Swift 码",
  "pages.kyc.form.bank.zipCode": "Zip Code / Postal Code",
  "fiat.bankDetails": "银行明细",
  "pages.kyc.SourceOfFundsOptions.Loan": "贷款",
  "pages.kyc.SourceOfFundsOptions.Inheritance": "遗产",
  "pages.kyc.SourceOfFundsOptions.Investment": "投资",
  "pages.kyc.SourceOfFundsOptions.FamilyMembers": "家庭成员",
  "pages.kyc.SourceOfFundsOptions.MyOwnBusiness": "个体生意",
  "pages.kyc.SourceOfFundsOptions.Savings": "积蓄",
  "pages.kyc.SourceOfFundsOptions.SalaryAndEmployment": "薪金，就业",
  "pages.kyc.SourceOfFundsOptions": "资金来源",
  "pages.kycIndividual.TRADING": "交易",
  "pages.kycIndividual.HEDGING": "对冲",
  "pages.kycIndividual.LIQUIDITY_MANAGEMENT": "流动性管理",
  "pages.kycIndividual.type7": "套期保值",
  "pages.kycIndividual.type6": "流动资金管理",
  "pages.kyc.individual.extra.name": "跟护照一样",
  "pages.kyc.MultipleOptionsV2.no": "否",
  "pages.kyc.MultipleOptionsV2.yes": "是",
  "pages.kyc.form.Definition.SourceOfWealth": "产生或促成财富积累的财富/活动的来源。",
  "pages.kyc.form.Definition.registered": "公司注册/注册经营的地方",
  "app.containers.LoginPage.signUp": "创建账户",
  "app.containers.LoginPage.CreateAnAccount": "创建账户",
  "pages.kyc.form.Definition.source_of_wealth": "产生或促成财富积累的财富/活动的来源。",
  "pages.kyc.form.Definition.estimated_annual_income": "本年度收入/收入总额。",
  "pages.kyc.form.Definition.estimated_total_net_worth": "一个人的全部财富，不包括所持有的任何债务。",
  "pages.kyc.form.Definition.pep": "FSRA的政治公众人物（PEP）\n系指受委托或已受委托承担重要公共职能的自然人（在相关情况下包括家庭成员或近亲），包括但不限于国家元首或政府首脑、国际或超国家组织的高级官员和工作人员、高级政治家、高级政府、司法人员或军事官员、大使、国有企业高级管理人员或重要的政党官员，但不包括这些类别中的中层或更低级的个人。",
  "pages.kyc.form.Definition.usPerson4": "•  美国公司、美国合伙企业、美国房地产和美国信托。",
  "pages.kyc.form.Definition.usPerson3": "•  某些人每年在美国呆很长时间。",
  "pages.kyc.form.Definition.usPerson2": "•  居住在美国的人，包括美国绿卡持有者。",
  "pages.kyc.form.Definition.usPerson1": "•  美国公民，包括在美国出生但居住在另一国家或地区的个人（未放弃美国公民身份）。",
  "pages.kyc.form.Definition.Permanent": "住址或固定地址。",
  "pages.kyc.form.Definition.Mailing": "你收到邮件的地址。",
  "pages.kyc.form.Definition.Residential": "您目前居住或居住的地方。",
  "pages.kyc.form.Definition.taxResidency": "你有责任或义务纳税的地方。",
  "Server.codes.BANK_ACCOUNT_HAS_ALREADY_REGISTERED": "银行账号已经被注册了。",
  "pages.kyc.form.notRequired": "只能输入{max}个字符",
  "pages.kycInstitution.form.sameAsResidential": "和居住地址一样",
  "pages.kycInstitution.form.sametoregistere": "和注册地址一样",
  "pages.Institution.institutionTypeOptions.Other": "其他",
  "input.error.5_15.number": "输入长度必须介于 5~ 50 之间的字符串。",
  "input.error.percent.number": "只能输入0-100正整数",
  "pages.kycIndividual.changeTips": "请先开启双因子验证。",
  "pages.kyc.form.nationalityNames.same.err": "国籍重复。",
  "Server.codes.FILE_AMOUNT_ERROR": "请上传两个地址证明文件。",
  "page.kyc.individual.ApprovedTitle.Assessed": "经评估的专业客户是：",
  "page.kyc.individual.ApprovedTitle.i": "（B） 在合理的理由下，个人似乎对相关金融市场、产品或交易以及任何相关风险有足够的经验和理解",
  "page.kyc.individual.ApprovedTitle.h": "或者：\n\n（A） 在过去12个月内，或在过去2年内，如为推广护照基金，该个人是或曾经是获授权人士、认可机构或偏远机构的专业职位的雇员；或",
  "page.kyc.individual.ApprovedTitle.g": "（C） 任何在该人服务终止或死亡或退休时应支付的福利（以退休金或其他形式），以及该人或其受养人有权或可能有权获得的福利；",
  "page.kyc.individual.ApprovedTitle.f": "（B） 该人在FSMR所指的合格保险合同下的任何权利；或",
  "page.kyc.individual.ApprovedTitle.e": "（A） 该人的主要居住地的财产或该居住地所担保的任何贷款；",
  "page.kyc.individual.ApprovedTitle.d": "（i） 个人的净资产至少为50万美元，或在推广护照基金的情况下为100万美元（包括该个人直接或间接持有的任何资产），其计算必须排除：",
  "page.kyc.individual.ApprovedTitle.c": "（a） 在下列情况下，个人可被视为“经评估”的专业客户（而非零售客户）：",
  "page.kyc.individual.ApprovedTitle.b": "个人 ",
  "page.kyc.individual.ApprovedTitle.a": "评估“专业客户",
  "page.kyc.individual.Approved.Assessed": "经评估的专业客户是：",
  "Server.codes.PASSPORT_NUMBER_REQUIRED_AT_LEAST_FIVE_CHARACTER": "护照号不能少于5位。",
  "Server.codes.IDENTIFICATION_NUMBER_REQUIRED_AT_LEAST_FIVE_CHARACTER": "证件号不能少于5位。",
  "Server.codes.PASSPORT_HAS_ALREADY_REGISTERED": "护照号已经被注册了。",
  "Server.codes.BUSINESS_IS_NOT_ALLOWED": "您所从事的业务不允许进行 KYC 认证。",
  "Server.codes.COUNTRY_IS_NOT_ALLOWED": "您所居住的国家不允许进行 KYC 认证。",
  "pages.kyc.form.Management.Add": "添加管理详细信息",
  "pages.individual.document.Declaration": "我声明所提供的资料及文件是真实、准确及完整的，如有任何更改，本人同意立即通知Matrix交易所。",
  "pages.institution.document.Declaration": "声明所提供的信息和文件真实，准确，准确和完整。 同意立即并酌情通过Matrix Exchange Limited更新我们的个人资料。",
  "pages.kyc.return": "返回",
  "input.error.num_alphabet": "只能由数字或字母组成",
  "pages.kycIndividual.UAEresident": "阿联酋身份证",
  "pages.Account.kycType.INDIVIDUAL": "个人",
  "pages.kyc.proofAddressTypes.Name_Lives": "从选民登记册上查到的最新确认书，证明有这样一个名字的人住在那个地址。",
  "pages.kyc.proofAddressTypes.Valid_Tenancy_Agreement": "本年度有效的租赁协议。",
  "pages.kyc.proofAddressTypes.Bank_account": "银行账户或邮局储蓄银行账户对账单/存折（不少于3个月）。",
  "pages.kyc.proofAddressTypes.tax_returns": "本年度有效的地方当局纳税申报表（如议会税）。",
  "pages.kyc.proofAddressTypes.Household_utility": "家用公用事业费（如煤气费、电费、水费或固定电话费、互联网费，但不包括移动电话费）。它必须不超过三个月，并显示您的姓名和当前地址。",
  "pages.kyc.form.tip.validateUploadList": "需要上载至少两个不同的证书。",
  "pages.kyc.form.EntityChooseOther": "其他实体类型名称",
  "pages.kyc.form.legalEntityTypeOptions.Others": "其他",
  "pages.kyc.form.legalEntityTypeOptions.Government_Agency": "政府机构、中央银行、国家货币管理局",
  "pages.kyc.form.legalEntityTypeOptions.Financial_Institution": "金融机构",
  "pages.kyc.form.legalEntityTypeOptions.Sole_Proprietorship": "独资经营",
  "pages.kyc.form.legalEntityTypeOptions.Limited_Liability_Company": "有限责任公司",
  "pages.kyc.form.legalEntityTypeOptions.General_Partnership": "普通合伙企业",
  "pages.kyc.form.legalEntityTypeOptions.Corporation": "公司",
  "pages.kyc.form.EntityType": "实体类型",
  "pages.kycInstitution.form.purposes": "账户目的",
  "pages.kyc.form.nameOfTheRegularlyTradedCorporation": "该实体为相关实体的定期交易公司的名称",
  "pages.kyc.form.nameOfTheEstablishedSecuritiesMarket": "公司定期交易的已建立证券市场的名称",
  "pages.KycInstitution.Declaration": "我确认我代表公司作为授权人行事，而不是代表任何第二方。",
  "pages.Kyc.Declaration.err": "请同意这份声明",
  "pages.Kyc.Declaration": "声明-我确认我是代表我自己而不是代表任何第二方行事。",
  "pages.kyc.form.postalCode.err": "只能输入{max}个字符。",
  "pages.kyc.form.Controlling_Persons_Details.Add": "添加控制人详细信息",
  "app.dev.RecaptchaSitekey": "6Le33OEUAAAAAHHzu460MoCwVCSrHb4CnxGajqXg",
  "app.production.RecaptchaSitekey": "6Le33OEUAAAAAHHzu460MoCwVCSrHb4CnxGajqXg",
  "pages.kycIndividual.requiredDocumentTitle": "个人账户认证",
  "pages.kycIndividual.requiredDocument.Note1": "我们需要先验证您的身份，然后才能批准您的帐户。请选择有效的政府身份证类型并按照说明操作。",
  "pages.kycInstitution.requiredDocument.Note1": "我们需要先验证您的机构身份，然后才能批准您的帐户。请提供以下文件。",
  "pages.kyc.SourceOfWealth.Royalty": "王室成员",
  "pages.kyc.SourceOfWealth.Inheritance": "继承",
  "pages.kyc.SourceOfWealth.Parents": "父母",
  "pages.kyc.SourceOfWealth.Investment": "投资",
  "pages.kyc.SourceOfWealth.Business": "我自己的生意",
  "pages.kyc.SourceOfWealth.Salary": "工资、就业",
  "pages.kyc.form.legalEntityType.NFE": "2. 非金融实体（NFE）",
  "pages.kyc.form.legalEntityType.Fl": "1. 金融机构（FI）-投资实体",
  "input.error.number": "只能输入数字。",
  "page.kyc.individual.Approved.submit": "提交",
  "page.kyc.individual.Approved.Disagree": "不同意",
  "page.kyc.individual.Approved.Agree": "同意",
  "page.kyc.individual.Approved.chooseTitle": "同意作为零售客户/不同意作为零售客户。",
  "page.kyc.Institution.Approved.DisagreeContext": "通过选择零售客户，您有资格获得为零售客户提供的最高保护级别。",
  "page.kyc.individual.Approved.DisagreeContext": "通过选择专业客户，我作为客户将放弃对零售客户提供的最高保护级别",
  "Server.codes.CAN_NOT_CHANGE_BANK": "暂时无法修改银行账户。",
  "page.kyc.Institution.Approved.submit": "提交",
  "page.kyc.Institution.Approved.chooseTitle": "同意作为专业客户/不同意作为专业客户",
  "page.kyc.Institution.Approved.Disagree": "不同意",
  "page.kyc.Institution.Approved.Agree": "同意",
  "page.kyc.Institution.Approved.header": "您被列为Matrix交易所的专业客户。但是，您可以选择作为零售客户请求加入或退出。通过选择零售客户，您有资格为零售客户提供最高级别的保护。请在收到此邮件后4天内同意/不同意分类。如果您没有任何回应，您将被视为专业客户",
  "account_history_CHANGE_BANK": "修改银行账户信息",
  "Server.codes.DOES_NOT_MATCH_THE_REGISTERED_EMAIL": "本页面填写的邮箱与您的注册邮箱不一致。",
  "pages.kyc.form.MANAGEMENT.Other": "其他",
  "pages.kyc.form.MANAGEMENT.COO": "首席运营官",
  "pages.kyc.form.MANAGEMENT.CRO": "首席风险官",
  "pages.kyc.form.MANAGEMENT.CFO": "首席财务官",
  "pages.kyc.form.MANAGEMENT.CEO": "首席执行官",
  "pages.kycInstitution.form.BusinessActivity": "商业活动",
  "pages.kyc.BusinessActivityOptions.54": "赌场，在线赌场，在线赌博，游戏",
  "pages.kyc.BusinessActivityOptions.53": "慈善、协会、信托",
  "pages.kyc.BusinessActivityOptions.52": "车辆租赁、贸易、零部件",
  "pages.kyc.BusinessActivityOptions.51": "废金属、金属制造或贸易",
  "pages.kyc.BusinessActivityOptions.50": "政治家、部长、议员、政治组织",
  "pages.kyc.BusinessActivityOptions.49": "石油、天然气、化工、石化",
  "pages.kyc.BusinessActivityOptions.48": "非营利组织（NPO）、非政府组织（NGO）",
  "pages.kyc.BusinessActivityOptions.47": "货币服务业务",
  "pages.kyc.BusinessActivityOptions.46": "采矿",
  "pages.kyc.BusinessActivityOptions.45": "法官",
  "pages.kyc.BusinessActivityOptions.44": "珠宝、贵金属或宝石、奢侈品",
  "pages.kyc.BusinessActivityOptions.43": "国际事务",
  "pages.kyc.BusinessActivityOptions.42": "保险活动",
  "pages.kyc.BusinessActivityOptions.41": "政府机构、政府机构、军队",
  "pages.kyc.BusinessActivityOptions.40": "森林采伐、木材、造纸业",
  "pages.kyc.BusinessActivityOptions.39": "事件管理",
  "pages.kyc.BusinessActivityOptions.38": "密码和区块链",
  "pages.kyc.BusinessActivityOptions.37": "艺术家，艺术品交易，古董",
  "pages.kyc.BusinessActivityOptions.36": "替代医学",
  "pages.kyc.BusinessActivityOptions.35": "旅游业",
  "pages.kyc.BusinessActivityOptions.34": "零售店",
  "pages.kyc.BusinessActivityOptions.33": "房地产开发或交易",
  "pages.kyc.BusinessActivityOptions.55": "军事装备、武器贸易",
  "pages.kyc.BusinessActivityOptions.32": "进出口、国际贸易、货运、邮政、海运",
  "pages.kyc.BusinessActivityOptions.31": "加油站",
  "pages.kyc.BusinessActivityOptions.30": "时装设计、美容服务",
  "pages.kyc.BusinessActivityOptions.29": "施工活动、施工材料和设备",
  "pages.kyc.BusinessActivityOptions.28": "会计、审计、咨询",
  "pages.kyc.BusinessActivityOptions.27": "纺织品制造与贸易",
  "pages.kyc.BusinessActivityOptions.26": "电信、网络",
  "pages.kyc.BusinessActivityOptions.25": "体育",
  "pages.kyc.BusinessActivityOptions.24": "餐厅、酒店、娱乐商务、休闲服务",
  "pages.kyc.BusinessActivityOptions.23": "医疗、卫生服务",
  "pages.kyc.BusinessActivityOptions.22": "媒体、广告、出版",
  "pages.kyc.BusinessActivityOptions.21": "法律服务，律师，公证服务",
  "pages.kyc.BusinessActivityOptions.20": "工程、建筑、室内设计",
  "pages.kyc.BusinessActivityOptions.19": "计算机硬件或软件",
  "pages.kyc.BusinessActivityOptions.18": "银行、金融机构、金融服务",
  "pages.kyc.BusinessActivityOptions.17": "农业、畜牧业、食品生产、贸易",
  "pages.kyc.BusinessActivityOptions.16": "兽医",
  "pages.kyc.BusinessActivityOptions.15": "运输服务",
  "pages.kyc.BusinessActivityOptions.14": "可再生能源、环境服务",
  "pages.kyc.BusinessActivityOptions.13": "塑料制造",
  "pages.kyc.BusinessActivityOptions.12": "制药、医疗设备",
  "pages.kyc.BusinessActivityOptions.11": "个人服务（保姆、清洁、房屋维护…）",
  "pages.kyc.BusinessActivityOptions.10": "机械制造或贸易",
  "pages.kyc.BusinessActivityOptions.9": "人力资源、招聘、辅导、个人发展",
  "pages.kyc.BusinessActivityOptions.8": "五金设备、家具",
  "pages.kyc.BusinessActivityOptions.7": "平面设计、摄影、印刷",
  "pages.kyc.BusinessActivityOptions.6": "电气、电子制造和/或贸易",
  "pages.kyc.BusinessActivityOptions.5": "数据提供程序",
  "pages.kyc.BusinessActivityOptions.4": "航空、航空、航天",
  "pages.kyc.BusinessActivityOptions.3": "车辆制造商",
  "pages.kyc.BusinessActivityOptions.2": "教师、讲师、教育机构",
  "pages.kyc.BusinessActivityOptions.1": "图书馆、书店、文具店",
  "pages.kycInstitution.form.usPerson": "该实体是否为美国人？我们确认该实体不是美国人。",
  "pages.kycInstitution.Entityinformation": "实体信息",
  "pages.kyc.form.NameAndDetailsOfTheAlgorithm": "算法的名称和详细信息",
  "pages.kyc.form.NameOfPersonResponsibleForTheAlgorithm": "算法负责人姓名",
  "pages.kycInstitution.form.algorithmTrader": "你是算法交易者吗？",
  "pages.Institution.institutionTypeOptions.Financial_Institution": "金融机构",
  "pages.Institution.institutionTypeOptions.Sole_Proprietorship": "独资经营",
  "pages.Institution.institutionTypeOptions.Limited_Liability_Company": "有限责任公司",
  "pages.Institution.institutionTypeOptions.General_Partnership": "普通合伙企业",
  "pages.Institution.institutionTypeOptions.Corporation": "法人团体",
  "pages.Institution.institutionTypeOptions.Asset_Management": "资产管理",
  "pages.Institution.institutionTypeOptions.Broker": "经纪人",
  "pages.kyc.form.DetailsOfAuthorizedTraderInfos": "账户授权交易员详情",
  "pages.Institution.typeOfCustomerChoosesOptions.Entity": "实体",
  "pages.Institution.typeOfCustomerChoosesOptions.Individual": "个人",
  "pages.kyc.form.typeOfCustomerChooses": "授权交易者类型",
  "pages.kycInstitution.form.PEP": "政治公众人物",
  "pages.kycInstitution.form.marketIdentifierCode": "市场识别码(MIC)",
  "pages.kycInstitution.form.legalEntityIdentifierNumber": "法人识别号(LEI)",
  "Server.codes.INVALID_MULTIPLE_NATIONALITY": "请选择多个不同国家。",
  "Server.codes.USERNAME_NOT_FOUND": "邮箱或密码有误。",
  "Server.codes. USERNAME_NOT_FOUND": "邮箱有误",
  "Server.codes.USER_EMAIL_OR_PASSWORD_INVALID.num": "密码错误：您还可以尝试 {max} 次。",
  "app.form.ChineseCharacters": "不能包含中文字符。",
  "pages.Account.StepThree": "资产充值",
  "pages.Account.StepTwo": "安全设置",
  "pages.Account.StepOne": "身份认证",
  "pages.kyc.legalEntityDocumentType.Financial_statement": "最近3年的财务报表–如果适用",
  "pages.kyc.legalEntityDocumentType.Company_bank_statement": "公司银行对账单，包括账号、账户名称，即公司名称下（不少于3个月），用于资金转账。文件应为英文",
  "pages.kyc.legalEntityDocumentType.Board_resolution_from_the_company": "本公司向将代表本公司进行交易的获授权人提交的董事会决议，地址为Matrix Exchange Limited。如果POA与交易者相同，则上述内容就足够了。决议应说明经营该账户的限额（法定资产和虚拟资产）。指定有效期（账户交易期限）的董事会决议董事会委员会签署的信函",
  "pages.kyc.legalEntityDocumentType.Official_document": "说明当前营业/办公地址的正式文件-纳税申报表（当前年度）或有效的租赁协议",
  "pages.kyc.legalEntityDocumentType.Board_resolution": "从公司到POA的董事会决议，以通过Matrix Exchange开立并操作该帐户，并发送给Matrix Exchange。 指定有效期的董事会决议（交易帐户的持续时间）。 该信由董事会委员会签署。",
  "pages.kyc.legalEntityDocumentType.Authorized_signatories": "授权签署人名单",
  "pages.kyc.legalEntityDocumentType.Details_of_ownership": "所有权和结构详情（组织结构图",
  "pages.kyc.legalEntityDocumentType.Memorandum": "备忘录和公司章程",
  "pages.kyc.legalEntityDocumentType.Certificate_of_incorporation": "公司注册证书和公司注册文件",
  "pages.kyc.form.legalEntityDocumentType": "法律实体文件",
  "pages.kyc.AuthorizedPersonType.AuthorsiedTrader": "授权交易员",
  "pages.kyc.AuthorizedPersonType.POA": "POA",
  "pages.kyc.AuthorizedPersonType.Shareholders": "股东",
  "pages.kyc.AuthorizedPersonType.Directors": "董事会",
  "pages.kycInstitution.form.AuthorizedPersonType": "人员类型",
  "pages.kyc.entitySourceOfWealth.Others": "其他",
  "pages.kyc.entitySourceOfWealth.SaleOfShares": "股份出售",
  "pages.kyc.entitySourceOfWealth.CompanyProfit": "公司利润",
  "input.error.username": "只能由英文字母、数字和下划线组成。",
  "input.error.empty.required": "必填，只能输入{max}个字符。",
  "label.lastName": "姓",
  "label.firstName": "名字",
  "label.companyName": "公司名称",
  "pages.kyc.form.onBehalfOfTheLegalEntity": "作为交易账户的授权代理，您是否代表该法人实体进行交易？",
  "pages.kycInstitution.form.Expiration": "到期时间",
  "pages.kycInstitution.form.identification": "身份证明",
  "pages.Account.kycType.LEGAL_ENTITY": "法律实体",
  "pages.kycInstitution.form.CountryOfPrincipalBusiness": "主营业务国",
  "pages.kycInstitution.form.CountryOfRegistered": "注册国",
  "pages.kyc.new.form.street": "房屋/公寓/街道号",
  "pages.kyc.new.form.Province": "市/镇/省",
  "pages.kyc.new.form.PostalCode": "邮政编码",
  "pages.kyc.form.authorizedPersonInfosrmation": "交易账户授权书的详细信息",
  "pages.kyc.form.percentageOfOwnership": "所有权百分比",
  "pages.Institution.CompanyManagementDetails.Shareholders_Entity": "实体股东 - 25%或以上所有权的详细信息。",
  "pages.Institution.CompanyManagementDetails.Shareholders_Individual": "个人股东 - 25%或以上所有权的详细信息。",
  "pages.kyc.form.managementPosition": "高级管理职位",
  "pages.kycInstitution.form.PersonEmail": "邮箱ID",
  "pages.kyc.form.AuthorizedPersonInformation": "授权人信息",
  "pages.kyc.form.taxIdentification.Add": "添加纳税信息",
  "pages.kyc.form.taxIdentificationReasons": "原因",
  "pages.kyc.form.taxIdentificationStatus": "是否纳税识别号",
  "pages.kycIndividual.dateOfBirthError": "年纪必须大于18岁",
  "Server.codes.USERNAME_INVALID": "用户名必须为英文字母。",
  "input.register.BUSINESS": "企业",
  "input.register.INDIVIDUAL": "个人",
  "label.username": "用户名",
  "pages.kycInstitution.form.CompanyWebsite": "公司官网",
  "pages.kycInstitution.form.OfficialEmail": "官方邮箱",
  "pages.kycInstitution.form.CountryOfOperations": "运营国家",
  "pages.kycInstitution.form.IncorporationNumber": "实体编号",
  "pages.kycInstitution.form.CountryOfIncorporation": "实体所在国家",
  "pages.kycInstitution.form.TelephoneNumber": "号码",
  "pages.kycInstitution.form.TelephoneCode": "号码归属国家",
  "pages.kycInstitution.form.Tel": "电话号码",
  "pages.kyc.form.ManagementDetails": "管理人员详情",
  "pages.Institution.CompanyManagementDetails.Senior_Management": "高级管理层——首席执行官、首席财务官、首席运营官",
  "pages.Institution.CompanyManagementDetails.Board_of_Directors": "董事会",
  "pages.Institution.CompanyManagementDetails.Shareholders": "股东 - 25%或以上所有权的详细信息。添加5条记录详细信息的限制",
  "pages.kyc.form.CompanyManagementDetails": "公司管理人员细则",
  "pages.Institution.CONTROLLING_OPTIONS.m": "信托以外法律安排的控制人-其他（同等）",
  "pages.Institution.CONTROLLING_OPTIONS.l": "信托以外法律安排的控制人-受益人（同等）",
  "pages.Institution.CONTROLLING_OPTIONS.k": "信托以外法律安排的控制人-受托人的保护人（同等）",
  "pages.Institution.CONTROLLING_OPTIONS.j": "信托受托人以外的法律安排的控制人（同等）",
  "pages.Institution.CONTROLLING_OPTIONS.i": "信托委托人以外的法律安排的控制人（同等）",
  "pages.Institution.CONTROLLING_OPTIONS.h": "信托的控制人-其他",
  "pages.Institution.CONTROLLING_OPTIONS.g": "信托控制人-受益人",
  "pages.Institution.CONTROLLING_OPTIONS.f": "信托的控制人-受托人的保护人",
  "pages.Institution.CONTROLLING_OPTIONS.e": "信托的控制人-受托人",
  "pages.Institution.CONTROLLING_OPTIONS.d": "信托的控制人-委托人",
  "pages.Institution.CONTROLLING_OPTIONS.c": "法人控制人-高级管理人员",
  "pages.Institution.CONTROLLING_OPTIONS.b": "法人的控制人——以其他方式控制",
  "pages.Institution.CONTROLLING_OPTIONS.a": "法人的控制人——所有权控制",
  "pages.kycInstitution.form.Type_of_Controlling_person": "控制人类型",
  "pages.kyc.form.Controlling_Persons_Details": "控制人详情",
  "pages.kycInstitution.form.Legal_Entity_Type_classification": "法人类型分类-通用报告标准分类（CRS）",
  "pages.Institution.NFE_E": "e.被动非金融实体",
  "pages.Institution.NFE_D": "d.主动非金融实体–除（a），（b）或（c）选项外（例如，初创非金融实体或非盈利非金融实体）",
  "pages.Institution.NFE_C": "c.主动非金融实体-一个国际组织",
  "pages.Institution.NFE_B": "b.主动的非金融实体-政府实体或中央银行",
  "pages.Institution.NFE_A": "a.主动的非金融实体-其股票定期在已建立的证券市场上交易的公司或作为该公司相关实体的公司。",
  "pages.Institution.FI_B": "b.位于非参与管辖区并由另一金融机构管理的投资实体。",
  "pages.Institution.FI_A": "a.金融机构（存款机构、托管机构、指定保险公司或CRS参与管辖区内的投资实体）",
  "fiat.change.account": "变更账户？",
  "pages.kyc.form.CountryOfResidence": "税收居住地",
  "pages.kyc.form.taxIdentificationNumber": "税号",
  "pages.kyc.form.taxResidencyStatus": "纳税人信息",
  "pages.kyc.form.EmploymentStatus": "就业状况",
  "pages.kyc.form.EmployerName": "雇主名称",
  "pages.kyc.EmploymentStatus.OTHER": "股东、董事会、高级管理层",
  "pages.kyc.EmploymentStatus.NON_EMPLOYED": "非就业学生，退休，家庭主妇",
  "pages.kyc.EmploymentStatus.SELF_EMPLOYED": "自营",
  "pages.kyc.EmploymentStatus.EMPLOYED": "受雇（雇员）",
  "pages.kycIndividual.EmploymentStatus": "就业状况",
  "pages.kycIndividual.governmentStakeHolder": "政府利益相关者",
  "pages.kycIndividual.FAMILY_MEMBERS_AND_CLOSE_ASSOCIATES": "家庭成员和亲密伙伴：政府或政治官员或高级管理人员的直系亲属，即配偶、父母、兄弟姐妹、子女以及配偶的父母和兄弟姐妹。\n\n亲密关系人是指对法律实体、法律安排或关闭业务拥有共同实益所有权的个人\n\n与政治公众人物的关系；\n\n拥有法人实体唯一实益所有权或为政治公众人物的实际利益而设立的法律安排的任何个人。",
  "pages.kycIndividual.SENIOR_EXECUTIVES": "高级管理人员：在国有商业企业或国际组织中担任高级管理职务的个人，如董事或董事会成员，即由任何此类个人或为任何此类个人的利益而成立的公司、企业或其他实体。",
  "pages.kycIndividual.POLITICAL_PARTY_OFFICIALS": "政党官员：在国内外主要政党任职的高级官员。",
  "pages.kycIndividual.GOVERNMENT_OFFICIALS": "政府官员：被任命担任国内政府职位或外国政府职位的现任或前任官员。这可能包括在行政、立法、行政、军事或司法部门工作的国家元首或个人，担任民选和非民选职务。",
  "pages.kycIndividual.USPersonError": "根据内部政策，不能为您提供服务",
  "pages.kycIndividual.usPerson": "美国人声明-我确认我不是美国人。",
  "pages.kyc.form.PreviousStep": "上一步",
  "pages.kyc.form.nextStep": "下一步",
  "pages.kycInstitution.form.PassportTitle": "身份证件类型：护照",
  "pages.kycInstitution.form.PassportExpiration": "护照到期时间",
  "pages.kycIndividual.PassportNumber": "身份证件编号",
  "pages.kycInstitution.requiredDocument.Authorized.context2": "2.\t董事会、股东有效护照复印件（强制性）、EID（如果是阿联酋居民）、国民身份证、居住国的驾驶执照-顶部的下拉列表说明接受哪些文件）-任何2个身份证（如适用）",
  "pages.kycInstitution.requiredDocument.Authorized.context1": "1.\t有效护照复印件（强制性）、EID（阿联酋居民）、国家身份证、居住国驾驶执照-顶部的下拉列表说明接受哪些文件）。任何2个ID（如适用)",
  "pages.kycInstitution.requiredDocument.Authorized.title": "授权人、股东、董事会文件",
  "pages.kycInstitution.requiredDocument.Note2": "注：所有文件必须（清晰、可读、英文）。接受的文件类型-pdf，jpg格式。",
  "pages.kycInstitution.requiredDocument.title": "We need to verify your institution’s identity before we can approve your account .Please Provide the Following Documents.",
  "pages.kycInstitution.form.SourceOfWealth": "财富来源",
  "pages.kyc.EstimatedAnnualTurnover.above": "250million$ 以上",
  "pages.kyc.EstimatedAnnualTurnover.250Mln": "100million$ ~ 250million$",
  "pages.kyc.EstimatedAnnualTurnover.100Mln": "50million$ ~ 100million$",
  "pages.kyc.EstimatedAnnualTurnover.50Mln": "10million$ ~ 50million$",
  "pages.kyc.EstimatedAnnualTurnover.10Mln": "1million$ ~ 10million$",
  "pages.kyc.EstimatedAnnualTurnover.1Mln": "500,000$ ~ 1million$",
  "pages.kyc.EstimatedAnnualTurnover.5000000": "250,000$ ~ 500,000$",
  "pages.kyc.EstimatedAnnualTurnover.250000": "100,000$ ~ 250,000$",
  "pages.kyc.EstimatedAnnualTurnover.100000": "0 ~ 100,000$",
  "pages.kyc.FinancialStatement.above": "500million$ 以上",
  "pages.kyc.FinancialStatement.500Mln": "250million ~ 500million$",
  "pages.kyc.FinancialStatement.250Mln": "100million ~ 250million$",
  "pages.kyc.FinancialStatement.100Mln": "50million ~  100million$",
  "pages.kyc.FinancialStatement.50Mln": "10million ~ 50million$",
  "pages.kyc.FinancialStatement.10Mln": "1million ~ 10million$",
  "pages.kyc.FinancialStatement.1Mln": "500,000 ~ 1million $",
  "pages.kyc.FinancialStatement.500000": "250,000 ~ 500,000$",
  "pages.kyc.FinancialStatement.250000": "0 ~ 250,000$",
  "pages.kycInstitution.EstimatedAnnualTurnover": "估计年营业额（美元）",
  "pages.kycInstitution.FinancialStatement": "基于上次财务报表的总资产（美元）",
  "pages.kycInstitution.form.TickerCode": "公司代码",
  "pages.kycInstitution.form.WhichExchange": "交易所名称",
  "pages.kycInstitution.form.No": "否",
  "pages.kycInstitution.form.Yes": "是",
  "pages.kyc.form.CompanyListedRequired": "必填",
  "pages.kycInstitution.form.CompanyListed": "在交易所上市的公司？",
  "ga.change.step3": "更改双因子验证",
  "pages.kycIndividual.ResidentialAddress": "居住地址",
  "pages.kycIndividual.CountryOfResidential": "居住国家",
  "pages.kycIndividual.CountryOfMailing": "邮寄国家",
  "pages.kycIndividual.MailingAddress": "邮寄地址",
  "pages.kycIndividual.CountryOfPermanent": "永久居住国家",
  "pages.account.changeGASuccess": "成功。",
  "pages.kyc.totalNetWorthOption.above": "1 Mln $ 或以上",
  "pages.kyc.totalNetWorthOption.1min": "250,000-1Mln $以下",
  "pages.kyc.totalNetWorthOption.250000": "100,000 ~ 250,000$以下",
  "pages.kyc.totalNetWorthOption.100000": "50,000 ~ 100,000$以下",
  "pages.kyc.totalNetWorthOption.50000": "20,000 ～ 50,000$以下",
  "pages.kyc.totalNetWorthOption.20000": "0 ~20,000$以下",
  "pages.kyc.annualIncomeOption.above": "500,000 $ 或以上",
  "pages.kyc.annualIncomeOption.500000": "250,000  ~小于 500,000$",
  "pages.kyc.annualIncomeOption.250000": "100,000  ~小于 250,000$",
  "pages.kyc.annualIncomeOption.100000": "50,000 ～小于 100,000$",
  "pages.kyc.annualIncomeOption.50000": "20,000 ～小于 50,000$",
  "pages.kyc.annualIncomeOption.20000": "0 ~小于 20,000$",
  "pages.kyc.SourceIncomeOption.CapitalGains": "资本收益",
  "pages.kyc.SourceIncomeOption.Retirementfunding": "退休金",
  "pages.kyc.SourceIncomeOption.Pensions": "养老金",
  "pages.kyc.SourceIncomeOption.Inheritance": "遗产",
  "pages.kyc.SourceIncomeOption.Royalty": "版费",
  "pages.kyc.SourceIncomeOption.Dividends": "股份",
  "pages.kyc.SourceIncomeOption.Rentals": "租金",
  "pages.kyc.SourceIncomeOption.Interests": "收益",
  "pages.kyc.SourceIncomeOption.Savings": "储蓄",
  "pages.kyc.SourceIncomeOption.Business": "生意",
  "pages.kyc.SourceIncomeOption.Salary": "工资",
  "pages.kycIndividual.AccountStatus": "帐户状态",
  "pages.kycIndividual.CityOfBirth": "出生城市",
  "pages.kycIndividual.CountryOfBirth": "出生国家",
  "fiat.page.Interceptor.message1": "你必须要验证kyc才可以继续操作。",
  "pages.kycIndividual.Gender": "性别",
  "pages.kyc.MultipleOptions.Female": "女",
  "pages.kyc.MultipleOptions.Male": "男",
  "pages.kycIndividual.multipleNationality": "多重国籍",
  "pages.kyc.MultipleOptions.no": "否",
  "pages.kyc.MultipleOptions.yes": "是",
  "pages.kycIndividual.middleName": "中间名",
  "fiat.history.more": "展示",
  "label.vcode": "邮箱验证码",
  "Server.codes.WITHDRAW_AMOUNT_TOO_SMALL": "提现金额太小。",
  "Server.codes.INSUFFICIENT_AVAILABLE_BALANCE": "可用资产不足。",
  "fiat.history.comment": "留言",
  "fiat.know": "我知道了",
  "fiatWithdraw.successDetail": "你的请求已成功提交，请在历史记录里查看详情。\n",
  "fiatWithdraw.success": "提现成功",
  "Server.codes.SIGNATURE_ERROR": "账户异常，请联系客服。",
  "Server.codes.FUND_PASSWORD_NOT_NULL": "请先设置资金密码。",
  "account_history_AGREE_EXEMPTION": "同意免责条款",
  "pages.kycIndividual.englishfieldError": "必须英文字符",
  "iText.noData": "暂无数据",
  "fiat.searchForm.withdraw.option": "提现",
  "fiat.searchForm.deposit.option": "充值",
  "fiatWithdraw.description.text1": "国际提现将收取{feeRate}％的手续费，Matrix平台的最低收费标准为{minimumFee}美元，另外可能会产生额外的国际银行转账手续费。 国际提现的最低金额为{minimumAmount}美元。",
  "fiatWithdraw.description.text6": "请输入银行账号且该银行账号不需要做FFC（进一步贷记）说明。FFC付款将不被处理。",
  "fiatWithdraw.description.text5": "第三方提现是指意在将资金转移到与Matrix账户具有不同名义的帐户或金融机构的提现。",
  "fiatWithdraw.description.text4": "不允许通过第三方提现！",
  "fiatWithdraw.description.text3": " 第三方提现请求不予处理。",
  "fiatWithdraw.description.text2": "仅从主账户处理提现请求。",
  "fiatWithdraw.WithdrawFiatFunds": "国际电汇",
  "label.fiat.comment": "备注信息",
  "label.fiat.fundPassword": "资金密码",
  "fiat.withdraw.warning": "请验证下方的您账户信息，如果充值信息与KYC信息不符，充值将会退回。",
  "fiat.search.label.status": "状态",
  "fiat.search.label.type": "类型",
  "fiat.history.method": "操作方式",
  "fiat.history.message": "备注信息",
  "page.fiatAssets.history": "历史记录",
  "fiat.page.link.title": "法币资产",
  "components.Header.menuFiat": "法币资产",
  "fiat.withdraw.FAILURE": "失败",
  "fiat.withdraw.FREEZE": "冻结",
  "fiat.withdraw.FINISHED": "已完成",
  "fiat.withdraw.PROCESSING": "处理中",
  "fiat.deposit.CONFIRMING": "确认中",
  "fiat.deposit.FINISHED": "已完成",
  "fiat.deposit.FREEZE": "已冻结",
  "fiat.deposit.FAILURE": "失败",
  "fiat.deposit.WAITING_FOR_REFUND": "待退款",
  "fiat.deposit.WAITING_FOR_RISK": "待风控审核",
  "fiat.deposit.WAITING_FOR_COMPLIANCE": "待合规审核",
  "fiat.deposit.WAITING_FOR_OPERATION": "待运营审核",
  "fiat.deposit.all": "全部",
  "fiat.history.bankAccountName": "银行账户名称",
  "fiat.history.status": "状态",
  "fiat.history.amount": "金额数量",
  "fiat.history.type": "类型",
  "fiat.history.currency": "币种",
  "fiat.history.date": "时间",
  "fiat.history": "历史记录",
  "fiat.page.title": "法币资产",
  "fiat.available": "最高可提现额度",
  "fiatDeposit.title2": "将资金转入Matrix账户",
  "fiat.warning": "警告",
  "fiat.agree": "我已同意",
  "fiat.understand": "我已知晓",
  "fiat.important": "重要提示",
  "fiat.disClaimer": "免责声明",
  "fiat.accountNumberUSD": "账户号码（USD）",
  "fiat.address": "地址",
  "fiat.accountOwner": "账户所有者",
  "fiat.city": "城市",
  "fiat.bankAccountNumber": "银行账户号码",
  "fiat.country": "国家",
  "fiat.message": "充值备注",
  "fiat.bankAddress": "银行地址",
  "fiat.bankName": "银行名称",
  "fiat.accountName": "账户名称",
  "fiatDeposit.title": "将资金充值到Matrix平台",
  "fiatDeposit.description": "目前我们使用单一银行处理美元充值。每次通过银行汇款时，请您务必仔细核实充值页面上的银行帐户详细信息。因为银行账户详细信息可能会有变更！通过国际银行转帐进行充值时，Matrix平台不收取任何充值手续费，一般需要3-5个工作日到账。美元充值将直接反映在您账户的美元余额中，不会兑换成其他币种，所有其他币种将会免费兑换到指定币种余额中。请勿通过第三方向您的账户进行充值。转账之前请与我们的客服人员联系。什么是第三方充值？第三方充值是指与Matrix账户持有人具有不同名义的金融机构账户进行的充值。如有任何疑问，请与我们的客服人员联系。",
  "fiatDeposit.agreement4": "如果使用非本人账户充值，充值请求将会被自动原路退回，可能会产生相关费用。",
  "fiatDeposit.agreement3": "请不要使用企业账户往您的Matrix私人账户充值，反之亦然。",
  "fiatDeposit.agreement2": "请不要使用第三方账户，银行账户名称需要与您的名字相同。",
  "fiatDeposit.agreement1": "在您往Matrix打款前，请先完成KYC认证。",
  "pages.kyc.form.bank.BankAccountrequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.bank.BankNamerequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.bank.BankAddressRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.bank.accountNameRequired": "必填，只能输入{max}个字符",
  "pages.kycIndividual.bankStatement": "银行对帐单",
  "pages.kycIndividual.BankAccount": "银行账户",
  "pages.kycIndividual.BankName": "银行名称",
  "pages.kycIndividual.BankAddress": "银行地址",
  "pages.kycIndividual.BankCountry": "银行国家",
  "pages.kycIndividual.AccountName": "账户名称",
  "pages.kyc.tip.individualPanel.bankinfo": "银行信息",
  "Server.codes.CURRENT_DESCRIPTION_IS_TOO_LONG": "描述不能超过50个字符。",
  "pages.kycIndividual.SelfieDocument": "自拍照",
  "pages.kycIndividual.PassportDocument": "护照照片",
  "pages.kycIndividual.PassportCountry": "身份证件签发国",
  "Server.codes.UNAUTHORIZED_IP": "Matrix即将开放注册，敬请期待。",
  "pages.kycIndividual.credentialsSelfieDocumentButtonTitle": "自拍",
  "test": "测试",
  "Server.codes.TRIGGERON_SCALE_ERROR": "小数位数超限。",
  "Server.codes.AMOUNT_SCALE_ERROR": "小数位数超限。",
  "Server.codes.PRICE_SCALE_ERROR": "小数位数超限。",
  "Server.codes.TRIGGERON_NOT_LESS_THAN_PRICE": "触发价不能小于限价。",
  "Server.codes.TRIGGERON_NOT_GREATER_THAN_PRICE": "触发价不能大于限价。",
  "Server.codes.CAN_NOT_REGISTER_WITH_THIS_EMAIL": "此邮箱无法注册，请更换邮箱注册。",
  "pages.kyc.form.credentialsTypeRequired": "必填",
  "pages.kycIndividual.credentialsPassportDocumentButtonTitle": "护照",
  "account_history_CREATE_ANTI_PHISHING": "设置防钓鱼码",
  "Server.codes.TRIGGERON_NEED_GREATER_ZERO": "触发价必须大于零。",
  "Server.codes.PRICE_NEED_GREATER_ZERO": "价格必须大于零。",
  "account_history_EDIT_ANTI_PHISHING": "修改防钓鱼码",
  "account_history_ORDER_NOTIFICATION_ON": "订单通知打开",
  "account_history_WITHDRAW_NOTIFICATION_ON": "提现通知打开",
  "account_history_DEPOSIT_NOTIFICATION_ON": "充值通知打开",
  "account_history_ORDER_CONFIRM_ON": "交易确认弹框打开",
  "account_history_ORDER_CONFIRM_OFF": "交易确认弹框关闭",
  "account_history_DEPOSIT_NOTIFICATION_OFF": "充值通知关闭",
  "account_history_WITHDRAW_NOTIFICATION_OFF": "提现通知关闭",
  "account_history_ORDER_NOTIFICATION_OFF": "订单通知关闭",
  "Server.codes.JUMIO_SWITCH_STATUS_ON": "jumio认证升级中",
  "Server.codes.JUMIO_INDENTITY_TIMES_OUT": "KYC 认证尝试次数超限，请24小时后再试。",
  "Server.codes.CONNECTION_TIMEOUT": "连接超时。",
  "pages.kyc.errors.credentials_has_already_registered": "该证件号已注册",
  "pages.kyc.form.credentialsNumberMin": "不能少于5个字符",
  "pages.kyc.form.credentialsNumberMax": "不能超过五十个字符",
  "Server.codes.PASSWORD_ERROR_UPPER_LIMIT": "Your operation is too frequent,please try again later.",
  "Server.codes.CREDENTIALS_REQUIRED_AT_LEAST_FIVE_CHAR": "证件号码不能少于5个字符。",
  "Server.codes.USER_CANNOT_WITHDRAW_AFTER_FUND_PASSWORD_CHANGE": "为了您的资产安全，资金密码修改后24小时内不允许提现。",
  "Server.codes.USER_CANNOT_WITHDRAW_AFTER_AUTH_CODE_CHANGE": "为了您的资产安全，二次认证修改后24小时内不允许提现。",
  "app.home.tutorial.SignUpSrc": "https://cdn.matrix.co/videos/register_zh_cn.mp4",
  "app.home.tutorial.WithdrawSrc": "https://cdn.matrix.co/videos/withdraw_zh-cn.mp4",
  "app.home.tutorial.DepositSrc": "https://cdn.matrix.co/videos/Deposit_zh-cn.mp4",
  "app.home.tutorial.Withdraw": "提现",
  "app.home.tutorial.Deposit": "充值",
  "app.home.tutorial.DepositWithdraw": "充值/提现",
  "app.home.tutorial.SignUp": "注册",
  "app.home.tutorial.title": "新手引导",
  "pages.Account.switchTitle.OFF": "关",
  "pages.Account.switchTitle.ON": "开",
  "pages.Account.orderNotification.description": "获取订单的邮件通知",
  "pages.Account.withdrawNotification.description": "获取每笔提现的邮件通知",
  "pages.Account.depositNotification.description": "获取每笔充值的邮件通知",
  "pages.Account.orderNotification": "订单通知",
  "pages.Account.withdrawNotification": "提现通知",
  "pages.Account.depositNotification": "充值通知",
  "pages.Account.EmailNotification": "邮件通知",
  "Server.codes.NO_SATISFIED_MATRIX_ACCOUNT": "查不到对应的银行账号信息。",
  "Server.codes.MUST_BE_VIRTUAL_CURRENCY": "必须为加密货币。",
  "Server.codes.WITHDRAW_REQUEST_NOT_FOUND": "未找到该提现请求。",
  "Server.codes.WITHDRAW_ADDRESS_NOT_FOUND": "未找到该提现地址。",
  "Server.codes.CURRENCY_NOT_FIND": "未找到该币种。",
  "Server.codes.AMOUNT_CAN_NOT_LESS_ZERO": "金额必须大于零。",
  "Server.codes.CONFIRMS_NEED_GREATER_ZERO": "确认次数必须大于零。",
  "Server.codes.REPORT_MONTH_IS_NOT_NULL": "入参缺少月份。",
  "Server.codes.NOT_FOUND_USER_ADDRESS_OF_THE_CURRENCY": "通过UserId和Currency查不到充值地址。",
  "Server.codes.USER_LEVEL_IS_WRONG": "用户级别有误。",
  "Server.codes.DEPOSIT_RECORD_NOT_EXIST": "该存款记录不存在。",
  "Server.codes.MATCH_DETAIL_MISSING_SYMBOL": "详情页交易对缺失。",
  "Server.codes.AUDIT_COUNT_NOT_CONFIGURED": "未配置审核账户。",
  "Server.codes.THIS_MANAGER_AUDITED": "管理员已审核。",
  "Server.codes.ERROR_STATUS": "错误状态。",
  "Server.codes.BENEFICIARY_ACCOUNT_NOT_EXISTED": "受益人账户不存在。",
  "Server.codes.ADDRESS_NOT_EXIST": "地址不存在。",
  "Server.codes.BENEFICIARY_ACCOUNT_NOT_MATCH": "受益人账户不匹配。",
  "Server.codes.PASSWORD_CANNOT_SAME_AS_RECENTLY": "新密码不能与近期用过密码相同。",
  "Server.codes.KYC_REGISTRATIONDATE_ERROR": "登记时间格式有误。",
  "Server.codes.KYC_BIRTHDATE_ERROR": "出生日期格式有误。",
  "Server.codes.KYC_STATUS_TRANSFER_NOT_ALLOWED": "您目前不可转账，请查看KYC状态。",
  "Server.codes.KYC_TYPE_INVALID": "KYC类型无效。",
  "Server.codes.KYC_FOUNDATION_NOT_SET": "尚未设置KYC基础。",
  "Server.codes.KYC_DOC_STORAGE_ERROR": "KYC材料存储错误。",
  "Server.codes.OPERATION_NOT_ALLOWED_FOR_CURRENT_KYC_STATUS": "您需要完成相应的KYC等级认证，以进行此操作。",
  "Server.codes.OLD_AUTH_CODE_WRONG": "旧的验证码有误。",
  "Server.codes.NEW_AUTH_CODE_WRONG": "新的验证码有误。",
  "Server.codes.AUTH_CODE_BIND_REQUEST_EXPIRED": "您的安全验证绑定请求已过期，请重新绑定。",
  "Server.codes.AUTH_CODE_AUTH_EXPIRED": "GA Code Expires.",
  "Server.codes.AUTH_CODE_CODE_WRONG": "GA Code Error.",
  "Server.codes.AUTH_CODE_NOT_BOUND": "安全验证未绑定。",
  "Server.codes.AUTH_CODE_ALREADY_BOUND": "安全验证已绑定。",
  "Server.codes.AUTH_CODE_NOT_OPEN": "安全验证未开启。",
  "Server.codes.AUTH_CODE_NOT_NULL": "验证码不能为空。",
  "Server.codes.AUTH_CODE_INVALID": "验证码有误。",
  "Server.codes.FUND_PASSWORD_STATUS_SAME": "请勿设置相同状态的资金密码。",
  "Server.codes.FUND_PASSWORD_INVALID": "资金密码无效。",
  "Server.codes.FUND_PASSWORD_MISSING": "资金密码遗失。",
  "Server.codes.FUND_PASSWORD_WRONG": "资金密码有误。",
  "Server.codes.FUND_PASSWORD_NOT_SET": "尚未设置资金密码。",
  "Server.codes.SYMBOL_TRADE_TIME_OVER": "此交易对已经结束交易。",
  "Server.codes.SYMBOL_TRADE_NOT_START": "此交易对尚未开始交易。",
  "Server.codes.SYMBOL_NOT_FIND": "未发现此交易对。",
  "Server.codes.SYMBOL_CAN_NOT_NULL": "交易对不能为空。",
  "Server.codes.MARKET_ORDER_CANNOT_CANCEL": "市价单无法取消",
  "Server.codes.TABLE_NAME_INVALID": "表名有误。",
  "Server.codes.CANCELORDER_CANNOT_CREATE": "请勿重复关闭订单。",
  "Server.codes.AMOUNT_NEED_GREATER_ZERO": "金额必须大于零。",
  "Server.codes.ORDER_TYPE_UNSUPPORTED": "不支持该订单类型。",
  "Server.codes.IP_INVALID_OR_NUMBER_TOO_MAX": "IP无效或IP长度超限。",
  "Server.codes.TRIGGERON_IS_DISABLED": "触发关闭。",
  "Server.codes.MINING_IS_DISABLED": "挖矿关闭。",
  "Server.codes.SOURCE_INVALID": "来源无效。",
  "Server.codes.TRAILINGSTOP_IS_DISABLED": "追踪止损关闭。",
  "Server.codes.HIDDEN_IS_DISABLED": "隐藏交易关闭。",
  "Server.codes.POSTONLY_IS_DISABLED": "只做Maker关闭。",
  "Server.codes.IMMEDIATEORCANCEL_IS_DISABLED": "立即成交并取消剩余关闭。",
  "Server.codes.FILLORKILL_IS_DISABLED": "全部成交或立即取消关闭。",
  "Server.codes.IP_LENGTH_ERROR": "IP长度超限。",
  "Server.codes.TYPE_CAN_NOT_NULL": "类型不能为空。",
  "Server.codes.IP_CAN_NOT_NULL": "IP不能为空。",
  "Server.codes.STATUS_CAN_NOT_NULL": "状态不能为空。",
  "Server.codes.PASSWORD_ERROR": "密码错误。",
  "Server.codes.USER_NOT_EXIST": "用户不存在。",
  "Server.codes.USERID_CAN_NOT_NULL": "用户id不 能为空。",
  "Server.codes.UPLOAD_FREQUENT": "文件上传频率超限，请24小时后重新上传。",
  "Server.codes.TOTAL_FILE_SIZE_IS_TOO_LARGE": "文件总大小上传超限。",
  "pages.kyc.server_code.SINGLE_FILE_SIZE_IS_TOO_LARGE": "文件大小上传超限。",
  "Server.codes.UNSUPPORTED_FILE_TYPE": "请上传正确的文件类型。",
  "Server.codes.UNSUPPORTED_VALUE": "不支持的值。",
  "Server.codes.UNSUPPORTED_TYPE": "不支持此类型。",
  "Server.codes.ORIGIN_ANTI_PHISHING_CODE_INVALID": "旧防钓鱼码有误。",
  "Server.codes.ANTI_PHISHING_CODE_INVALID": "防钓鱼码有误。",
  "Server.codes.ANTI_PHISHING_CODE_MISSING": "防钓鱼码丢失。",
  "Server.codes.API_SERVICE_RESTRICT": "您的API 服务暂时受到限制。",
  "Server.codes.CREDENTIALS_HAS_ALREADY_REGISTERED": null,
  "Server.codes.BEYOND_DAILY_LIMIT": "已超出单日提现额度。",
  "Server.codes.BEYOND_MONTH_LIMIT": "已超出每月提现额度。",
  "Server.codes.CURRENCY_CAN_NOT_NULL": "币种不能为空。",
  "Server.codes.MISSING_VCODE": "Vcode丢失。",
  "Server.codes.DB_OPERATION_FAILED": "DB操作失败。",
  "Server.codes.VCODE_WRONG": "请输入正确的验证码。",
  "Server.codes.VCODE_EXPIRED": "您输入的邮箱验证码已过期,请重新获取。",
  "Server.codes.CAN_NOT_ADD_INTERNAL_ADDRESS": "无法添加内部地址。",
  "Server.codes.CAN_NOT_SIGN_IN": "由于您的账号异常，暂时无法登录，请留意邮件通知。",
  "Server.codes.RECORD_NOT_FOUND": "没有找到该记录。",
  "Server.codes.REFERRER_ID_INVALID": "推荐人ID不存在。",
  "Server.codes.EMAIL_INVALID": "邮箱格式有误。",
  "Server.codes.RULE_NOT_FOUND": "Rule没找到",
  "Server.codes.VCODE_SENDING_RATE_LIMITED": "您的操作过于频繁，请稍后再试。",
  "Server.codes.MAN_MACHINE_CHECK_ERROR": "图片验证失败。",
  "Server.codes.TRANSACTION_REFERENCE_NOT_EXISTED": "交易信息不存在。",
  "Server.codes.AUTHORITY_LIMITED": "尚无权限。",
  "Server.codes.FIND_BACK_PASSWORD_EXPIRED_CODE": "验证码已过期。",
  "Server.codes.FIND_BACK_PASSWORD_WRONG_CODE": "验证失败，请重新获取新的验证码并正确输入。",
  "Server.codes.FIND_BACK_PASSWORD_NOT_EXIST": "验证码不存在。",
  "Server.codes.FIND_BACK_PASSWORD_NOT_EXPIRED": "找回的密码已过期。",
  "Server.codes.MISSING_PARAMETER": "参数丢失。",
  "Server.codes.MISSING_REGISTRATION_REQUEST": "注册请求丢失。",
  "Server.codes.REGISTRATION_ACTIVATION_INVALID": "验证码不正确或已过期。",
  "Server.codes.INTERNAL_SERVER_ERROR": "服务器内部错误。",
  "Server.codes.SYSTEM_MAINTAIN": "系统维护中。",
  "Server.codes.REQUEST_BODY_TOO_LARGE": "请求失败。",
  "Server.codes.OPERATION_FAILED": "操作失败。",
  "Server.codes.OPERATION_CANNOT_RETRY": "请勿重复操作。",
  "Server.codes.DECRYPT_FAILED": "解密失败。",
  "Server.codes.ENCRYPT_FAILED": "加密失败。",
  "Server.codes.WITHDRAW_INVALID_STATUS": "权限异常，请联系客服。",
  "Server.codes.WITHDRAW_DISABLED": "权限异常，请联系客服。",
  "Server.codes.ADDRESS_MAXIMUM": "已达到币种提现地址的最大数量限制，不能提交。",
  "Server.codes.ADDRESS_NOT_ALLOWED": "未找到该地址。",
  "Server.codes.ADDRESS_CHECK_FAILED": "地址验证失败。",
  "Server.codes.ADDRESS_DUPLICATED": "提现地址重复。",
  "Server.codes.ADDRESS_INVALID": null,
  "Server.codes.RETRY_LATER": "请稍后再试。",
  "Server.codes.DEPOSIT_CANCEL": "取消存款。",
  "Server.codes.DEPOSIT_FAILED": "存款失败。",
  "Server.codes.ORDER_CANNOT_CANCEL_WITH_STATUS_FULLY_CANCELLED": "请勿频繁点击。",
  "Server.codes.ORDER_CANNOT_CANCEL_WITH_STATUS_PARTIAL_CANCELLED": "ORDER_CANNOT_CANCEL_WITH_STATUS_PARTIAL_CANCELLED",
  "Server.codes.ORDER_CANNOT_CANCEL": "取消订单失败。",
  "Server.codes.ORDER_NOT_FOUND": "没有找到此订单。",
  "Server.codes.USER_EMAIL_OR_PASSWORD_INVALID": "邮箱/用户名或密码有误。",
  "Server.codes.USER_EMAIL_EXIST": "用户名或者邮箱已存在。",
  "Server.codes.USER_CANNOT_DEPOSIT": "您暂时无法充值。",
  "Server.codes.CURRENT_ADDRESS_CANNOT_WITHDRAW": "当前地址为交易平台地址，不能提交。",
  "Server.codes.USER_CANNOT_WITHDRAW_AFTER_LOGIN_PWD_CHANGE": "修改登录密码后，24小时内不允许提现。",
  "Server.codes.USER_CANNOT_WITHDRAW": "您暂时无法提现。",
  "Server.codes.USER_CANNOT_TRADE": "您的账户暂时没有交易权限。",
  "Server.codes.USER_CANNOT_SIGNIN": "您暂时无法登录。",
  "Server.codes.USER_NOT_FOUND": "该账户尚未注册。",
  "Server.codes.ACCOUNT_UNLOCK_FAILED": "您的账户已解锁。",
  "Server.codes.ACCOUNT_LOCK_FAILED": "您的账户已被锁。",
  "Server.codes.ACCOUNT_UNFREEZE_FAILED": "您的账户解冻失败。",
  "Server.codes.ACCOUNT_FREEZE_FAILED": "您的账户冻结失败。",
  "Server.codes.AUTH_SIGNIN_FAILED": "该账户尚未注册。",
  "Server.codes.AUTH_SIGNIN_REQUIRED": "需要授权。",
  "Server.codes.AUTH_USER_NOT_ACTIVE": "您的账户尚未激活。",
  "Server.codes.AUTH_USER_FORBIDDEN": "您暂时无权访问该账户。",
  "Server.codes.OLD_PASSWORD_ERROR": "旧密码有误。",
  "Server.codes.AUTH_SIGNATURE_INVALID": "您的签名无效。",
  "Server.codes.AUTH_IP_FORBIDDEN": "系统检测IP异常，已被禁止访问。",
  "Server.codes.AUTH_APIKEY_DISABLED": "您的API密钥已被禁用，请联系我们的客服团队。",
  "Server.codes.AUTH_APIKEY_INVALID": "您的API密钥无效，请检查是否已正确复制。",
  "Server.codes.AUTH_AUTHORIZATION_EXPIRED": "您的登录状态已失效，请重新登录。",
  "Server.codes.AUTH_AUTHORIZATION_INVALID": "无效权限。",
  "Server.codes.HEADER_INVALID": "请求头无效。",
  "Server.codes.PARAMETER_INVALID": null,
  "ga.change.page.waring.message": "为了您的资产安全，双因子验证码修改后24小时内不允许提现。",
  "page.resetPwd.FoundPwd": "找回密码",
  "ga.change.step.description2": "请输入6位谷歌验证码和邮箱验证码",
  "ga.change.step.description1": "安装完成后打开谷歌身份验证器，扫描下方二维码或手动输入密钥，获得6位数验证码。",
  "ga.global.prev": "上一步",
  "ga.global.next": "下一步",
  "ga.change.page.title": "更改双因子验证",
  "ga.qrSection.notice": "请保管好您的密钥，以防手机丢失或更换手机导致无法登陆",
  "ga.set.androidLink": "https://android.myapp.com/myapp/detail.htm?apkName=com.google.android.apps.authenticator2",
  "ga.set.googlePlayLink": "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=zh",
  "ga.set.appStoreLink": " https://apps.apple.com/us/app/google-authenticator/id388497605",
  "ga.set.step.description3": "启用您的谷歌身份验证器",
  "ga.set.step.description2": "打开谷歌验证器，扫描下方二维码或手动输入下述密钥添加验证令牌",
  "ga.set.step.description1": "下载谷歌验证APP",
  "ga.label.stepName": "步骤",
  "ga.set.page.title": "开启谷歌验证",
  "ga.set.step3": "开启谷歌验证",
  "ga.set.step2": "扫描二维码",
  "ga.set.step1": "下载APP",
  "page.homepage.ipaUrl": "https://www.jinse.com/blockchain/408606.html",
  "page.homepage.ipaText": "阿布扎比全球市场金融服务监管委员会（FSRA）授权",
  "page.HomePage.3step3": "交易",
  "page.HomePage.3step2": "为您的帐户提供资金",
  "page.HomePage.3step1": "注册",
  "page.HomePage.3step": "只需三步，即刻开启数字货币交易",
  "page.HomePage.partners": "合作伙伴",
  "page.HomePage.servicesItemContent3": "一流的冷藏系统和风险控制。",
  "page.HomePage.servicesItemContent2": "开立帐户，用美元结算交易。",
  "page.HomePage.servicesItemContent1": "支持与美元配对的顶级虚拟资产交易。",
  "page.HomePage.servicesItemTitle3": "安全保管",
  "page.HomePage.servicesItemTitle2": "Fiat On-Boarding and Settlement",
  "page.HomePage.servicesItemTitle1": "强劲的交易",
  "page.HomePage.servicesTitle": "平台服务",
  "pages.Account.orderConfirmFlag.server.success": "操作成功",
  "pages.Account.orderConfirmFlag.server.failure": "操作失败",
  "pages.trade.orderConfirmWin.marketConfirm": "确定用{total} {baseCurrency} {type}等价的 {currency}？",
  "page.Withdraw.unReceive": "未收到确认邮件？",
  "page.Withdraw.more": "还是没收到",
  "page.Withdraw.helplink": "https://support.matrix.co/hc/zh-cn/articles/360025533474",
  "page.Withdraw.unReceiveDes": "如果长时间未收到邮件，请尝试在垃圾邮件或其他文件中查找。",
  "page.deposit.unReceive": "充值没有到账？",
  "page.deposit.more": "更多",
  "page.deposit.helplink": "https://support.matrix.co/hc/zh-cn/articles/360039971374",
  "pages.trade.orderConfirmWin.securityCenter": "账户安全",
  "pages.trade.orderConfirmWin.popTip": "如需再次开启交易提醒，请在{link}打开交易提醒",
  "pages.trade.orderConfirmWin.noAlertTip": "不再提示",
  "pages.trade.orderConfirmWin.confirm": "是否确认{type} {amount} {currency} ?",
  "page.deposit.unReceiveDes": "如果长时间未到账，有可能是因为区块拥堵或转出平台未成功转出。",
  "pages.trade.orderConfirmWin.confirmBtn": "确认",
  "pages.trade.orderConfirmWin.cancelBtn": "取消",
  "pages.trade.orderConfirmWin.tradeMethod.sell": "售出",
  "pages.trade.orderConfirmWin.tradeMethod.buy": "买入",
  "pages.Account.orderConfirmFlagDesc": "每次下单时，将会提醒您二次确认",
  "pages.Account.orderConfirmFlagTitle": "交易确认弹框",
  "pages.Account.orderConfirmFlag.switchTitle.OFF": "关",
  "pages.Account.orderConfirmFlag.switchTitle.ON": "开",
  "pages.OrderBook.tip.need_kyc_verify": "请先完成{link}认证",
  "pages.api.errors.INVALID_IP_OR_NUMBER_TOO_MAX": "IP无效或IP的数量大于最大值。",
  "pages.api.canCreateTip": "剩余可添加API Key数量: {remain}",
  "page.withdrawAddress.balance": "剩余可添加数量：{balance}",
  "pages.kyc.form.tip.proofAddressSelectFile": "只支持单个文件上传，如有多个文件，请合并为一个之后上传。(文件大小不能超过5M)",
  "app.containers.RegisterPage.registerDisabledMessage": "Matrix即将开放注册，敬请期待",
  "pages.api.unauthBeforeSettingTip.ga": "为了您的账户安全，设置API前请先绑定{GALink}",
  "pages.api.unauthBeforeSettingTip.kyc": "为了您的账户安全，设置API前请先完成{KYCLink}",
  "pages.api.unauthBeforeSettingTip.all": "为了您的账户安全，设置API前请先完成{KYCLink}并绑定{GALink}。",
  "cc.label.ZW": "津巴布韦",
  "cc.label.ZM": "赞比亚",
  "cc.label.YE": "也门",
  "cc.label.EH": "西撒哈拉",
  "cc.label.WF": "瓦利斯群岛和富图纳群岛",
  "cc.label.VN": "越南",
  "cc.label.VE": "委内瑞拉",
  "cc.label.VA": "梵蒂冈",
  "cc.label.VU": "瓦努阿图",
  "cc.label.UZ": "乌兹别克斯坦",
  "cc.label.UY": "乌拉圭",
  "cc.label.US": "美国",
  "cc.label.GB": "英国",
  "cc.label.AE": "阿拉伯联合酋长国",
  "cc.label.UA": "乌克兰",
  "cc.label.UG": "乌干达",
  "cc.label.VI": "美属维京群岛",
  "cc.label.TV": "图瓦卢",
  "cc.label.TC": "特克斯和凯科斯群岛",
  "cc.label.TM": "土库曼斯坦",
  "cc.label.TR": "土耳其",
  "cc.label.TN": "突尼斯",
  "cc.label.TT": "特立尼达和多巴哥",
  "cc.label.TO": "汤加",
  "cc.label.TK": "托克劳",
  "cc.label.TG": "多哥",
  "cc.label.TH": "泰国",
  "cc.label.TZ": "坦桑尼亚",
  "cc.label.TJ": "塔吉克斯坦",
  "cc.label.TW": "中国台湾省",
  "cc.label.SY": "叙利亚",
  "cc.label.CH": "瑞士",
  "cc.label.SE": "瑞典",
  "cc.label.SZ": "斯威士兰",
  "cc.label.SJ": "斯瓦尔巴和扬马延",
  "cc.label.SR": "苏里南",
  "cc.label.SD": "苏丹",
  "cc.label.LK": "斯里兰卡",
  "cc.label.ES": "西班牙",
  "cc.label.SS": "南苏丹共和国",
  "cc.label.KR": "韩国",
  "cc.label.ZA": "南非",
  "cc.label.SO": "索马里",
  "cc.label.SB": "所罗门群岛",
  "cc.label.SI": "斯洛文尼亚",
  "cc.label.SK": "斯洛伐克",
  "cc.label.SX": "圣马丁",
  "cc.label.SG": "新加坡",
  "cc.label.SL": "塞拉利昂",
  "cc.label.SC": "塞舌尔",
  "cc.label.RS": "塞尔维亚;",
  "cc.label.SN": "塞内加尔",
  "cc.label.SA": "沙特阿拉伯",
  "cc.label.ST": "圣多美和普林西比",
  "cc.label.SM": "圣马力诺",
  "cc.label.WS": "萨摩亚",
  "cc.label.VC": "圣文森特岛",
  "cc.label.PM": "圣皮埃尔和密克隆岛",
  "cc.label.MF": "圣马丁",
  "cc.label.LC": "圣卢西亚",
  "cc.label.KN": "圣基茨和尼维斯联邦",
  "cc.label.SH": "圣赫勒拿岛",
  "cc.label.BL": "巴勒斯坦",
  "cc.label.RW": "卢旺达",
  "cc.label.RU": "俄罗斯",
  "cc.label.RO": "罗马尼亚",
  "cc.label.RE": "留尼汪",
  "cc.label.CG": "刚果",
  "cc.label.QA": "卡塔尔",
  "cc.label.PR": "波多黎各",
  "cc.label.PT": "葡萄牙",
  "cc.label.PL": "波兰",
  "cc.label.PN": "皮特凯恩群岛",
  "cc.label.PH": "菲律宾",
  "cc.label.PE": "秘鲁",
  "cc.label.PY": "巴拉圭",
  "cc.label.PG": "巴布亚新几内亚",
  "cc.label.PA": "巴拿马",
  "cc.label.PS": "巴勒斯坦",
  "cc.label.PW": "帕劳共和国",
  "cc.label.PK": "巴基斯坦",
  "cc.label.OM": "阿曼",
  "cc.label.NO": "挪威",
  "cc.label.MP": "北马里亚纳群岛",
  "cc.label.KP": "朝鲜",
  "cc.label.NU": "纽埃",
  "cc.label.NG": "尼日利亚",
  "cc.label.NE": "尼日尔",
  "cc.label.NI": "尼加拉瓜",
  "cc.label.NZ": "新西兰",
  "cc.label.NC": "新喀里多尼亚",
  "cc.label.AN": "安的列斯群岛",
  "cc.label.NL": "荷兰",
  "cc.label.NP": "尼泊尔",
  "cc.label.NR": "瑙鲁",
  "cc.label.NA": "纳米比亚",
  "cc.label.MM": "缅甸",
  "cc.label.MZ": "莫桑比克",
  "cc.label.MA": "摩洛哥",
  "cc.label.MS": "蒙特塞拉特岛",
  "cc.label.ME": "黑山共和国",
  "cc.label.MN": "蒙古",
  "cc.label.MC": "摩纳哥",
  "cc.label.MD": "摩尔多瓦",
  "cc.label.FM": "密克罗尼西亚",
  "cc.label.MX": "墨西哥",
  "cc.label.YT": "马约特",
  "cc.label.MU": "毛里求斯",
  "cc.label.MR": "毛里塔尼亚伊斯兰共和国",
  "cc.label.MH": "马绍尔群岛",
  "cc.label.MT": "马耳他",
  "cc.label.ML": "马里",
  "cc.label.MV": "马尔代夫",
  "cc.label.MY": "马来西亚",
  "cc.label.MW": "马拉维",
  "cc.label.MG": "马达加斯加",
  "cc.label.MK": "马其顿共和国",
  "cc.label.MO": "中国澳门特别行政区",
  "cc.label.LU": "卢森堡",
  "cc.label.LT": "立陶宛",
  "cc.label.LI": "列支敦士登",
  "cc.label.LY": "利比亚",
  "cc.label.LR": "利比里亚",
  "cc.label.LS": "莱索托",
  "cc.label.LB": "黎巴嫩",
  "cc.label.LV": "拉脱维亚",
  "cc.label.LA": "老挝",
  "cc.label.KG": "吉尔吉斯坦",
  "cc.label.KW": "科威特",
  "cc.label.XK": "科索沃",
  "cc.label.KI": "基里巴斯",
  "cc.label.KE": "肯尼亚",
  "cc.label.KZ": "哈萨克斯坦",
  "cc.label.JO": "约旦",
  "cc.label.JE": "泽西岛",
  "cc.label.JP": "日本",
  "cc.label.JM": "牙买加",
  "cc.label.CI": "科特迪瓦",
  "cc.label.IT": "意大利",
  "cc.label.IL": "以色列",
  "cc.label.IM": "马恩岛",
  "cc.label.IE": "爱尔兰",
  "cc.label.IQ": "伊拉克",
  "cc.label.IR": "伊朗",
  "cc.label.ID": "印度尼西亚",
  "cc.label.IN": "印度",
  "cc.label.IS": "冰岛",
  "cc.label.HU": "匈牙利",
  "cc.label.HK": "中国香港特别行政区",
  "cc.label.HN": "洪都拉斯",
  "cc.label.HT": "海地",
  "cc.label.GY": "圭亚那",
  "cc.label.GW": "几内亚比绍共和国",
  "cc.label.GN": "几内亚",
  "cc.label.GG": "根西",
  "cc.label.GT": "危地马拉",
  "cc.label.GU": "关岛",
  "cc.label.GD": "格林纳达",
  "cc.label.GL": "格陵兰岛",
  "cc.label.GR": "希腊",
  "cc.label.GI": "直布罗陀",
  "cc.label.GH": "加纳",
  "cc.label.DE": "德国",
  "cc.label.GE": "格鲁吉亚",
  "cc.label.GM": "冈比亚",
  "cc.label.GA": "加蓬",
  "cc.label.PF": "法属玻利尼西亚",
  "cc.label.FR": "法国",
  "cc.label.FI": "芬兰",
  "cc.label.FJ": "斐济",
  "cc.label.FO": "法罗群岛",
  "cc.label.FK": "福克兰群岛",
  "cc.label.ET": "埃塞俄比亚",
  "cc.label.EE": "爱沙尼亚",
  "cc.label.ER": "厄立特里亚",
  "cc.label.GQ": "赤道几内亚",
  "cc.label.SV": "萨尔瓦多",
  "cc.label.EG": "埃及",
  "cc.label.EC": "厄瓜多尔",
  "cc.label.TL": "东帝汶民主共和国",
  "cc.label.DO": "多米尼加共和国",
  "cc.label.DM": "多米尼克国",
  "cc.label.DJ": "吉布提",
  "cc.label.DK": "丹麦",
  "cc.label.CD": "刚果民主共和国",
  "cc.label.CZ": "捷克",
  "cc.label.CY": "塞浦路斯",
  "cc.label.CW": "库拉索酒",
  "cc.label.CU": "古巴",
  "cc.label.HR": "克罗地亚",
  "cc.label.CR": "哥斯达黎加",
  "cc.label.CK": "库克群岛",
  "cc.label.KM": "科摩罗联盟",
  "cc.label.CO": "哥伦比亚",
  "cc.label.CC": "科科斯群岛",
  "cc.label.CX": "圣诞岛",
  "cc.label.CN": "中国",
  "cc.label.CL": "智利",
  "cc.label.TD": "乍得",
  "cc.label.CF": "中非共和国",
  "cc.label.KY": "开曼群岛",
  "cc.label.CV": "佛得角共和国",
  "cc.label.CA": "加拿大",
  "cc.label.CM": "喀麦隆",
  "cc.label.KH": "柬埔寨",
  "cc.label.BI": "布隆迪",
  "cc.label.BF": "布基纳法索",
  "cc.label.BG": "保加利亚",
  "cc.label.BN": "文莱",
  "cc.label.VG": "英属维尔京群岛",
  "cc.label.IO": "英属印度洋领地",
  "cc.label.BR": "巴西",
  "cc.label.BW": "博茨瓦纳",
  "cc.label.BA": "波斯尼亚和黑塞哥维那",
  "cc.label.BO": "玻利维亚",
  "cc.label.BT": "不丹",
  "cc.label.BM": "百慕大群岛",
  "cc.label.BJ": "贝宁",
  "cc.label.BZ": "伯利兹",
  "cc.label.BE": "比利时",
  "cc.label.BY": "白俄罗斯",
  "cc.label.BB": "巴巴多斯",
  "cc.label.BD": "孟加拉国",
  "cc.label.BH": "巴林",
  "cc.label.BS": "巴哈马",
  "cc.label.AZ": "阿塞拜疆",
  "cc.label.AT": "奥地利",
  "cc.label.AU": "澳大利亚",
  "cc.label.AW": "阿鲁巴",
  "cc.label.AM": "亚美尼亚",
  "cc.label.AR": "阿根廷",
  "cc.label.AG": "安提瓜和巴布达",
  "cc.label.AQ": "南极洲",
  "cc.label.AI": "安圭拉岛",
  "cc.label.AO": "安哥拉",
  "cc.label.AD": "安道尔共和国",
  "cc.label.AS": "东萨摩亚",
  "cc.label.DZ": "阿尔及利亚",
  "cc.label.AL": "阿尔巴尼亚",
  "cc.label.AF": "阿富汗",
  "account_history_RESET_OR_CHANGE_PASSWORD": "重置密码",
  "page.HomePage.slogan": "开启数字货币世界之旅<br>",
  "account.operate.GA_INVALID": "谷歌验证码错误。",
  "page.fundPassword.openButton": "开启",
  "page.fundPassword.closeButton": "关闭",
  "page.FundPassword.close": "关闭资金密码",
  "pages.kyc.codes.JUMIO_SWITCH_STATUS_ON": "KYC升级中...",
  "pages.api.errorMsg.repeatedIP": "请不要添加重复的IP。",
  "pages.monthlyStatments.status.get_report_fail": "账户异常，请联系客服。",
  "page.deposit.Refunded": "已退款",
  "page.deposit.WaitingForRefund": "待退款",
  "page.deposit.Done": "已完成",
  "page.deposit.Failure": "失败",
  "auth.token.tip.refreshed_success": "刷新成功。",
  "pages.Account.kycType.null": "未认证",
  "bindGAFailure.GA_BIND_REQUEST_EXPIRED": "双因子验证码错误。",
  "public.code.error.GA_CODE_WRONG": "双因子验证码错误。",
  "page.FundPassword.requireRule": "资金密码只能设置6到20位之间数字或英文字母",
  "antiFishing.requireRule": "防钓鱼码只能设置6到20位之间数字或英文字母",
  "antiFishing.set.error": "设置防钓鱼码失败",
  "antiFishing.update.error": "修改防钓鱼码失败。",
  "antiFishing.update.success": "修改防钓鱼码成功。",
  "antiFishing.set.success": "设置防钓鱼码成功。",
  "antiFishing.canNotSame": "请勿设置与上次相同的防钓鱼码",
  "antiFishing.input.placeholder.new": "请输入防钓鱼码",
  "antiFishing.input.label.new": "新防钓鱼码",
  "antiFishing.input.placeholder.old": "请输入已设置的防钓鱼码",
  "antiFishing.input.label.old": "旧防钓鱼码",
  "antiFishing.input.placeholder": "请输入防钓鱼码",
  "antiFishing.input.label": "防钓鱼码",
  "antiFishing.title.update": "修改防钓鱼码",
  "antiFishing.title.set": "设置防钓鱼码",
  "account.antiFishing.label.title": "Matrix给您发送的邮件将包含您设置的防钓鱼码，可防范假冒邮件",
  "page.FundPassword.antiFishing": "防钓鱼码",
  "label.can.not.beEmpty": "此处不能为空",
  "account.operate.error": "操作失败",
  "account.operate.successfully": "操作成功。",
  "pages.kyc.errors.no_pane_error": "无错误",
  "pages.kyc.tip.collapsed": "折叠",
  "pages.kyc.tip.expanded": "展开",
  "pages.kyc.tip.uploaderFormatTip": "仅支持{fileFormatTotal}格式，总大小不超过{fileMByteTotal}M， 只能上传1个文件。",
  "pages.Account.idl.enhanced_kyc.description4.prefix": "提现额度：",
  "pages.Account.idl.enhanced_kyc.description3.prefix": "充值额度：",
  "pages.kyc.tip.counterpartyPanel.base": "基本信息",
  "pages.kyc.tip.individualEnhancedPanel.additionalInformationPane": "其他信息",
  "pages.kyc.EstimatedDailyTransactionsOptions.not_greater_than_10": "≤10 美元",
  "pages.kyc.EstimatedDailyTransactionsOptions.10_and_100": "10-100 美元",
  "pages.kyc.EstimatedDailyTransactionsOptions.100_and_500": "100-500 美元",
  "pages.kyc.EstimatedDailyTransactionsOptions.not_less_than_500": "≥ 500 美元",
  "pages.api.unauthBindToKYC": "KYC",
  "request.errors.status.504": "网络超时",
  "request.errors.status.503": "服务不可用，服务器暂时过载或维护。",
  "request.errors.status.502": "网关错误。",
  "request.errors.status.500": "服务器发生错误，请检查服务器。",
  "request.errors.status.422": "当创建一个对象时，发生一个验证错误。",
  "request.errors.status.410": "请求的资源被永久删除，且不会再得到的。",
  "request.errors.status.406": "请求的格式不可得。",
  "request.errors.status.404": "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  "request.errors.status.403": "用户得到授权，但是访问是被禁止的。",
  "request.errors.status.401": "用户没有权限（令牌、用户名、密码错误）。",
  "request.errors.status.400": "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  "request.errors.status.204": "删除数据成功。",
  "request.errors.status.202": "一个请求已经进入后台排队（异步任务）。",
  "request.errors.status.201": "新建或修改数据成功。",
  "request.errors.status.200": "服务器成功返回请求的数据。",
  "request.errors.message.network_error": "网络错误",
  "components.Header.menuAnnouncement": "公告中心",
  "pages.kyc.errors.jumio_try_times_limit": "每人每天限制3次验证，超出将禁止使用，请勿随意进行验证。",
  "pages.kycIndividual.credentialsDocument": "证件照",
  "pages.kycIndividual.idCardNumber": "身份证件编号",
  "account.kyc.Verification": "验证",
  "pages.kyc.careers.OTHER": "其他",
  "pages.kyc.careers.CIVIL_SERVANT_GOVERNMENT_STAFF": "公务员/政府职员",
  "pages.kyc.careers.SERVICE_INDUSTRY": "服务业",
  "pages.kyc.careers.CONSULTING_LEGAL_EDUCATION_RESEARCH_TRANSLATION": "咨询/法律/教育/科研/翻译",
  "pages.kyc.careers.PERSONNEL_ADMINISTRATION_SENIOR_MANAGEMENT": "人事/行政/高级管理",
  "pages.kyc.careers.CONSTRUCTION_REAL_ESTATE": "建筑/地产",
  "pages.kyc.careers.ADVERTISING_MARKETING_MEDIA_ART": "广告/市场/媒体/艺术",
  "pages.kyc.careers.BIOLOGICAL_PHARMACEUTICAL_MEDICAL_NURSING": "生物/制药/医疗/护理",
  "pages.kyc.careers.PRODUCTION_OPERATIONS_PURCHASING_LOGISTICS": "生产/运营/采购/物流",
  "pages.kyc.careers.FINANCE_INSURANCE_BANKING": "金融/保险/银行",
  "pages.kyc.careers.SALES_CUSTOMER_SERVICE_AFTER_SALES_SUPPORT_TECHNICAL_SUPPORT": "销售/客服/售后支持/技术支持",
  "pages.kyc.careers.ACCOUNTING_CASHIER_FINANCIAL_RELATED": "会计/出纳/财务相关",
  "pages.kyc.careers.COMPUTER_INTERNET_COMMUNICATION_ELECTRONICS": "计算机/互联网/通信/电子",
  "page.MonthlyStatements.none_month_list": "无资产报告",
  "pages.kyc.form.error_image_file_format": "只支持图片`{format} `",
  "account_history_CHANGE_API": "修改API",
  "account_history_DELETE_API": "删除API",
  "account_history_CREATE_API": "创建API",
  "account_history_CHANGE_GA": "修改GA",
  "account_history_BIND_GA": "绑定GA",
  "account_history_BIND_OR_CHANGE_FUND_PASSWORD": "资金密码状态变更",
  "account_history_CHANGE_STATUS_FUND_PASSWORD": "资金密码状态变更",
  "account_history_LOGIN": "登录",
  "components.Header.menuExchange": "开始交易",
  "ga.set.notice": "请保管好您的私钥，以免更换手机或手机丢失造成无法登录。",
  "ga.set.final.step": "请输入6位谷歌验证码和邮箱验证码来完成设置",
  "page.GA.Set.stepTitle.extra": "在安装并且打开谷歌身份验证器后，扫描下方二维码或者输入Key来获取6位数密码",
  "google.play.store": "谷歌应用商店",
  "app.store": "APP应用商店",
  "ga.copySuccess": "复制成功。",
  "ga.copyKey": "复制密钥",
  "page.resetPwd.modifyPwd": "修改登录密码",
  "page.account.title": "账户",
  "page.deposit.verify": "验证",
  "pages.kyc.form.credentialsNumberRequired": "必填",
  "pages.kyc.label.how_long_crypto_investment_experience": "您投资数字货币多久了？",
  "pages.kycIndividual.career": "职业",
  "page.deposit. Done": "已完成",
  "page.deposit. Refunded": "已退款",
  "page.deposit. WaitingForRefund": "待退款",
  "page.deposit. Failure": "失败",
  "page.deposit.PendingReview": "审核中",
  "pages.kyc.tip.individualEnhancedPanel.account_activity": "账户活动",
  "pages.kyc.tip.individualEnhancedPanel.company_information_and_business_activity": "公司信息与商业活动",
  "pages.kyc.errors.wellUploadFileTip": "（仅支持单个文件, 格式限制为zip, rar, doc, docx, xls, xlsx, ppt, pptx, pdf, png, jpg, jpeg, 文件需小于5M）",
  "pages.kyc.tip.institutionPanel.base": "基本信息",
  "pages.kyc.label.estimate_daily_crypto_withdrawal_volume": "预计加密货币每月提现额度",
  "pages.kyc.label.estimate_daily_crypto_withdrawal_frequence": "预计加密货币每月提现频率",
  "pages.kyc.label.estimate_daily_crypto_deposit_volume": "预计加密货币每月充值额度",
  "pages.kyc.label.estimate_daily_crypto_deposit_frequence": "预计加密货币每月充值频率",
  "pages.kyc.label.estimate_daily_fiat_withdrawal_volume": "预计法币每月提现额度",
  "pages.kyc.label.estimate_daily_fiat_withdrawal_frequence": "预计法币每月提现频率",
  "pages.kyc.label.estimate_daily_fiat_deposit_volume": "预计法币每月充值额度",
  "pages.kyc.label.estimate_daily_fiat_deposit_frequence": "预计法币每月充值频率",
  "pages.RechargePage.WAITING_FOR_REFUND": "待退款",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.6": "良好声誉来源的新闻和其他类似证据",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.5": "审计账目及财务报表",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.4": "遗嘱认证文件",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.3": "银行或经纪账户对账单",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.2": "公开登记的所有权证明",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description.1": "股权证书",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.5": "所有向账户付款的交易的证明",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.4": "贷款文件",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.3": "工资/奖金证明",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.2": "银行对账单",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description.1": "与持股相关的股息支付证明",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.description.DRIVER_LICENSE": "驾照（正反面）",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.description.PASSPORT": "护照（两页）",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.description.ID_CARD": "身份证件（正反面）",
  "pages.kyc.tip.individualEnhancedPanel.additional_information": "其他",
  "pages.kyc.tip.individualEnhancedPanel.beneficial_owner_verification_documents": "受益人认证文档",
  "IPAddress": "IP地址",
  "CountryOrLocation": "国家/地区",
  "Source": "来源",
  "Activity": "活动",
  "account.certification.first": "请先完成身份认证",
  "pages.kyc.label.error_unit": "项错误。",
  "account.more.certification": "完善认证",
  "pages.kyc.form.error_uploading_files": "正在上传...",
  "pages.Account.idl.normal_certification.header": "基础认证",
  "pages.kycIndividual.driverLicenseNumber": "身份证件编号",
  "pages.kycIndividual.driverLicense": "驾照",
  "pages.kycIndividual.idCard": "身份证件",
  "pages.kycIndividual.credentialsType": "身份证件类型",
  "account.modal.ga.first": "请先开启双因子验证。",
  "account.email.label.title": "用于登录，提现，安全设置和API管理",
  "account.LoginPassword.label.title": "用于登录",
  "account.ga.label.title": "用于登录，提现，安全设置和API管理",
  "account.FundPassword.label.title": "用于交易与提现",
  "account.validateTime.each": "交易每次验证",
  "account.validateTime.everyday": "交易每天验证",
  "account.validateTime.twoHours": "交易每两小时验证",
  "account.validateTime.placeholder": "请选择验证时间间隔",
  "url.footer.menuSupportCenter": "https://support.martix.co/hc/zh-tw",
  "url.footer.menuPLegalPrivacy": "https://support.matrix.co/hc/zh-tw/articles/360036544773-法律条款",
  "url.footer.menuFeeSchedule": "https://support.martix.co/hc/zh-tw/articles/360025624973-费率说明",
  "url.footer.menuContactUS": "https://support.matrix.co/hc/zh-tw/articles/360036041894-联系方式",
  "url.footer.menuOurCompany": "https://support.martix.co/hc/zh-tw/articles/360036544473-公司介绍",
  "url.footer.menuReferal": "https://support.martix.co/hc/zh-tw/articles/360036039794-邀请好友返佣",
  "url.footer.menuAnnouncement": "https://support.martix.co/hc/zh-tw/sections/360004684874-最新公告",
  "iText.hash": "转账哈希",
  "iText.view": "查看",
  "page.deposit.refundAddress": "退款地址",
  "page.deposit.comments": "失败原因",
  "page.deposit.addressTip": "请填写退款地址",
  "pages.kyc.tip.individualPanel.beneficial": "受益人",
  "pages.kyc.tip.individualPanel.estimate": "资产及投资信息 ",
  "pages.kyc.tip.individualPanel.base": "个人信息",
  "pages.kycIndividual.credentialsDocumentUploading": "正在上传",
  "pages.kycIndividual.credentialsBackDocumentButtonTitle": "背面",
  "pages.kycIndividual.credentialsFrontDocumentButtonTitle": "正面",
  "pages.kycIndividual.credentialsDocumentUploaded": "已上传",
  "link.edit": "编辑",
  "page.withdraw.actualAmount": "实际到账",
  "page.withdraw.limitTip2": "您当前的KYC认证登记可享受以下的提现额度，完成高级认证后可提升额度。",
  "page.withdraw.limitTip1": "您当前的KYC认证已达到最高等级，可享受（个人/机构/对手方）最高提现额度。",
  "page.withdraw.limitTitle": "提现额度规则",
  "pages.assets.authTip": "请先完成以下验证再进行充值、提现。",
  "page.deposit.goVerify": "去验证",
  "page.deposit.limitTip2": "您当前的KYC认证登记可享受以下的充值额度，完成高级认证后可提升额度。",
  "page.deposit.limitTip1": "您当前的KYC认证已达到最高等级，可享受（个人/机构/对手方）最高充值额度。",
  "page.deposit.limitHelp": "受阿布扎比金融监管影响，所有注册用户的充值、提现额度需要符合监管政策所规定的限制，超出限制部分的申请将被拒绝。",
  "page.deposit.limitTitle": "充值额度规则",
  "iText.currency": "币种",
  "page.deposit.tip4": "否则充值将不会上账并被退款处理。",
  "page.deposit.tip3": "最小充值金额为",
  "iText.qrCode": "二维码",
  "iText.copy": "复制",
  "pages.TradeHistoryPage.empty": "您尚无历史交易",
  "pages.OpenOrderPage.dateRange": "日期",
  "pages.OpenOrderPage.disFee": "手续费",
  "page.loginGa.ga": "请输入6位数字的谷歌验证码",
  "page.loginGa.title": "安全认证",
  "label.repeatPassword": "确认密码",
  "pages.api.errorMsg.invalidIPCount": "最多不超过{maxIPCount}个",
  "pages.api.unauthBeforeSettingTip": "为了您的账户安全，设置API前请先完成{KYCLink}并绑定{GALink}",
  "page.GA.qr.empty": "暂无密钥数据",
  "page.FundPassword.submit.reset": "确定",
  "page.FundPassword.submit.set": "设置资金密码",
  "pages.account.setFundPasswordSuccess": "资金密码设置成功。",
  "page.withdraw.setAssetPwd": "去设置",
  "page.withdraw.assetPwdTip": "您还没有设置资金密码",
  "page.deposit.needKy": "去完成KYC",
  "table.title.blockHash": "块",
  "page.FundPassword.messageConfirmFundPassword": "请再一次输入资金密码",
  "page.FundPassword.confirmFundPassword": "确认资金密码",
  "page.FundPassword.messageFundPassword": "请输入资金密码",
  "page.FundPassword.fundPassword": "资金密码",
  "page.FundPassword.messageGACode": "请输入GA验证码",
  "page.FundPassword.gaCode": "谷歌验证码",
  "page.FundPassword.update": "修改资金密码",
  "page.FundPassword.set": "设置资金密码",
  "page.FundPassword.account": "个人中心",
  "page.FundPassword.same": "请保证两次资金密码输入一致",
  "pages.WithdrawPage.largerthan": "不能低于",
  "pages.account.setFundPasswordFail": "双因子验证码错误。",
  "pages.account.resetFundPasswordSuccess": "修改资金密码成功。",
  "pages.account.resetFundPasswordFail": "谷歌验证码错误。",
  "pages.404.back_home": "返回首页",
  "pages.404.tip.message": "抱歉，您访问的页面不存在。",
  "page.deposit.copy": "复制",
  "pages.Account.retrivecode": "更改密码，修改安全设置",
  "page.Withdraw.Set.sendCode": "发送验证码",
  "pages.Account.openGA": "开启GA",
  "pages.Account.closeGA": "关闭GA",
  "pages.account.toggleGAFailure": "切换ga开关失败",
  "pages.account.toggleGASuccess": "切换ga开关成功",
  "pages.Account.assets_password_notice": "请先设置ga",
  "page.withdraw.authTitle": "安全校验",
  "label.assetsPassword": "资金密码",
  "pages.kyc.kyc_review_reject_message": "您的账户存在异常状态，请联系客服进行处理。",
  "pages.kyc.kyc_review_reject": "验证失败",
  "label.emailVerifyCode": "邮箱验证码",
  "page.deposit.gaEnableLink": "激活谷歌验证码",
  "pages.kyc.form.customizedActivityRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.fundsAcquireWayRequired": "必填，只能输入{max}个字符",
  "page.withdraw.beyondAvailable": "可用资产不足",
  "pages.kyc.form.customizeActivityRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.accountPurposeDescribeRequired": "必填，只能输入{max}个字符",
  "pages.kyc.codes.JUMIO_INDENTITY_TIMES_OUT": "jumio认证尝试次数超限，请24小时后再试。",
  "pages.kyc.form.accountPurposeDescibeRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.bankAccountInfoRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.industryServiceRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.websiteAddressRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.customerReachWayRequired": "必填，只能输入{max}个字符",
  "page.withdraw.beyond": "超出了",
  "page.withdraw.monthlyLimit": "月提现额度",
  "pages.kyc.form.placeOfRegistrationRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.principalBusinessAddressRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.registeredAddressRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.tradingNameRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.companyNameRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.beneficialOwnerNameRequired": "必填，只能输入{max}个字符",
  "pages.RechargePage.PROCESSING": "处理中",
  "pages.kyc.form.annualIncomeRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.sourceOfFundsRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.sourceOfWealthRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.totalNetWorthRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.form.beneficialOwnerPermanentAddressRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.form.beneficialOwnerMobileRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.permanentAddressRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.domicileRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.emailRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.mobileRequired": "必填，只能输入{max}个字符",
  "pages.kyc.form.required": "必填",
  "pages.kyc.form.placeOfBirthRequired": "必填，只能输入{max}个字符",
  "link.view": "查看",
  "page.withdraw.available": "余额",
  "page.withdraw.minimum": "最小提现数量",
  "button.addressBook": "地址簿",
  "input.error.select": "请选择",
  "label.address": "地址",
  "label.fee": "手续费",
  "label.amount": "数量",
  "table.title.date": "日期",
  "table.title.status": "状态",
  "pages.kyc.form.error_file_size": "大小不能超过 {total_mbytes}M。",
  "pages.kyc.card.header": "账户认证",
  "input.placeholder.select": "请选择",
  "Please complete": "只支持 zip, rar, doc, docx, xls, xlsx, ppt, pptx, pdf, png, jpg, jpeg ",
  "page.withdraw.tip2": "提现申请后可在历史记录跟踪状态。",
  "page.withdraw.tip1": "不要提现到ICO地址。",
  "page.withdraw.noAuthTip": "做完 {kyc} 和 {ga}后才可提现。",
  "page.withdraw.authTip": "仅支持外部地址",
  "page.withdraw.dailyLimit": "日提现额度",
  "page.withdraw.historyTitle": "提现记录",
  "table.title.fee": "手续费",
  "page.withdraw.title": "提现",
  "page.withdraw.addressEmpty": "暂无地址",
  "page.withdrawAddress.add": "添加地址",
  "page.withdrawAddress.title": "地址簿",
  "table.title.address": "地址",
  "table.title.note": "描述",
  "button.confirm": "确认",
  "button.cancel": "取消",
  "table.title.action": "操作",
  "message.success": "成功",
  "link.delete": "删除",
  "pages.kyc.VolumeOptions.not_greater_than_5000": "≤5000 美元",
  "pages.kyc.VolumeOptions.5001_and_10000": "5,001-10,000 美元",
  "pages.kyc.VolumeOptions.100000_and_1000000": "100,000-500,000 美元",
  "pages.kyc.VolumeOptions.500000_and_1000000": "500,000-1,000,000 美元",
  "pages.kyc.VolumeOptions.not_less_than_1000000": "≥1,000,000 美元",
  "button.submit": "提交",
  "button.getCode": "发送验证码",
  "button.addNow": "添加",
  "label.withdrawNote": "描述",
  "label.withdrawAddress": "提现地址",
  "pages.kyc.InvestedInCryptoOptions.no_experience": "没有经验",
  "pages.kyc.InvestedInCryptoOptions.0_and_1_years": "0-1 年",
  "label.token": "TOKEN",
  "pages.kyc.InvestedInCryptoOptions.1_and_3_years": "1-3 年",
  "page.deposit.needIncrease": "增加限额",
  "page.deposit.needKyc": "继续KYC",
  "page.deposit.monthlyLimit": "每月限额",
  "pages.kyc.InvestedInCryptoOptions.not_less_than_3_years": "≥3 年",
  "page.deposit.orderLimit": "单笔限额",
  "page.deposit.tableEmpty": "暂无历史记录",
  "page.deposit.historyTitle": "充值历史",
  "table.title.confirmations": "确认数",
  "table.title.amount": "数量",
  "table.title.orderId": "订单ID",
  "page.deposit.tip2": "为{currency}充值的地址需要确认",
  "page.deposit.tip1": "只接受{currency} ",
  "page.deposit.noAuthTip": "做完{kyc} 和 {ga}后, 充值功能才会开放.",
  "page.deposit.copied": "充值地址复制成功。",
  "page.deposit.address": "地址",
  "page.deposit.gaBindLink": "绑定谷歌验证码",
  "page.deposit.kycLink": "KYC",
  "pages.Account.kycType.COUNTERPARTY": "对手方",
  "pages.Account.kycType.INSTITUTION": "机构",
  "pages.Account.kycType.RETAIL": "个人",
  "pages.Account.idl.enhanced_kyc.linkText": "高级认证",
  "pages.kycInstitutionEnhanced.AccountActivity.header2": "请描述帐户用途及预期的帐户活动/用途。",
  "pages.kyc.proofAddressTypes.local_authority_tax_bill": "地方政府税单",
  "pages.kyc.proofAddressTypes.utility_bill": "公共设施费用账单（如电费/水费/网络费等）",
  "pages.kyc.proofAddressTypes.tenancy_agreement": "租赁协议",
  "pages.kyc.proofAddressTypes.electoral_register_search": "通过选民资料网上查阅系统核实住址信息",
  "pages.kyc.proofAddressTypes.record_of_home_visit": "家访记录",
  "pages.kyc.form.error.select_upload_file": "请上传文件！",
  "pages.kyc.form.error.select_one": "请选择",
  "pages.kycIndividual.form.error.activities": "请至少选择一个",
  "pages.kycIndividual.form.error.estimated_daily_transactions": "请选择交易目的",
  "components.CountrySelect.placeholder": "请选择国家",
  "pages.Account.assets_password_set": "设置",
  "pages.Account.assets_password_reset": "更改",
  "pages.Account.assets_password": "资金密码",
  "pages.Account.assets_password_description": "要完成下订单，您可以根据自己的喜好选择安全级别。",
  "pages.Account.idl.levelName": "身份认证等级",
  "page.kycInstitution.please_upload_file": "请上传所需文件。",
  "page.kycInstitution.please_attach_file": "请上传文件",
  "pages.Account.idl.enhanced_kyc.description4": "{withdrawDaily} 美金/每日, {withdrawMonth} 美金/每月",
  "pages.Account.idl.enhanced_kyc.description3": "{depositOrder} 美金/每单, {depositMonth} 美金/每月",
  "pages.Account.idl.enhanced_kyc.description2": "已达到最高认证等级",
  "pages.Account.idl.enhanced_kyc.description1": "额度不够可继续提升等级",
  "pages.Account.idl.limited_account.description": "您的信息存在异常状态，无法完成认证 ，如有疑问，请联系客服。",
  "pages.Account.idl.limited_account.header": "限制账户",
  "pages.Account.idl.no_certification.description2": "提升认证等级享受更多额度。",
  "pages.Account.idl.no_certification.description1": "当前未进行身份认证， 将限制充值和提现功能使用",
  "pages.Account.idl.no_certification.header": "未认证",
  "pages.Account.limited_account": "限制账户",
  "pages.Account.verification": "开始认证",
  "pages.kyc.kyc_verify_again_btn": "再次验证",
  "pages.kyc.kyc_review_failure": "身份认证失败",
  "pages.kyc.status.CounterpartyPendingReviewTip": "预计将在3个工作日内审核完成。",
  "pages.kyc.status.CounterpartyPendingReviewTitle": "提交成功",
  "pages.kycCounterparty.title": "对手方",
  "pages.kyc.card.counterparty.description": "如果您是市场对手方，请点击“对手方”进行验证",
  "pages.kyc.card.counterparty.title": "对手方",
  "pages.kyc.card.institution.description": "如果您是实体用户，请点击“实体”进行验证",
  "pages.kyc.card.institution.title": "实体",
  "pages.kyc.card.individual.description": "如果您是个人用户，请点击“个人”进行验证",
  "pages.kyc.card.individual.title": "个人",
  "pages.kycIndividualEnhanced.intendedVolumesFrequency.fiat": "法币",
  "pages.kycIndividualEnhanced.intendedVolumesFrequency.crypto": "数字货币",
  "pages.kycIndividualEnhanced.intendedVolumesFrequencyWithdrawalVolAndFreq": "提款量（美元）和频率",
  "pages.kycIndividualEnhanced.intendedVolumesFrequencyDepositVolAndFreq": "存款量（美元）和频率",
  "pages.kycIndividualEnhanced.intendedVolumesFrequencyHeader": "预期数量和频率（每月）",
  "pages.kycIndividualEnhanced.accountActivityIntend.no": "否",
  "pages.kycIndividualEnhanced.accountActivityIntend.yes": "是",
  "pages.kycIndividualEnhanced.accountActivityDetailTitle": "请描述帐户目的和预期的帐户活动/用途。",
  "pages.kycIndividualEnhanced.accountActivityIntendTitle": "您是代表他人进行操作吗？",
  "pages.kycIndividualEnhanced.accountActivityHeader": "账户活动",
  "pages.kycIndividualEnhanced.additionalInformationHeader": "额外信息",
  "pages.kycIndividualEnhanced.verificationDocument.uploadTip": "仅支持{fileFormatTotal}格式，总大小不超过{fileMByteTotal}M ， 数量不超过{fileNumberTotal}个。",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.description": "股权证明，公开可用的所有权登记册，银行或经纪账户报表，遗嘱文件，经审计的账目和财务报表，来自信誉良好来源的新闻和其他类似证据。",
  "pages.kycIndividualEnhanced.verificationDocumentItems.2.title": "验证受益所有者的财富来源",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.description": "所有和账户相关的股息支付证明，银行对账单，工资/奖金证书，贷款文件以及交易证明。",
  "pages.kycIndividualEnhanced.verificationDocumentItems.1.title": "验证受益所有者的资金来源",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.description": "可接受的凭证类型，身份证（双面），护照（双页），驾驶执照（双面）",
  "pages.kycIndividualEnhanced.verificationDocumentItems.0.title": "所有受益所有人的身份识别",
  "pages.kycIndividualEnhanced.verificationDocumentHeader": "认证材料",
  "pages.kycIndividualEnhanced.title": "个人高级身份认证",
  "pages.kycIndividualEnhanced.pass": "认证通过",
  "pages.kycIndividualEnhanced.ongoing": "认证中",
  "pages.kycIndividualEnhanced.yourmsg": "基础信息",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequency.fiat": "法币",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequency.crypto": "数字货币",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequencyWithdrawalVolAndFreq": "提款量（美元）和频率",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequencyDepositVolAndFreq": "存款量（美元）和频率",
  "pages.kycInstitutionEnhanced.intendedVolumesFrequencyHeader": "预期数量和频率（每月）",
  "pages.kycInstitutionEnhanced.AccountActivity.header1": "您是代表他人进行操作吗？",
  "pages.kycInstitutionEnhanced.AccountActivity.title": "账户活动",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.title": "公司信息和业务活动",
  "pages.kycInstitutionEnhanced.option.no": "否",
  "pages.kycInstitutionEnhanced.option.yes": "是",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header7": "银行账户信息（如果您还没有银行账户，请说明您计划开设账户的司法管辖区）",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header6": "请问您有反洗钱政策表吗？如果有的话，请提供一下复印内容。",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header5": "请问您的业务是否受到反洗钱合规审核？您公司有金融执照吗？",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header4": "请说明您公司所在行业，并形容您公司提供的服务。",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header3": "请问您是如何获得这些计划存入Matrix的法币/数字货币资金？",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header2": "您的公司官网地址是？",
  "pages.kycInstitutionEnhanced.CompanyInfoAndBusinessActivity.header1": "通常您的用户如何联系您？",
  "pages.kycInstitutionEnhanced.title": "机构高级KYC",
  "pages.kycIndividual.estimated_daily_transactions": "每日交易预估",
  "pages.kycIndividual.withdrawal_frequency_and_volume": "提款频率和数量",
  "pages.kycIndividual.deposit_frequency_and_volume": "存款频率和数量",
  "pages.kycIndividual.intended_volumes_and_frequency": "预期交易数量和频率（每日）",
  "pages.kycIndividual.financial_information": "资产信息",
  "pages.kycIndividual.how_long_invested_crypto": "您投资数字货币多久了？",
  "pages.kycIndividual.info_about_beneficial_owner": "有关实际受益所有者的信息",
  "pages.kycIndividual.same_as_personal_information": "和基础信息一致",
  "pages.kycIndividual.estimated_annual_income": "年收入估值（美元）",
  "pages.kycIndividual.source_of_funds_deposited_into_account": "存入资金的来源",
  "pages.kycIndividual.source_of_wealth": "资产来源",
  "pages.kycIndividual.estimated_total_net_worth": "总净值估值（美元）",
  "pages.kyc.institutionType.INSTITUTION_ACCOUNT_VERIFICATION_Enhanced_CDD": "机构账户验证增强型客户尽职调查",
  "pages.kyc.institutionType.TRUST_OR_OTHER_SIMILAR_LEGAL_ARRANGEMENT": "信托或其他类似的机构",
  "pages.kyc.institutionType.FOUNDATION_OR_OTHER_SIMILAR_LEGAL_ARRANGEMENT": "基金会或其他类似的机构",
  "pages.kyc.institutionType.CORPORATION": "机构",
  "pages.kyc.institutionType.REGULATED_FINANCIAL_INSTITUTION": "受监管的金融机构",
  "pages.kyc.activity.CUSTOM_ACTIVITY": "任何其他商业活动（请说明）",
  "pages.kyc.activity.MANAGING_FUNDS_OF_OTHER_INDIVIDUALS": "管理他人的资金",
  "pages.kyc.activity.DEPOSITING_OR_WITHDRAWING_FUNDS_TO_BANK": "存入或取出资金到商业银行账户",
  "pages.kyc.activity.ACCEPTING_OR_CONVERTING_PAYMENTS_FROM_CUSTOMERS": "接受或转换客户对所提供服务或销售商品的付款",
  "pages.kyc.label.fiat": "法币",
  "pages.kyc.label.crypto": "数字货币",
  "pages.kyc.form.error_only_one_file": "一次请只上传一个文件",
  "pages.kyc.form.error_upload_files_firstly": "请先上传文件",
  "pages.kyc.form.error_files_total_number": "上传文件数量不得超过{total_number}.",
  "pages.kyc.form.error_files_total_size": "上传文件大小不得超过{total_mbytes}M。",
  "pages.kyc.form.error_select_and_upload_files": "请上传文件",
  "pages.kyc.form.invalid_email": "邮箱地址无效",
  "pages.kyc.form.nameRequired": "必填，只能输入{max}个字符",
  "pages.kyc.tip.start_upload": "开始上传",
  "pages.kyc.tip.uploading": "上传中",
  "pages.kyc.tip.select_file": "选择文件",
  "pages.kyc.tip.upload_failure": "上传失败。",
  "pages.kyc.tip.upload_successfully": "上传成功。",
  "pages.kyc.tip.form_invalid_message": "表格包含错误。",
  "pages.kyc.tip.submit_failure": "提交失败",
  "pages.kyc.tip.submit_successfully": "提交成功。",
  "pages.kyc.submitBtn": "提交",
  "pages.kyc.tip.sending": "加载中...",
  "pages.kyc.tip.loading": "加载中",
  "pages.OrderBook.form.marketPriceLabel": "市场价",
  "pages.api.enterIPs.placehold": "如果输入多个ip，使用逗号分隔。",
  "pages.OpenOrderPage.status.SEQUENCED": "排队中",
  "component.priceOrder.dealTime": "成交时间",
  "page.message.empty": "暂无消息",
  "xiao.test": "测试",
  "page.Account.retrivecode": "更改密码，修改安全设置",
  "components.Footer.menuReferal": "邀请好友",
  "components.Footer.menuAPIDocumentation": "API 文档",
  "components.Footer.menuServices": "服务",
  "components.Footer.menuOurCompany": "公司信息",
  "components.Footer.menuSupportCenter": "帮助中心",
  "components.Footer.menuPLegalPrivacy": "法律条款",
  "components.Footer.menuFeeSchedule": "费率标准",
  "components.Footer.menuCustomerSupport": "用户支持",
  "pages.WithdrawPage.addressNames": "提款地址",
  "pages.Account.nameLabel": "姓名",
  "pages.RechargePage.SUBMITTED": "已提交",
  "pages.RechargePage.WAITING_FOR_WALLET": "处理中",
  "pages.RechargePage.APPROVED": "已通过",
  "pages.RechargePage.DONE": "完成",
  "pages.RechargePage.FAILED": "失败",
  "page.GA.Set.bindauth": "开启谷歌验证",
  "pages.WithdrawPage.todelete": "确定要删除吗？",
  "pages.account.bindGAFailure": "谷歌验证码错误。",
  "pages.account.bindGASuccess": "谷歌验证绑定成功。",
  "pages.account.bindGAEmalCodeFailure": "发送频繁，请稍后再试。",
  "pages.account.bindGAEmalCodeSuccess": "邮箱验证码发送成功。",
  "pages.WithdrawPage.downloadexcel": "下载表格",
  "page.Account.enhancedKyc": "高级认证",
  "page.Account.higherLimit": "更高限额",
  "page.Account.basicKyc": "基础认证",
  "pages.WithdrawPage.selectaddress": "请选择提现地址",
  "pages.RechargePage.PENDING": "进行中",
  "pages.RechargePage.DEPOSITED": "完成",
  "pages.RechargePage.NEED_CANCEL": "请等待",
  "pages.RechargePage.CANCELLED": "已取消",
  "pages.RechargePage.WAITING_FOR_APPROVAL": "进行中",
  "pages.RechargePage.DENIED": "驳回",
  "button.refresh": "刷新",
  "message.warning.tokenExpire": "您的登录时效即将过期，请单击刷新按钮更新。",
  "message.title.tip": "提醒",
  "pages.WithdrawPage.withdralInputReminder": "请输入地址",
  "page.MonthlyStatements.FundsReportTitle": "资产报告",
  "page.MonthlyStatements.AccountLabel": "账户",
  "page.MonthlyStatements.BaseCurrencyLabel": "基础货币",
  "page.MonthlyStatements.AccountTypeLabel": "账户类型",
  "page.MonthlyStatements.nameLabel": "姓名",
  "page.GA.Change.sendCode": "发送验证码",
  "page.GA.Change.codeNotice": "请输入验证码",
  "page.GA.Change.vcodeNotice": "请输入邮箱验证码",
  "page.GA.Change.ncodeNotice": "请输入老的GA验证码",
  "page.GA.Set.sendCode": "发送验证码",
  "page.GA.Set.vcodeNotice": "请输入验证码",
  "page.GA.Set.codeNotice": "请输入邮箱邮箱验证码",
  "pages.RechargePage.downloadExcel": "下载表格",
  "pages.RechargePage.copy": "复制",
  "pages.RechargePage.address": "打款地址",
  "pages.OpenOrderPage.side.SELL": "卖",
  "pages.OrderHistoryPage.empty": "您尚无历史委托",
  "button.ok": "确定",
  "asdas": "dasdasd",
  "pages.WithdrawPage.value": "价值",
  "pages.WithdrawPage.trade": "去交易",
  "pages.WithdrawPage.tipGA": "进行谷歌验证之后才能选择添加提币地址。",
  "pages.WithdrawPage.tip2": "您可以在充值提现 历史记录页面跟踪状态。",
  "pages.WithdrawPage.tip1": "提现请求申请成功后，请去邮箱点击链接确认本次提现请求。",
  "pages.WithdrawPage.tip": "提现",
  "pages.WithdrawPage.time": "时间",
  "pages.WithdrawPage.submit": "提交",
  "pages.WithdrawPage.status": "状态",
  "pages.WithdrawPage.pointError": "大于",
  "pages.WithdrawPage.number": "数量",
  "pages.WithdrawPage.noMsg": "无提现记录",
  "pages.WithdrawPage.moreError": "超出最大提币范围",
  "pages.WithdrawPage.more": "更多",
  "pages.WithdrawPage.limit": "提现额度",
  "pages.WithdrawPage.lessError": "超出最小提币范围",
  "pages.WithdrawPage.inputga": "请输入谷歌验证码",
  "pages.WithdrawPage.important4": "最大提现数量为",
  "pages.WithdrawPage.important3": "请勿直接提现至众筹或ICO地址",
  "pages.WithdrawPage.important2": "卖",
  "pages.WithdrawPage.important1": "最小提现数量为",
  "pages.WithdrawPage.important": "注意",
  "pages.WithdrawPage.history": "历史记录",
  "pages.WithdrawPage.header": "提现",
  "pages.WithdrawPage.get": "实际到账",
  "pages.WithdrawPage.gaCode": "谷歌验证码",
  "pages.WithdrawPage.fee": "手续费",
  "pages.WithdrawPage.emptyError": "该字段不能为空",
  "pages.WithdrawPage.delete": "删除",
  "pages.WithdrawPage.currency": "币种",
  "pages.WithdrawPage.cannotempty": "此处不能为空",
  "pages.WithdrawPage.amount": "提币数量",
  "pages.WithdrawPage.addressName": "提币地址",
  "pages.WithdrawPage.address": "提现地址",
  "pages.WithdrawPage.addmsg": "添加地址",
  "pages.WithdrawPage.add": "添加",
  "pages.WithdrawPage. trade": "去交易",
  "pages.WithdrawPage. pointError": "最小位数不能多于",
  "pages.WithdrawPage. important1": "最小提现",
  "pages.TradeHistoryPage.tradehistory": "成交记录",
  "pages.TradeHistoryPage.searchBtn": "搜索",
  "pages.TradeHistoryPage.downloadExcelBtn": "下载",
  "pages.TradeHistoryPage.colKeyType": "类型",
  "pages.TradeHistoryPage.colKeyTotal": "总交易额",
  "pages.TradeHistoryPage.colKeySymbol": "交易对",
  "pages.TradeHistoryPage.colKeySide": "买卖方向",
  "pages.TradeHistoryPage.colKeyReceive": "最终获得",
  "pages.TradeHistoryPage.colKeyPrice": "价格",
  "pages.TradeHistoryPage.colKeyFee": "手续费",
  "pages.TradeHistoryPage.colKeyDate": "日期",
  "pages.TradeHistoryPage.colKeyAverage": "平均成交价",
  "pages.TradeHistoryPage.colKeyAmount": "数量",
  "pages.TradeHistoryPage.cancelConfirmTitle": "确定",
  "pages.TradeHistoryPage.cancelConfirmContent": "确定要取消?",
  "pages.RechargePage.value": "价值",
  "pages.RechargePage.trade": "去交易",
  "pages.RechargePage.tip4": "历史记录",
  "pages.RechargePage.tip3": "充值完成后，您可以在历史记录中查看进度。",
  "pages.RechargePage.tip2": "充值就能到账。",
  "pages.RechargePage.tip1": "多个网络确认后",
  "pages.RechargePage.tip": "请注意",
  "pages.RechargePage.time": "时间",
  "pages.RechargePage.status": "状态",
  "pages.RechargePage.qrcode": "展示二维码",
  "pages.RechargePage.number": "充值数量",
  "pages.RechargePage.noMsg": "无充值记录",
  "pages.RechargePage.more": "更多",
  "pages.RechargePage.history": "历史记录",
  "pages.RechargePage.header": "充值",
  "pages.RechargePage.filled": "确认",
  "pages.RechargePage.currency": "币种",
  "pages.RechargePage.cpoy": "复制地址",
  "pages.RechargePage.attentionText2": "地址充值其他币种的资产，任何充入该地址的非该币种资产将不可找回。",
  "pages.RechargePage.attentionText1": "禁止向",
  "pages.RechargePage.attention": "注意",
  "pages.RechargePage.addressName": "打款地址",
  "pages.Orders.type.market": "市价单",
  "pages.Orders.type.limit": "限价单",
  "pages.Orders.symbol.all": "全部",
  "pages.Orders.status.done": "完成",
  "pages.Orders.status.canceled": "已取消",
  "pages.Orders.status.cancel": "已取消",
  "pages.Orders.status.all": "所有",
  "pages.Orders.side.sell": "卖",
  "pages.Orders.side.buy": "买",
  "pages.Orders.side.all": "全部",
  "pages.Orders.action.cancelTitle": "取消",
  "pages.OrderHistoryPage.searchBtn": "搜索",
  "pages.OrderHistoryPage.orderhistory": "订单记录",
  "pages.OrderHistoryPage.downloadExcelBtn": "下载",
  "pages.OrderHistoryPage.colKeyType": "类型",
  "pages.OrderHistoryPage.colKeyTrigger": "触发条件",
  "pages.OrderHistoryPage.colKeySymbol": "交易对",
  "pages.OrderHistoryPage.colKeyStatus": "状态",
  "pages.OrderHistoryPage.colKeySide": "买卖方向",
  "pages.OrderHistoryPage.colKeyPrice": "价格",
  "pages.OrderHistoryPage.colKeyFinished": "完成",
  "pages.OrderHistoryPage.colKeyFilled": "已完成",
  "pages.OrderHistoryPage.colKeyDate": "日期",
  "pages.OrderHistoryPage.colKeyAverage": "平均价",
  "pages.OrderHistoryPage.colKeyAmount": "数量",
  "pages.OrderHistoryPage.cancelConfirmTitle": "确认",
  "pages.OrderHistoryPage.cancelConfirmContent": "确认取消？",
  "pages.OrderBox.openOrder": "当前委托",
  "pages.OrderBox.more": "更多",
  "pages.OrderBox.historyOrder": "历史委托",
  "pages.OrderBook.tabStopLimitTip": "买卖价格达到指定价格，限价止损单才生效",
  "pages.OrderBook.tabStopLimit": "限价止损单",
  "pages.OrderBook.tabMarket": "市价单",
  "pages.OrderBook.tabLimit": "限价单",
  "pages.OrderBook.successMessage": "成功",
  "pages.OrderBook.sell": "售出",
  "pages.OrderBook.form.total": "交易金额",
  "pages.OrderBook.form.stop": "触发价",
  "pages.OrderBook.form.price": "价格",
  "pages.OrderBook.form.limit": "限价",
  "pages.OrderBook.form.amount": "数量",
  "pages.OrderBook.errors.StopNotMoreThanLimit": " {Limit}必须大于等于 {Stop}",
  "pages.OrderBook.errors.StopNotLessThanLimit": "{Limit} 必须小于等于 {Stop}",
  "pages.OrderBook.errors.SmallOrEqualTo": "必须小于或等于{max}",
  "pages.OrderBook.errors.Required": "不能为空",
  "pages.OrderBook.errors.normalNumber": "必须是数字或者大于0",
  "pages.OrderBook.errors.GreaterOrEqualTo": "必须大于等于 {min}",
  "pages.OrderBook.errors.DecimalPoint": "小数点精度错误(最大: {scale})",
  "pages.OrderBook.buy": "买入",
  "pages.OpenOrderPage.type.MARKET": "市价单",
  "pages.OpenOrderPage.type.LIMIT": "限价单",
  "pages.OpenOrderPage.type": "类型",
  "pages.OpenOrderPage.triggerOn": "触发条件",
  "pages.OpenOrderPage.tradehistory": "历史成交",
  "pages.OpenOrderPage.total": "总交易金额",
  "pages.OpenOrderPage.symbol": "交易对",
  "pages.OpenOrderPage.status.MARKET": "市价",
  "pages.OpenOrderPage.status.LIMIT": "限价",
  "pages.OpenOrderPage.status.DONE": "完成",
  "pages.OpenOrderPage.status.CANCELED": "取消",
  "pages.OpenOrderPage.status.BUY": "买",
  "pages.OpenOrderPage.status": "状态",
  "pages.OpenOrderPage.side.BUY": "买",
  "pages.OpenOrderPage.side": "方向",
  "pages.OpenOrderPage.searchBtn": "搜索",
  "pages.OpenOrderPage.receive": "获得",
  "pages.OpenOrderPage.price2": "价格(市价)",
  "pages.OpenOrderPage.price1": "价格(限价)",
  "pages.OpenOrderPage.price": "价格",
  "pages.OpenOrderPage.pari": "市场",
  "pages.OpenOrderPage.orderhistory": "历史委托",
  "pages.OpenOrderPage.operation": "操作",
  "pages.OpenOrderPage.openorder": "当前委托",
  "pages.OpenOrderPage.loadMore": "加载更多",
  "pages.OpenOrderPage.finished": "已成交",
  "pages.OpenOrderPage.filled": "成交率",
  "pages.OpenOrderPage.fee": "手续费",
  "pages.OpenOrderPage.empty": "您尚无任何委托",
  "pages.OpenOrderPage.downloadExcelBtn": "下载",
  "pages.OpenOrderPage.date": "时间",
  "pages.OpenOrderPage.confirm.title": "确定",
  "pages.OpenOrderPage.confirm.okBtn": "好",
  "pages.OpenOrderPage.confirm.content": "确定取消？",
  "pages.OpenOrderPage.confirm.cancelBtn": "取消",
  "pages.OpenOrderPage.confirm": "确定取消？",
  "pages.OpenOrderPage.cancel": "取消",
  "pages.OpenOrderPage.averagePrice": "成交均价",
  "pages.OpenOrderPage.average": "平均价",
  "pages.OpenOrderPage.amount": "数量",
  "pages.OpenOrderPage.action": "操作",
  "pages.models.orders.orderCannotCancel": "订单已取消",
  "pages.kycInstitution.title": "法人",
  "pages.kycInstitution.requiredDocumentTitle": "机构账户认证",
  "pages.kycInstitution.requiredDocuments": "上传文件",
  "pages.kycInstitution.requiredDocumentDownloadTxt": "下载文件要求",
  "pages.kycInstitution.requiredDocumentDescription": "感谢您开立机构账户，为了您接下来的账户认证，请提供以下机构相关文件和信息。请注意：我们可能会向您索取更多补充信息。",
  "pages.kycInstitution.pendingReview": "等待认证",
  "pages.kycInstitution.form.tradingName": "营业名称",
  "pages.kycInstitution.form.registrationDate": "注册日期",
  "pages.kycInstitution.form.registeredAddress": "注册地址",
  "pages.kycInstitution.form.principalBusinessAddress": "营业地址",
  "pages.kycInstitution.form.placeOfRegistration": "注册地点",
  "pages.kycInstitution.form.institutionTitle": "您的机构类型是什么？",
  "pages.kycInstitution.form.generalInformationTitle": "普通信息",
  "pages.kycInstitution.form.companyName": "实体名称",
  "pages.kycInstitution.form.activityTitle": "您开通机构账户主要用途是什么？",
  "pages.kycInstitution.basicInfoSubmitBtn": "开始认证",
  "pages.kycInstitution.basicInfo": "基础信息",
  "pages.kycInstitution.approved": "认证通过",
  "pages.kycIndividual.yourmsg": "基础信息",
  "pages.kycIndividual.type5": "网上购物/支付",
  "pages.kycIndividual.type4": "买卖产品或服务",
  "pages.kycIndividual.type3": "转卖(经纪人/经销商) 相关活动",
  "pages.kycIndividual.type2": "投资",
  "pages.kycIndividual.type1": "套利",
  "pages.kycIndividual.title": "个人",
  "pages.kycIndividual.successTitle": "通过",
  "pages.kycIndividual.successTip": "您可以做充值、提现、交易以及其他操作。",
  "pages.kycIndividual.proofAddress": "住址证明",
  "pages.kycIndividual.postcode": "邮编",
  "pages.kycIndividual.phone": "电话号码",
  "pages.kycIndividual.permanentAddress": "永久住址",
  "pages.kycIndividual.pass": "认证通过",
  "pages.kycIndividual.other": "目的",
  "pages.kycIndividual.ongoingTitle": "验证中",
  "pages.kycIndividual.ongoingTip": "预计将在3个工作日内审核完成。",
  "pages.kycIndividual.ongoing": "认证中",
  "pages.kycIndividual.occupation": "职业",
  "pages.kycIndividual.lastName": "姓",
  "pages.kycIndividual.information": "基本信息",
  "pages.kycIndividual.idmsg": "个人身份认证",
  "pages.kycIndividual.header": "个人",
  "pages.kycIndividual.firstName": "名",
  "pages.kycIndividual.failureTitle": "失败",
  "pages.kycIndividual.failureTip": "您的申请未能通过，请重新认证",
  "pages.kycIndividual.emptyError": "不能为空",
  "pages.kycIndividual.emailError": "请输入正确格式的邮箱。",
  "pages.kycIndividual.email": "邮箱地址",
  "pages.kycIndividual.deal": "交易目的",
  "pages.kycIndividual.currentAddress": "邮寄地址",
  "pages.kycIndividual.country": "国籍",
  "pages.kycIndividual.contactWay": "联系信息",
  "pages.kycIndividual.button": "开始认证",
  "pages.kycIndividual.birthplace": "出生地",
  "pages.kycIndividual.birthday": "出生日期",
  "pages.kycIndividual.address": "地址信息",
  "pages.kycEnhanced.title": "账户验证",
  "pages.kycEnhanced.description": "感谢您注册Matrix账户，为了后续的账户验证，请下载下面的PDF,并提供所需信息，然后上传这些信息。请注意，我们可能向您索取更多支持性材料。",
  "pages.kyc.status.PendingReviewTitle": "你的帐户正在审核中",
  "pages.kyc.status.PendingReviewTip": "审核将在4个工作日内完成，谢谢您耐心等待。",
  "pages.kyc.status.ApprovedTitle": "审核通过",
  "pages.kyc.status.ApprovedTip": "您可以做充值、提现、交易以及其他操作。",
  "pages.HomePage.tryme.header": "试一试！",
  "pages.HomePage.tryme.atPrefix": "@",
  "pages.HomePage.start_project.message": "A highly scalable, offline-first foundation with the best DX and a focus on performance and best practices",
  "pages.HomePage.start_project.header": "迅速开启下一个项目",
  "pages.HomePage.features_sectionTop_item3.title": "快捷交易",
  "pages.HomePage.features_sectionTop_item3.description": "高性能核心撮合引擎，承接海量下单交易。支持法币存提，交易快捷。",
  "pages.HomePage.features_sectionTop_item2.title": "系统稳定",
  "pages.HomePage.features_sectionTop_item2.description": "我们的主系统已多次通过可靠性测试。多节点容灾，异地灾备，多活切换，确保交易系统稳定可靠。",
  "pages.HomePage.features_sectionTop_item1.title": "合规安全",
  "pages.HomePage.features_sectionTop_item1.description": "我们提供反洗钱/用户身份认证的合规监管审查及离线存储，以保证平台和用户信息安全。",
  "pages.HomePage.features_sectionBottom_text.title2": "简易好用",
  "pages.HomePage.features_sectionBottom_text.title1": "操作平台",
  "pages.HomePage.features_sectionBottom_text.content": "无论您的交易经验如何,Matrix将为您提供安全的交易工具。",
  "pages.HomePage.banner.subTitle": "Matrix交易所即将上线",
  "pages.api.unauthPlease": "请",
  "pages.api.unauthBindToGA": "双因子验证",
  "pages.api.unauthBeforeSetting": "设置API前",
  "pages.api.Title.copy": "复制",
  "pages.api.Title.copied": "已复制",
  "pages.api.title": "API 管理",
  "pages.api.submiting": "提交中...",
  "pages.api.options.canWithdrawal": "提现",
  "pages.api.options.canTrade": "交易",
  "pages.api.options.canRead": "可读信息",
  "pages.api.loading": "加载中...",
  "pages.api.ipOptions.only": "限制授信IP登录(推荐)",
  "pages.api.ipOptions.all": "不限制(安全性低)",
  "pages.api.form.submitBtn": "提交",
  "pages.api.form.secretKeyLabel": "密钥",
  "pages.api.form.optionLabel": "选择",
  "pages.api.form.ipAccessRestrictionLabel": "IP 接入限制",
  "pages.api.form.gaLabel": "双因子验证码",
  "pages.api.form.descriptionLabel": "API Key 标签",
  "pages.api.form.accessKeyLabel": "Access Key",
  "pages.api.errors.invalidGA": "谷歌验证码错误。",
  "pages.api.errorMsg.required": "必填！",
  "pages.api.errorMsg.invalidIPs": "无效IP(多个IP,请用逗号分割)",
  "pages.api.editKeyBtn": "编辑",
  "pages.api.editForm.editTitle": "修改key值",
  "pages.api.editForm.createTitle": "创建新key",
  "pages.api.deleteKeyBtn": "删除",
  "pages.api.deleteConfirm.title": "确定",
  "pages.api.deleteConfirm.succResult": "删除成功。",
  "pages.api.deleteConfirm.okBtn": "确定",
  "pages.api.deleteConfirm.content": "确定删除 '{apiKey}' ?",
  "pages.api.deleteConfirm.cancelBtn": "取消",
  "pages.api.createNewKeyBtn": "创建新key",
  "pages.api.createdResult.warnTip": "密钥仅显示1次，遗失后不可找回，请务必妥善保存。",
  "pages.api.createdResult.tipTitle": "提醒",
  "pages.api.createdResult.tip2": "如您忘记了secret key，请收回该密钥对并申请新的密钥对。",
  "pages.api.createdResult.tip1": "请不要泄露您的access key及secret key，以免造成资产损失。",
  "pages.api.createdResult.modalTitle": "创建成功",
  "pages.api.createdResult.enterBtn": "已经保存",
  "pages.api.apiDocTitle": "API 文档",
  "pages.api.apiDescriptionView": "查看",
  "pages.api.apiDescription": "创建私钥，可以接入Matrix的实时交易服务，在Matrix或第三方网站进行交易。",
  "page.MonthlyStatements.WithdrawalFee": "提现手续费",
  "page.MonthlyStatements.Withdrawal": "提现",
  "page.MonthlyStatements.USDMarket": "美元计价估值",
  "page.MonthlyStatements.TradingFee": "交易费",
  "page.MonthlyStatements.Symbol": "货币",
  "page.MonthlyStatements.Starting": "开始",
  "page.MonthlyStatements.selectMonth": "请首先选择月份",
  "page.MonthlyStatements.sectionTitle2": "2.时间以 UTC+0 时区格式展示。",
  "page.MonthlyStatements.sectionTitle1": "法律说明",
  "page.MonthlyStatements.sectionContent2": "3.本报告中开始、结束价格来源为Matrix以及第三方数据。",
  "page.MonthlyStatements.sectionContent1": "1.如果您的资产表或账户有任何问题，请及时联系我们的客服。",
  "page.MonthlyStatements.Quantity": "数量",
  "page.MonthlyStatements.pageTitle": "资产报告",
  "page.MonthlyStatements.net": "净资产值",
  "page.MonthlyStatements.Interest": "利息",
  "page.MonthlyStatements.Funds": "资产类型",
  "page.MonthlyStatements.ExchangeRate": "汇率",
  "page.MonthlyStatements.Ending": "结束",
  "page.MonthlyStatements.Download": "下载PDF",
  "page.MonthlyStatements.Deposit": "充值",
  "page.MonthlyStatements.Amount": "数量",
  "page.MonthlyStatements.account": "账户信息",
  "page.message.title": "消息",
  "page.HomePage.topItemTitle3": "高性能",
  "page.HomePage.topItemTitle2": "安全",
  "page.HomePage.topItemTitle1": "全球贸易平台",
  "page.HomePage.topItemContent3": "强大的核心匹配引擎，能够处理大量的订单和执行。",
  "page.HomePage.topItemContent2": "一个安全可靠的交易环境，以及保险托管服务。多节点容灾、远程容灾、多服务器切换，确保抗灾能力。",
  "page.HomePage.topItemContent1": "为来自全球各地的零售和机构客户提供服务。",
  "page.HomePage.startTrading": "开始交易",
  "page.HomePage.prodTextMore": "了解更多",
  "page.HomePage.prodText": "Matrix已获得由阿布扎比全球市场金融监管机构颁发的原则性批复（IPA）。",
  "page.HomePage.Pair": "交易对",
  "page.HomePage.messageTitle": "世界级交易平台",
  "page.HomePage.message2": "订单操作简洁易用，随时开启交易之旅。",
  "page.HomePage.message1": "Matrix直观的交易界面为您持续提供实时订单、数据图表。",
  "page.HomePage.LastPrice": "最新成交价",
  "page.HomePage.empoverTitle": "在Matrix，你可以享受到",
  "page.HomePage.empoverItemTitle3": "安全交易",
  "page.HomePage.empoverItemTitle2": "设计简明的操作平台",
  "page.HomePage.empoverItemTitle1": "全球法币&币币交易",
  "page.HomePage.empoverItemContent3": "Matrix致力于保证用户的资金安全。我们遵守阿联酋金融服务监管机构的监督和规章制度",
  "page.HomePage.empoverItemContent2": "Matrix交易平台方便易用。不管交易高手还是交易小白，都可以轻松操作。",
  "page.HomePage.empoverItemContent1": "Matrix用户可以按自己的喜好选择存入、提取数字货币和法币。",
  "page.HomePage.Markets": "行情",
  "page.HomePage.bottomText3": "无论您是经验丰富的交易参与者，还是交易新人，Matrix都将为您的资产提供安全的工具",
  "page.HomePage.bottomText2": "和活跃交易者",
  "page.HomePage.bottomText1": "新手工具",
  "page.HomePage.24Volume": "24h交易量",
  "page.HomePage.24Change": "24h涨跌幅",
  "page.GA.Set.stepTitle2": "请输入6位验证码和邮箱验证码，并完成设置",
  "page.GA.Set.stepTitle": "请在苹果应用商店或者谷歌应用商店搜索谷歌身份验证器",
  "page.GA.Set.PleaseEnterGoogleCode": "请输入谷歌验证码",
  "page.GA.Set.PleaseEnterEmailCode": "请输入邮箱验证码",
  "page.GA.Set.notice2": "请保管好您的私钥，以防手机丢失或更换手机导致无法登录。",
  "page.GA.Set.notice1": "下载完成后，打开谷歌身份验证器并扫描下方二维码，或输入key值，获取6位验证码。",
  "page.GA.Set.GoogleAuthentication": "谷歌验证码",
  "page.GA.Set.Emailcode": "邮箱验证码",
  "page.GA.Set.Confirm": "确定",
  "page.GA.Set.account": "账户",
  "page.GA.NewGoogleAuthentication": "新的谷歌验证码",
  "page.GA.Change.stepTitle": "请输入6位密码和邮箱验证码，并完成设置。",
  "page.GA.Change.PleaseEnterNewGoogleCode": "请输入新的谷歌验证码",
  "page.GA.Change.PleaseEnterGoogleCode": "请输入旧的谷歌验证码",
  "page.GA.Change.PleaseEnterEmailCode": "请输入邮箱验证码",
  "page.GA.Change.notice2": "请保管好您的私钥，以免更换手机或手机丢失造成无法登录。",
  "page.GA.Change.notice1": "下载完成后，打开谷歌身份验证器并扫描下方二维码，或输入key值，获取6位验证码。",
  "page.GA.Change.NewGoogleAuthentication": "新谷歌验证码",
  "page.GA.Change.GoogleAuthentication": "旧谷歌验证码",
  "page.GA.Change.Emailcode": "邮箱验证码",
  "page.GA.Change.Confirm": "确认",
  "page.GA.Change.ChangeGA": "更改谷歌验证",
  "page.GA.Change.account": "账户",
  "page.cryptoAssets.totalEstimate": "总估值",
  "page.cryptoAssets.title": "数字资产",
  "page.cryptoAssets.table.total": "总额",
  "page.cryptoAssets.table.frozen": "冻结",
  "page.cryptoAssets.table.coin": "币种",
  "page.cryptoAssets.table.btcEstimate": "BTC估值",
  "page.cryptoAssets.table.balance": "余额",
  "page.cryptoAssets.table.action": "操作",
  "page.cryptoAssets.hideZero": "隐藏小额资产",
  "pages.Account.WithdrawalOrDeposit": "存取或提现",
  "pages.Account.Usedto": "用来找回密码 & 修改安全设置",
  "pages.Account.Unverified": "未验证",
  "pages.Account.Set": "设置",
  "pages.Account.Security": "安全",
  "pages.Account.ReferralLink": "邀请链接",
  "pages.Account.ReferralFriends": "邀请好友",
  "pages.Account.ReferralContent": "请使用以下链接邀请您的朋友加入，他们注册时输入您的邀请码后将获得12个月的9折交易费优惠。",
  "pages.Account.Referral": "推荐人",
  "pages.Account.perOrder": "每个订单",
  "pages.Account.perMonth": "每月",
  "pages.Account.pageWarning": "安全提示： 为了您的账户安全，请检查访问网址，开启双因子验证，不要透露短信和谷歌验证码给任何人，包括Matrix工作人员。",
  "pages.Account.pageTitle": "账户",
  "pages.Account.MyReferralID": "我的推荐ID",
  "pages.Account.LoginPassword": "登录密码",
  "pages.Account.Limit": "限价单",
  "pages.Account.GoogleAuthentication": "双因子验证",
  "pages.Account.EstimatedCommissionValue": "预估佣金",
  "pages.Account.CommissionRate": "佣金比例",
  "pages.Account.Change": "更改",
  "navBar.lang": "1111d d d ",
  "name": "名字",
  "message.title.error": "错误",
  "message.success.register": "注册成功！",
  "message.success.passwordUpdate": "密码更新成功，请重新登录。",
  "message.success.passwordReset": "密码修改成功，请重新登录。",
  "message.success.checkEmail": "请查收邮件。",
  "menu.welcome": "欢迎",
  "menu.result.success": "成功",
  "menu.result.fail": "失败",
  "menu.result": "结果",
  "menu.register.result": "注册结果",
  "menu.register": "注册",
  "menu.profile.basic": "基础详情页",
  "menu.profile.advanced": "高级详情页",
  "menu.profile": "详情页",
  "menu.more-blocks": "更多区块",
  "menu.login": "登录",
  "menu.list.table-list": "查询表格",
  "menu.list.search-list.projects": "搜索列表（项目）",
  "menu.list.search-list.articles": "搜索列表（文章）",
  "menu.list.search-list.applications": "搜索列表（应用）",
  "menu.list.search-list": "搜索列表",
  "menu.list.card-list": "卡片列表",
  "menu.list.basic-list": "基础列表",
  "menu.list": "列表页",
  "menu.home": "首页",
  "menu.form.step-form.result": "分步表单（完成）",
  "menu.form.step-form.info": "分步表单（填写转账信息）",
  "menu.form.step-form.confirm": "分步表单（确认转账信息）",
  "menu.form.step-form": "分步表单",
  "menu.form.basic-form": "基础表单",
  "menu.form.advanced-form": "高级表单",
  "menu.form": "表单页",
  "menu.exception.trigger": "触发错误",
  "menu.exception.server-error": "500",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.500": "500",
  "menu.exception.404": "404",
  "menu.exception.403": "403",
  "menu.exception": "异常页",
  "menu.editor.mind": "脑图编辑器",
  "menu.editor.koni": "拓扑编辑器",
  "menu.editor.flow": "流程编辑器",
  "menu.editor": "图形编辑器",
  "menu.dashboard.workplace": "工作台",
  "menu.dashboard.monitor": "监控页",
  "menu.dashboard.analysis": "分析页",
  "menu.dashboard": "面板",
  "menu.account.trigger": "触发报错",
  "menu.account.settings": "个人设置",
  "menu.account.logout": "退出",
  "menu.account.center": "个人中心",
  "menu.account": "个人页",
  "layout.user.link.terms": "条款",
  "layout.user.link.privacy": "隐私",
  "layout.user.link.help": "帮助",
  "label.referralId": "推荐人ID",
  "label.password": "密码",
  "label.ga": "谷歌验证码",
  "label.email": "邮箱或用户名",
  "input.placeholder.referralId": "推荐人ID (选填)",
  "input.placeholder.password": "请输入密码",
  "input.placeholder.oldPassword": "请输入旧密码",
  "input.placeholder.newPassword": "请输入新密码",
  "input.placeholder.ga": "谷歌验证码开启",
  "input.placeholder.enter": "请输入",
  "input.placeholder.emailVerifyCode": "邮箱验证码",
  "input.placeholder.email": "请输入您的邮箱",
  "input.placeholder.confirmPassword": "请确认密码",
  "input.error.passwordsNotMatch": "密码不一致，请重新输入。",
  "input.error.invalidEmail": "邮箱格式错误。",
  "input.error.empty": "此字段必填。",
  "input.error.confirmPasswordEmpty": "请确认密码。",
  "errorCode.OLD_GA_CODE_WRONG": "旧谷歌验证码错误",
  "errorCode.NEW_GA_CODE_WRONG": "新谷歌验证码错误",
  "errorCode.GA_NOT_OPEN": "谷歌验证未开启",
  "errorCode.GA_NOT_BOUND": "谷歌验证未绑定",
  "errorCode.GA_NOTNULL": "谷歌验证不能空白",
  "errorCode.GA_INVALID": "谷歌验证码不正确或已过期。",
  "errorCode.GA_CODE_WRONG": "谷歌验证码错误",
  "errorCode.GA_BIND_REQUEST_EXPIRED": "谷歌验证绑定过期",
  "errorCode.GA_ALREADY_BOUND": "谷歌验证码已绑定",
  "components.kyc.Title.link": "链接",
  "components.kyc.Title.kyc": "身份验证",
  "components.kyc.Status.pending": "您的KYC认证还在进行中",
  "components.kyc.Status.haveNotSet": "注意：您没有开始",
  "components.kyc.Status.haveNotCompleted": "注意： 您没有完成",
  "components.kyc.Status.enhancedKYC": "注意： 您需要提供其他文件",
  "components.Header.menuTradeHistory": "历史成交",
  "components.Header.menuSupportCenter": "帮助中心",
  "components.Header.menuSupport": "帮助中心",
  "components.Header.menuSubmitRequest": "提交请求",
  "components.Header.menuRegister": "注册",
  "components.Header.menuOrders": "订单",
  "components.Header.menuOrderHistory": "历史委托",
  "components.Header.menuOpenOrders": "当前委托",
  "components.Header.menuNews": "新闻",
  "components.Header.menuMonthlyStatements": "资产报告",
  "components.Header.menuLogin": "登录",
  "components.Header.menuCrypto": "数字资产",
  "components.Header.menuAssets": "资产",
  "components.Header.menuAPI": "API 管理",
  "components.Header.menuAccount": "账户",
  "components.Footer.menuUserProtocol": "帮助中心",
  "components.Footer.menuSupport": "帮助中心",
  "components.Footer.menuStatementOfClauses": "用户支持",
  "components.Footer.menuSocial": "社交",
  "components.Footer.menuReturnCommission": "返佣",
  "components.Footer.menuPrivacyServices": "隐私服务",
  "components.Footer.menuPlatformSupport": "用户服务",
  "components.Footer.menuLegalStatement": "法律和隐私",
  "components.Footer.menuFees": "费率标准",
  "components.Footer.menuContactUS": "联系我们",
  "components.Footer.menuCompanyProfile": "公司信息",
  "components.Footer.menuAPIDocument": "API文档",
  "components.Footer.menuAnnouncement": "公告声明",
  "components.Footer.menuAboutUS": "关于我们",
  "component.tagSelect.expand": "展开",
  "component.tagSelect.collapse": "收起",
  "component.tagSelect.all": "全部",
  "component.SymbolToggle.volume": "24小时成交量",
  "component.SymbolToggle.pair": "交易对",
  "component.SymbolToggle.lastPrice": "最新成交价",
  "component.SymbolToggle.change": "24h涨跌幅",
  "component.priceOrder.total": "总交易金额",
  "component.priceOrder.sell": "卖",
  "component.priceOrder.price": "价格",
  "component.priceOrder.optionText": "选择深度",
  "component.priceOrder.depthStep6": "1000",
  "component.priceOrder.depthStep5": "100",
  "component.priceOrder.depthStep4": "10",
  "component.priceOrder.depthStep3": "1",
  "component.priceOrder.depthStep2": "0.1",
  "component.priceOrder.depthStep1": "{size}",
  "component.priceOrder.buyAndSell": "所有",
  "component.priceOrder.buy": "买入",
  "component.priceOrder.amount": "数量",
  "component.priceOrder.all": "全部",
  "component.OrderHistoryBox.empty": "尚无委托记录",
  "component.OrderBox.openOrder": "当前委托",
  "component.OrderBox.more": "更多",
  "component.OrderBox.historyOrder": "历史委托",
  "component.noticeIcon.view-more": "查看更多",
  "component.noticeIcon.empty": "暂无通知",
  "component.noticeIcon.cleared": "已清空",
  "component.noticeIcon.clear": "清空",
  "component.modal.title.warning": "提示",
  "component.modal.title.error": "错误",
  "component.hudbar.lastPrice": "最新成交价",
  "component.hudbar.24hVolume": "24小时成交量",
  "component.hudbar.24hLow": "24小时最低",
  "component.hudbar.24hHigh": "24小时最高",
  "component.hudbar.24hChange": "24h涨跌幅",
  "component.header.nav.trade": "开始交易",
  "component.globalHeader.search.example3": "搜索提示三",
  "component.globalHeader.search.example2": "搜索提示二",
  "component.globalHeader.search.example1": "搜索提示一",
  "component.globalHeader.search": "站内搜索",
  "component.globalHeader.notification.empty": "你已查看所有通知",
  "component.globalHeader.notification": "通知",
  "component.globalHeader.message.empty": "您已读完所有消息",
  "component.globalHeader.message": "消息",
  "component.globalHeader.help": "帮助",
  "component.globalHeader.event.empty": "你已查看所有待办",
  "component.globalHeader.event": "待办",
  "component.common.marketOrder": "市价",
  "app.settings.security.weak": "弱",
  "app.settings.security.strong": "强",
  "app.settings.security.set": "设置",
  "app.settings.security.question-description": "请设置密保问题，其可有效保护账户安全。",
  "app.settings.security.question": "密保问题",
  "app.settings.security.phone-description": "已绑定手机",
  "app.settings.security.phone": "密保手机",
  "app.settings.security.password-description": "当前密码强度",
  "app.settings.security.password": "账户密码",
  "app.settings.security.modify": "修改",
  "app.settings.security.mfa-description": "未绑定多因子认证设备，绑定后，可以进行二次确认",
  "app.settings.security.mfa": "多因子认证设备",
  "app.settings.security.medium": "中",
  "app.settings.security.email-description": "已绑定邮箱",
  "app.settings.security.email": "备用邮箱",
  "app.settings.security.bind": "绑定",
  "app.settings.open": "开",
  "app.settings.notification.todo-description": "待办任务将以站内信的形式通知",
  "app.settings.notification.todo": "待办任务",
  "app.settings.notification.password-description": "其他用户的消息将以站内信的形式通知",
  "app.settings.notification.password": "账户密码",
  "app.settings.notification.messages-description": "系统消息将以站内信的形式通知",
  "app.settings.notification.messages": "系统消息",
  "app.settings.menuMap.security": "安全设置",
  "app.settings.menuMap.notification": "新消息通知",
  "app.settings.menuMap.binding": "账号绑定",
  "app.settings.menuMap.basic": "基本设置",
  "app.settings.close": "关",
  "app.settings.binding.taobao-description": "当前未绑定淘宝账号",
  "app.settings.binding.taobao": "绑定淘宝",
  "app.settings.binding.dingding-description": "当前未绑定钉钉账号",
  "app.settings.binding.dingding": "绑定钉钉",
  "app.settings.binding.bind": "绑定",
  "app.settings.binding.alipay-description": "当前未绑定支付宝账号",
  "app.settings.binding.alipay": "绑定支付宝",
  "app.settings.basic.update": "更新基本信息",
  "app.settings.basic.profile-placeholder": "个人简介",
  "app.settings.basic.profile-message": "请输入个人简介!",
  "app.settings.basic.profile": "个人简介",
  "app.settings.basic.phone-message": "请输入您的联系电话!",
  "app.settings.basic.phone": "联系电话",
  "app.settings.basic.nickname-message": "请输入您的昵称!",
  "app.settings.basic.nickname": "昵称",
  "app.settings.basic.geographic-message": "请输入您的所在省市!",
  "app.settings.basic.geographic": "所在省市",
  "app.settings.basic.email-message": "请输入您的邮箱!",
  "app.settings.basic.email": "邮箱",
  "app.settings.basic.country-message": "请输入您的国家或地区!",
  "app.settings.basic.country": "国家/地区",
  "app.settings.basic.change-avatar": "更换头像",
  "app.settings.basic.avatar": "头像",
  "app.settings.basic.address-message": "请输入您的街道地址!",
  "app.settings.basic.address": "街道地址",
  "app.setting.weakmode": "色弱模式",
  "app.setting.topmenu": "顶部菜单布局",
  "app.setting.themecolor.volcano": "火山",
  "app.setting.themecolor.sunset": "日暮橘",
  "app.setting.themecolor.purple": "酱紫",
  "app.setting.themecolor.green": "极光绿",
  "app.setting.themecolor.geekblue": "极客蓝",
  "app.setting.themecolor.dust": "薄暮红",
  "app.setting.themecolor.daybreak": "拂晓蓝（默认）",
  "app.setting.themecolor.cyan": "明青",
  "app.setting.themecolor": "主题色",
  "app.setting.sidemenu": "侧边菜单布局",
  "app.setting.production.hint": "设置面板仅在开发环境中显示，请手动修改。",
  "app.setting.pagestyle.light": "亮色菜单风格",
  "app.setting.pagestyle.dark": "暗色菜单风格",
  "app.setting.pagestyle": "整体风格设置",
  "app.setting.othersettings": "其他设置",
  "app.setting.navigationmode": "导航模式",
  "app.setting.hideheader.hint": "固定 Header 时可配置",
  "app.setting.hideheader": "下滑时隐藏 Header",
  "app.setting.fixedsidebar.hint": "侧边菜单布局时可配置",
  "app.setting.fixedsidebar": "固定侧边菜单",
  "app.setting.fixedheader": "固定 Header",
  "app.setting.copyinfo": "拷贝成功，请到 src/defaultSettings.js 中替换默认配置",
  "app.setting.copy": "拷贝设置",
  "app.setting.content-width.fluid": "流式",
  "app.setting.content-width.fixed": "定宽",
  "app.setting.content-width": "内容区域宽度",
  "app.pwa.serviceworker.updated.ok": "刷新",
  "app.pwa.serviceworker.updated.hint": "请点击“刷新”按钮或者手动刷新页面",
  "app.pwa.serviceworker.updated": "有新内容",
  "app.pwa.offline": "当前处于离线状态",
  "app.preview.down.block": "下载此页面到本地",
  "app.containers.WithdrawPage.value": "价值",
  "app.containers.WithdrawPage.trade": "去交易",
  "app.containers.WithdrawPage.tipGA": "谷歌验证完毕后才能添加提币地址。",
  "app.containers.WithdrawPage.tip2": "您可以在充值提现历史记录页面跟踪状态。",
  "app.containers.WithdrawPage.tip1": "提现请求申请成功后，请去邮箱点击链接确认本次提现请求。",
  "app.containers.WithdrawPage.tip": "提醒",
  "app.containers.WithdrawPage.time": "时间",
  "app.containers.WithdrawPage.submit": "提交",
  "app.containers.WithdrawPage.status": "状态",
  "app.containers.WithdrawPage.pointError": "大于{baseAmount}",
  "app.containers.WithdrawPage.number": "数量",
  "app.containers.WithdrawPage.noMsg": "无提现记录",
  "app.containers.WithdrawPage.moreError": "超出最大提币范围",
  "app.containers.WithdrawPage.more": "更多",
  "app.containers.WithdrawPage.limit": "提现额度",
  "app.containers.WithdrawPage.lessError": "超出最小提币范围",
  "app.containers.WithdrawPage.important4": "最大提现数量为",
  "app.containers.WithdrawPage.important3": "请勿直接提现至众筹或ICO地址",
  "app.containers.WithdrawPage.important2": "",
  "app.containers.WithdrawPage.important1": "最小提现数量为",
  "app.containers.WithdrawPage.important": "注意",
  "app.containers.WithdrawPage.history": "历史记录",
  "app.containers.WithdrawPage.header": "提现",
  "app.containers.WithdrawPage.get": "实际到账",
  "app.containers.WithdrawPage.gaCode": "谷歌验证码",
  "app.containers.WithdrawPage.filled": "确认",
  "app.containers.WithdrawPage.fee": "手续费",
  "app.containers.WithdrawPage.emptyError": "该字段不能为空",
  "app.containers.WithdrawPage.delete": "删除",
  "app.containers.WithdrawPage.currency": "币种",
  "app.containers.WithdrawPage.amountError": "金额必须大于{baseAmount}",
  "app.containers.WithdrawPage.amount": "提现数量",
  "app.containers.WithdrawPage.addressName": "提币地址",
  "app.containers.WithdrawPage.address": "提现地址",
  "app.containers.WithdrawPage.addmsg": "添加地址",
  "app.containers.WithdrawPage.add": "添加",
  "app.containers.tradeBox.priceDiff": "差价为{rate}%, 是否确认下单?",
  "app.containers.ResetPage.vcode": "验证码",
  "app.containers.ResetPage.rePassword": "确认密码",
  "app.containers.ResetPage.newPassword": "新密码",
  "app.containers.ResetPage.lengthError": "至少8个字符，必须包含大写字母和数字",
  "app.containers.ResetPage.header": "重置密码",
  "app.containers.ResetPage.getCode": "发送验证码",
  "app.containers.ResetPage.button": "保存",
  "app.containers.RegisterValidatePage.tip": "如果您未收到邮件，请尝试以下方法：",
  "app.containers.RegisterValidatePage.step4": "请确保您的邮箱能够正常收发邮件。",
  "app.containers.RegisterValidatePage.step3": "请添加Matrix到您的邮箱白名单里。",
  "app.containers.RegisterValidatePage.step2": "请您检查垃圾邮件箱。",
  "app.containers.RegisterValidatePage.step1": "请确保输入邮箱地址是正确的。",
  "app.containers.RegisterValidatePage.REGISTRATION_REQUEST_EXPIRED": "验证码已过期",
  "app.containers.RegisterValidatePage.header": "邮箱验证码",
  "app.containers.RegisterValidatePage.getCode": "发送验证码",
  "app.containers.RegisterValidatePage.button": "注册",
  "app.containers.RegisterPage.REGISTRATION_REQUEST_EXPIRED": "验证码已过期",
  "app.containers.RegisterPage.referralId": "推荐人ID",
  "app.containers.RegisterPage.password": "密码",
  "app.containers.RegisterPage.name": "用户名",
  "app.containers.RegisterPage.login": "登录",
  "app.containers.RegisterPage.lengthError": "至少8个字符，必须包含大写字母和数字。",
  "app.containers.RegisterPage.header": "创建您的账号",
  "app.containers.RegisterPage.getCode": "发送邮件",
  "app.containers.RegisterPage.emptyError": "该字段不能为空",
  "app.containers.RegisterPage.emailError": "请输入正确格式的邮箱",
  "app.containers.RegisterPage.email": "邮箱",
  "app.containers.RegisterPage.copassword": "确认密码",
  "app.containers.RegisterPage.confirmError": "两次输入的内容不一致",
  "app.containers.RegisterPage.code": "验证码",
  "app.containers.RegisterPage.button": "注册",
  "app.containers.RegisterPage.AUTH_GA_NOTNULL": "谷歌验证码不能为空",
  "app.containers.RegisterPage.AUTH_GA_INVALID": "谷歌验证码错误",
  "app.containers.RegisterPage.alreadyRegister": "已有帐号?",
  "app.containers.RegisterPage.ageAgreementTip": "Please agree over 18 years old",
  "app.containers.RegisterPage.agreement": "我同意",
  "app.containers.RegisterPage.agreeLink": "Matrix的使用条款",
  "app.containers.RegisterPage.ageAgreement": "  I am over 18 years old.",
  "app.containers.RechargePage.value": "价值",
  "app.containers.RechargePage.trade": "去交易",
  "app.containers.RechargePage.tip4": "历史记录",
  "app.containers.RechargePage.tip3": "充值完成后，你可以在历史记录中查看进度。",
  "app.containers.RechargePage.tip2": "充值才能到账。",
  "app.containers.RechargePage.tip1": "多个网络确认后",
  "app.containers.RechargePage.tip": "请注意",
  "app.containers.RechargePage.time": "时间",
  "app.containers.RechargePage.status": "状态",
  "app.containers.RechargePage.qrcode": "展示二维码",
  "app.containers.RechargePage.number": "充值数量",
  "app.containers.RechargePage.noMsg": "无充值记录",
  "app.containers.RechargePage.more": "更多",
  "app.containers.RechargePage.history": "历史记录",
  "app.containers.RechargePage.header": "充值",
  "app.containers.RechargePage.filled": "确认",
  "app.containers.RechargePage.currency": "币种",
  "app.containers.RechargePage.cpoy": "复制地址",
  "app.containers.RechargePage.attentionText2": "地址充值其他币种的资产，任何充入该地址的非该币种资产将不可找回。",
  "app.containers.RechargePage.attentionText1": "禁止向",
  "app.containers.RechargePage.attention": "注意",
  "app.containers.RechargePage.addressName": "打款地址",
  "app.containers.RechargePage.address": "地址",
  "app.containers.PasswordPage.tip": "为了您的资产安全，密码修改后24小时内不允许提现。",
  "app.containers.PasswordPage.savePassword": "保存密码",
  "app.containers.PasswordPage.oldPassword": "原密码",
  "app.containers.PasswordPage.oldoldError": "两次输入的内容不一致。",
  "app.containers.PasswordPage.newPassword": "新密码",
  "app.containers.PasswordPage.lengthError": "至少8个字符，必须包含大写字母和数字。",
  "app.containers.PasswordPage.header": "This is the PasswordPage container!",
  "app.containers.PasswordPage.emptyError": "该字段不能为空",
  "app.containers.PasswordPage.confirmPassword": "确认密码",
  "app.containers.PasswordPage.change": "修改密码",
  "app.containers.PasswordPage.account": "个人中心",
  "app.containers.LoginPage.register": "注册",
  "app.containers.LoginPage.noAccount": "尚无帐号? ",
  "app.containers.LoginPage.header": "登录",
  "app.containers.LoginPage.GA_NOTNULL": "GA验证码不能为空。",
  "app.containers.LoginPage.GA_INVALID": "谷歌验证码错误。",
  "app.containers.LoginPage.forgotPassword": "忘记密码?",
  "app.containers.LoginPage.button": "登录",
  "app.containers.LoginPage.AUTH_GA_NOTNULL": "请输入谷歌代码",
  "app.containers.LoginPage.AUTH_GA_INVALID": "谷歌验证码不正确",
  "app.containers.LoginPage.alreadyLoginTip": "你已经登录过了，请返回主页。请注意，您同时只能登录一个账号。",
  "app.containers.ForgotpasswordPage.message": "为了您的资产安全，当有任何的密码修改后24小时内不允许提现。",
  "app.containers.ForgotpasswordPage.header": "忘记密码",
  "app.containers.ForgotpasswordPage.emptyError": "该字段不能为空",
  "app.containers.ForgotpasswordPage.emailError": "请输入正确格式的邮箱。",
  "app.containers.ForgotpasswordPage.email": "邮箱",
  "app.containers.ForgotpasswordPage.button": "提交",
  "app.containers.BalancePage.withdraw": "提现",
  "app.containers.BalancePage.trade": "交易",
  "app.containers.BalancePage.totalEstimate": "当前市场总估值",
  "app.containers.BalancePage.remainder": "可用资产",
  "app.containers.BalancePage.recharge": "充值",
  "app.containers.BalancePage.locked": "锁定",
  "app.containers.BalancePage.hideZeroBalance": "隐藏小额资产",
  "app.containers.BalancePage.header": "我的资产",
  "app.containers.BalancePage.fullname": "全称",
  "app.containers.BalancePage.coin": "币种",
  "app.containers.BalancePage.block": "冻结资产",
  "app.containers.BalancePage.amount": "资产",
  "app.containers.BalancePage.about": "美元估值"
}
