import { getFiatAssert } from '@/services/fiat';
import { showResponseError } from '@/utils/utils';

export default {
  namespace: 'fiat',

  state: {
    fiatAssert: null,
  },

  effects: {
    *getFiatAssert({ meta }, { call, put }) {
      const res = yield call(getFiatAssert);
      if (res.status === 'success') {
        yield put({
          type: 'setFiatInfo',
          payload: res.data,
        });
        if (meta) meta();
      } else {
        showResponseError(res);
      }
    },
  },

  reducers: {
    setFiatInfo(state, action) {
      return {
        ...state,
        fiatAssert: action.payload,
      };
    },
  },
};
