/* eslint-disable */

import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isFunction from 'lodash/isFunction';
import once from 'lodash/once';
import Big from 'big.js';
import moment from 'moment-timezone';

import { DEV_ENV } from '@/utils';

import { getOursMarketPrices, getMarketSnapshot, getMarketTicks, getMarketMidpoint } from '@/services/trade';
import {
  debounceWrapper,
  filterSymbol,
  get24hChangePrecision,
  throttleWrapper,
  localStore,
} from '@/utils/utils';

import { handleReceive } from '@/components/Tradingview/DataFeed';
import {
  snapShotHandler,
  parseOrderPrice,
  formatPricesDisplay,
  DEFAULT_TIME_FRAME,
  TIME_FRAMES,
} from '@/utils/trade';

const initOnce = once(dispatch => {
  dispatch({
    type: 'globalIsReady',
  });
});

let TICKLIST = [];
const TRADETIMEFRAME = 'TRADE.TIMEFRAME';

const updateTickListWrap = throttleWrapper(type => {
  window.g_app._store.dispatch(type);
}, 500);

export default {
  namespace: 'trade',
  state: {
    depth: null,
    prices: {},
    tickList: [],
    timeFrame: localStore.get(TRADETIMEFRAME) || DEFAULT_TIME_FRAME,
    lastPrice: '--',
    lastPriceType: '',
    lastUsd: null,
    activeTab: 0,
    middlePrice: '--'
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (/^\/trade/i.test(pathname)) {
          initOnce(dispatch);
        }
      });
    },
    resetTrade({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (!/^\/trade/i.test(pathname)) {
          dispatch({ type: 'resetState' });
        }
      });
    },
  },
  effects: {
    *globalIsReady({}, { put, take, race, select }) {
      yield take('TRADEISREADY');

      // First, wait the right symbol(switch symbol by external handler)
      yield take('updateSymbol');
      if (DEV_ENV) {
        const currentSymbol = yield select(state => state.trade.symbol);
        console.log('Take current symobl: ', currentSymbol);
      }

      // Than, do sth what is related to this symbol
      yield race([
        put({ type: 'getAllPrice' }),
        put({ type: 'topicSnapshot' }),
        put({ type: 'topicTicks' }),
        put({ type: 'topicMidpoint' }),
        put({ type: 'topicPrices' }),
        put({ type: 'topicBars' }),
        put({ type: 'topicOrder' }),
      ]);
    },
    *updateMarketSnapshot({ symbol, cb }, { call, put, select }) {
      try {
        const response = yield call(getMarketSnapshot, symbol);
        if (get(response, 'status') === 'success') {
          const symbols = yield select(state => state.global.symbols);
          const currencies = yield select(state => state.global.currencies);
          const targetSymbol = find(symbols, { name: symbol });
          const { quoteScale } = targetSymbol || {};
          //此处是处理bug , price == null get方法赋值不了默认值 所以要不 price === null 赋值 undefined
          if(response.data.price == null) response.data.price = undefined
          const price = get(response, 'data.price', 1);
          if (isFunction(cb)) cb(Big(price === 0 ? 1 : price).toFixed(quoteScale));
          yield put({
            type: 'updatePriceList',
            data: get(response, 'data'),
            symbols,
          });
          yield put({
            type: 'updateLastPrice',
            data: get(response, 'data'),
            symbols,
            currencies,
          });
        }
      } catch (err) {
        console.log(err, '-----');
      }
    },
    *updateMarketMidpoint({symbol},{call, put}){
       try {
        const response = yield call(getMarketMidpoint, symbol);
        if (get(response, 'status') === 'success') {
          yield put({
            type: 'updateMidpoint',
            data: get(response, 'data'),
          });
        }
       } catch (err) {
        console.log(err, '-----');

       }
    },
    *getAllPrice({ payload }, { call, put, take, select }) {
      try {
        const response = yield call(getOursMarketPrices);
        if (get(response, 'status') === 'success') {
          const symbols = yield select(state => state.global.symbols);
          const currencies = yield select(state => state.global.currencies);
          yield put({
            type: 'updateSymbolsPrice',
            payload: {
              prices: get(response, 'data'),
              symbols,
              currencies,
            },
          });
        } else {
          yield put({
            type: 'global/fetchingError',
          });
        }
      } catch (error) {
        console.log(error, '-----------');
      }
    },
    *topicSnapshot({}, { throttle, put, select }) {
      try {
        yield throttle(500, 'TOPIC_SNAPSHOT', function*({ data }) {
          const {
            trade: { symbol },
            global: { symbols },
          } = yield select();
          if (data.symbol === symbol) {
            yield put({
              type: 'updatePriceList',
              data,
              symbols,
            });
          }
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    *topicMidpoint({}, {throttle, take, put, select }){
      try{
        yield throttle(500, 'TOPIC_MIDPOINT', function*({ data }) {
          console.log('updateMidpoint11111', data)
          const {
            trade: { symbol },
          } = yield select();
          if (data.symbol === symbol) {
            yield put({
              type: 'updateMidpoint',
              data,
              
            });
          }
        });
      }catch(err){

      }
    },
    *topicTicks({}, { take, put, select }) {
      try {
        while (true) {
          const { data } = yield take('TOPIC_TICKS');
          // data.middlePrice = data.price
          const {
            trade: { symbol },
            global: { symbols, currencies },
          } = yield select();
          if (data.symbol === symbol) {
            TICKLIST.unshift(data);
            updateTickListWrap({
              type: 'trade/updateTickListBuySocket',
              data,
            });
            updateTickListWrap({
              type: 'trade/updateLastPrice',
              data,
              symbols,
              currencies,
            });
            // yield put({
            //   type: 'updateTickListBuySocket',
            //   data,
            // });
            // yield put({
            //   type: 'updateLastPrice',
            //   data,
            //   symbols,
            //   currencies,
            // });
          }
        }
      } catch (error) {
        console.log(error.message, '-------');
      }
    },
    *topicPrices({}, { throttle, put, select }) {
      try {
        yield throttle(500, 'TOPIC_PRICES', function*({ data }) {
          const {
            global: { symbols, currencies },
          } = yield select();
        //  console.log("TOPIC_PRICES", data.ETH_USD);
          yield put({
            type: 'updateSymbolsPrice',
            payload: {
              symbols,
              currencies,
              prices: data,
            },
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    *topicBars({}, { take, put, select }) {
      try {
        while (true) {
          const { data } = yield take('TOPIC_BARS');
          yield put({
            type: 'updateBars',
            data,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    *topicOrder({}, { throttle, put, select }) {
      try {
        yield throttle(500, 'TOPIC_ORDER', function*({ data }) {
          const activeTab = yield select(state => state.trade.activeTab);
          yield put({ type: 'orderList/getOrderList', payload: { activeTab } });
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    *getTicks({ symbol }, { call, put }) {
      try {
        const response = yield call(getMarketTicks, symbol);
        if (get(response, 'status') === 'success') {
          yield put({
            type: 'updateTickList',
            data: get(response, 'data'),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    *reset({ cb, symbol }, { put }) {
      yield put({ type: 'resetState', payload: { symbol } });
      cb();
      yield put({ type: 'socketModel/changeSymbol', symbol });
    },
  },
  reducers: {
    initDepth(state, { depth }) {
      state.depth = depth;
    },
    updateLastPrice(state, { data, symbols, currencies }) {
      try {
        const targetSymbol = find(symbols, { name: state.symbol });
        const { baseScale, quoteScale } = targetSymbol || {};
        const { fex } = find(currencies, { name: state.symbol.split('_').pop() });
        const prePrice = state.lastPrice || '--';
        const price = get(data, 'price', 0);
        // const middlePrice = get(data,'middlePrice', 0)
        state.lastPrice = isNil(price) || price === 0 ? '--' : price;
        // 此行保留
        // state.middlePrice = isNil(middlePrice) || middlePrice === 0 ? '--' : Big(middlePrice).toFixed(quoteScale); 
        if (isNil(price) || Big(price).eq(0)) {
          state.lastPrice = '--';
        } else {
          const priceStr = price.toString();
          if (priceStr.indexOf('-') > -1) {
            state.lastPrice = Big(priceStr).toFixed(parseInt(priceStr.split('-').pop()));
          } else {
            state.lastPrice = Big(price).toFixed(quoteScale);
          }
        }

        if (state.lastPrice && state.lastPrice !== '--' && fex) {
          const lastUsd = Big(state.lastPrice).times(fex);
          if (lastUsd.gte(1)) {
            state.lastUsd = lastUsd.toFixed(2);
          } else {
            state.lastUsd = lastUsd.toFixed(6);
          }
        } else {
          state.lastUsd = null;
        }
        state.lastUsd = state.lastUsd ? `${state.lastUsd} USD` : null;
        if (prePrice === '--' || state.lastPrice === '--') {
          state.lastPriceType = '';
        } else {
          if (Big(state.lastPrice).eq(prePrice)) {
            state.lastPriceType = '';
          } else if (Big(state.lastPrice).gt(prePrice)) {
            state.lastPriceType = 'up';
          } else {
            state.lastPriceType = 'down';
          }
        }
      } catch (error) {
        // console.log(error);
        // Error:
        //  `state.symbol == null`
        //   when trigger subscriptions/resetTrade(resetState)
        state.lastPrice = '--';
        state.lastUsd = null;
        state.lastPriceType = '';
      }
    },
    updateMidpoint(state, {data}){
      state.middlePrice = Big(data.middlePrice).toFixed(4)
    },
    updateSymbolsPrice(state, { payload }) {
      try {
        const { prices, symbols, currencies } = payload;
        const usedSymbols = filterSymbol(symbols);
        const targetSymbol = find(symbols, { name: state.symbol });
        const { baseScale, quoteScale } = targetSymbol || {};
        const baseSymbol = state.symbol.split('_').pop();
        const quoteSymbol = state.symbol.split('_').shift();
        const { fex } = find(currencies, { name: baseSymbol });
        const targetPrice = prices[state.symbol];
        if (targetPrice) {
          const [_, openPrice, highPrice, lowPrice, closePrice, volume] = isString(targetPrice)
            ? JSON.parse(targetPrice)
            : targetPrice;
          const { close } = state.prices;
          const change = closePrice
            ? Big(closePrice)
                .minus(openPrice)
                .round(quoteScale, 3)
                .valueOf()
            : // .toFixed(quoteScale)
              // .round(quoteScale, 3)

              '--';

          state.prices = {
            open: formatPricesDisplay(openPrice, quoteScale, '--'),
            high: formatPricesDisplay(highPrice, quoteScale, '--'),
            low: formatPricesDisplay(lowPrice, quoteScale, '--'),
            close: formatPricesDisplay(closePrice, quoteScale, '--'),
            volume: `${formatPricesDisplay(volume, baseScale, '--')} ${quoteSymbol}`,
            // type:
            //   closePrice && close && close !== '--'
            //     ? Big(closePrice).eq(close)
            //       ? ''
            //       : Big(closePrice).gt(close)
            //       ? 'up'
            //       : 'down'
            //     : '',
            change,
            changePercent: get24hChangePrecision(openPrice, closePrice),
            // usd:
            //   isNumber(fex) && isNumber(closePrice)
            //     ? `${Big(closePrice)
            //         .times(fex)
            //         .toFixed(2)} USD`
            //     : null,
          };
        } else {
          state.prices = {
            open: '--',
            high: '--',
            low: '--',
            close: '--',
            volume: '--',
            // type: '',
            change: '--',
            changePercent: null,
          };
        }

        state.symbolList = map(usedSymbols, symbol => {
          try {
            if (prices[symbol.name]) {
              return {
                ...symbol,
                prices: isString(prices[symbol.name])
                  ? JSON.parse(prices[symbol.name])
                  : prices[symbol.name],
              };
            }
            return symbol;
          } catch (error) {
            return symbol;
          }
        });
      } catch (error) {
        console.log('error', error.message);
      }
    },
    updateSymbol(state, { symbol }) {
      state.symbol = symbol;
    },
    updatePriceList(state, { data, symbols }) {
      // 此行保留
      // const { buyOrders = [], sellOrders = [], middlePrice } = data;
      const { buyOrders = [], sellOrders = [] } = data;
      const targetSymbol = find(symbols, { name: state.symbol });
      const { baseScale, quoteScale } = targetSymbol || {};
      const { depth } = state;
      if (isNumber(baseScale) && isNumber(quoteScale)) {
        state.buyOrders = parseOrderPrice({
          baseScale,
          quoteScale,
          depth,
          orders: buyOrders,
          type: 'buy',
        });
        state.sellOrders = parseOrderPrice({
          baseScale,
          quoteScale,
          depth,
          orders: sellOrders,
          type: 'sell',
        });
        state.orders = data;
        // 此行保留
        // state.middlePrice = !isNil(middlePrice) && Big(middlePrice).toFixed(quoteScale);
      } else {
        state.buyOrders = [];
        state.sellOrders = [];
      }
    },
    updateDepth(state, { depth, symbols }) {
      state.depth = depth;
      const { buyOrders, sellOrders } = state.orders;
      const { baseScale, quoteScale } = find(symbols, { name: state.symbol }, {});
      state.buyOrders = parseOrderPrice({
        baseScale,
        quoteScale,
        depth,
        orders: buyOrders,
        type: 'buy',
      });
      state.sellOrders = parseOrderPrice({
        baseScale,
        quoteScale,
        depth,
        orders: sellOrders,
        type: 'sell',
      });
    },
    updateTickList(state, { data }) {
      state.tickList = data;
      TICKLIST = data;
    },
    updateTickListBuySocket(state, { data }) {
      if (TICKLIST.length > 50) {
        TICKLIST.length = 50;
      }
      state.tickList = [...TICKLIST];
    },
    updateBars(state, { data }) {
      const { symbol } = state;
      const { timeFrame } = state;
      const timeType = get(TIME_FRAMES, timeFrame);
      if (data.symbol === state.symbol && data.type === timeType && isFunction(handleReceive)) {
        handleReceive({
          time: get(data, 'data.0'),
          open: get(data, 'data.1'),
          high: get(data, 'data.2'),
          low: get(data, 'data.3'),
          close: get(data, 'data.4'),
          volume: get(data, 'data.5'),
        });
      }
    },
    resetState(state, { payload = {} }) {
      const { symbol = null } = payload;
      state.depth = null;
      state.tickList = [];
      state.prices = {};
      state.buyOrders = [];
      state.sellOrders = [];
      state.orders = null;
      state.symbol = symbol;
      state.lastPrice = '--';
      state.lastPriceType = '';
      state.lastUsd = null;
      state.timeFrame = localStore.get(TRADETIMEFRAME) || DEFAULT_TIME_FRAME;
    },
    updateTimeFrame(state, { timeFrame }) {
      state.timeFrame = timeFrame;
      localStore.set(TRADETIMEFRAME, timeFrame);
    },
    updateActiveTab(state, { activeTab }) {
      state.activeTab = activeTab;
    },
  },
};
