import { agreeExemption, getMessage, getDepositInfo } from '@/services/fiat';
import { showResponseError } from '@/utils/utils';

export default {
  namespace: 'fiatDeposit',

  state: {
    userMessage: null,
    depositInfo: null,
  },

  effects: {
    *getDepositInfo({ payload }, { call, put }) {
      const res = yield call(getDepositInfo, payload);
      if (res.status === 'success') {
        yield put({
          type: 'setDepositInfo',
          payload: res.data,
        });
      } else {
        showResponseError(res);
      }
    },
    *getMessage(_, { call, put }) {
      const res = yield call(getMessage);
      if (res.status === 'success') {
        yield put({
          type: 'setMessage',
          payload: res.data,
        });
      } else {
        showResponseError(res);
      }
    },
    *agreeExemption({ metaSuccess }, { call }) {
      const res = yield call(agreeExemption);
      if (res.status === 'success') {
        metaSuccess();
      } else {
        showResponseError(res);
      }
    },
  },

  reducers: {
    setDepositInfo(state, action) {
      return {
        ...state,
        depositInfo: action.payload,
      };
    },
    setMessage(state, action) {
      return {
        ...state,
        userMessage: action.payload,
      };
    },
  },
};
