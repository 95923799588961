/**
 *
 * OpenOrderBox
 *
 */

import React, { PureComponent } from 'react';
import { FormattedMessage, formatMessage } from 'umi-plugin-react/locale';
import { Spin } from 'antd';
// import Moment from 'react-moment';
import moment from 'moment-timezone';

// import Table from '@/components/Table';
// import Empty from '@/components/Empty';
import { customRenderEmpty } from '@/components/RenderEmptyConfigProvider';

import defer from '@/utils/defer';
import {
  // isZeroValue,
  isMarketPrice,
} from '@/utils/valueUtils';
import styles from './style.less';
import { Cancel } from './styles';

// const MARKET_PRICE = formatMessage({ id: 'component.common.marketOrder' });

const getMarketPriceMsg = () => formatMessage({ id: 'component.common.marketOrder' });

// const ctor = () => {}

// eslint-disable-next-line no-unused-vars
const Header = props => (
  <thead className={styles.thead}>
    <tr>
      <th className="date" title={formatMessage({ id: 'pages.OpenOrderPage.date' })}>
        <FormattedMessage id="pages.OpenOrderPage.date" />
      </th>
      <th className="symbol" title={formatMessage({ id: 'pages.OpenOrderPage.symbol' })}>
        <FormattedMessage id="pages.OpenOrderPage.symbol" />
      </th>
      <th className="type" title={formatMessage({ id: 'pages.OpenOrderPage.type' })}>
        <FormattedMessage id="pages.OpenOrderPage.type" />
      </th>
      <th className="side" title={formatMessage({ id: 'pages.OpenOrderPage.side' })}>
        <FormattedMessage id="pages.OpenOrderPage.side" />
      </th>
      <th className="price" title={formatMessage({ id: 'pages.OpenOrderPage.price' })}>
        <FormattedMessage id="pages.OpenOrderPage.price" />
      </th>
      <th className="amount" title={formatMessage({ id: 'pages.OpenOrderPage.amount' })}>
        <FormattedMessage id="pages.OpenOrderPage.amount" />
      </th>
      <th className="finished" title={formatMessage({ id: 'pages.OpenOrderPage.finished' })}>
        <FormattedMessage id="pages.OpenOrderPage.finished" />
      </th>
      <th className="filled" title={formatMessage({ id: 'pages.OpenOrderPage.filled' })}>
        <FormattedMessage id="pages.OpenOrderPage.filled" />
        (%)
      </th>
      <th className="triggerOn" title={formatMessage({ id: 'pages.OpenOrderPage.triggerOn' })}>
        <FormattedMessage id="pages.OpenOrderPage.triggerOn" />
      </th>
      <th className="action" title={formatMessage({ id: 'pages.OpenOrderPage.action' })}>
        <FormattedMessage id="pages.OpenOrderPage.action" />
      </th>
    </tr>
  </thead>
);

class DataTable extends PureComponent {
  state = {
    cancellingMap: {},
  };

  markCancelling = (id, status) => {
    const { resolve, promise } = defer();
    const { cancellingMap } = this.state;

    this.setState(
      {
        cancellingMap: {
          ...cancellingMap,
          [id]: status,
        },
      },
      resolve
    );

    return promise;
  };

  handleCancelProxy = item => {
    const { onCancel } = this.props;
    const { id } = item;

    return () => {
      this.markCancelling(id, true).then(() => onCancel(id));
      /*
        .then(() => this.markCancelling(id, false))
        .catch(() => this.markCancelling(id, false));
        */
    };
  };

  render() {
    const { orders } = this.props;
    const { cancellingMap } = this.state;

    return (
      <table className="order-box-table">
        <Header />
        <tbody className={styles.tbody}>
          {orders.map(item => {
            const { triggered = 0 } = item;
            const triggeredClass = triggered ? 'triggered' : '';
            return (
              <tr key={item.id}>
                <td className={`date ${triggeredClass}`}>
                  {/* <Moment unix format="HH:mm:ss DD/MM/YYYY">
                    {item.createdAt / 1000}
                  </Moment> */}
                  {moment(item.createdAt).format('HH:mm:ss DD/MM/YYYY')}
                </td>
                <td className={`symbol ${triggeredClass}`}>{item.symbol}</td>
                <td className={`type ${triggeredClass}`}>
                  {formatMessage({ id: `pages.OpenOrderPage.type.${item.type}` })}
                </td>
                <td className={`side ${triggeredClass}`}>
                  {formatMessage({ id: `pages.OpenOrderPage.side.${item.side}` })}
                </td>
                <td className={`price ${triggeredClass}`}>
                  {isMarketPrice(item.type) ? getMarketPriceMsg() : item.price}
                </td>
                <td className={`amount ${triggeredClass}`}>{item.amount}</td>
                <td className={`finished ${triggeredClass}`}>{item.filledAmount}</td>
                <td className={`filled ${triggeredClass}`}>{item.filledRate || '0.00'}%</td>
                <td className={`triggerOn ${triggeredClass}`}>{item.triggerOn}</td>
                <td className={`action ${triggeredClass}`}>
                  {cancellingMap[item.id] ? (
                    <span className={styles.isCancelling}>
                      <FormattedMessage id="pages.OpenOrderPage.cancel" />
                    </span>
                  ) : (
                    <Cancel onClick={this.handleCancelProxy(item)}>
                      <FormattedMessage id="pages.OpenOrderPage.cancel" />
                    </Cancel>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default ({ orders, loading, onCancel, isFixedHeader = false }) => (
  <div style={{ height: '100%', width: '100%', display: 'flex' }}>
    <Spin spinning={loading} wrapperClassName="order-list-spin">
      {isFixedHeader && (
        <div className="fixed-header-wrapper">
          <table className="order-box-table fixed-header">
            <Header />
          </table>
          <div className="scroll-pane">
            <DataTable orders={orders} onCancel={onCancel} />
            {orders.length === 0 && (
              <div className="none-data">
                {customRenderEmpty(formatMessage({ id: 'pages.OpenOrderPage.empty' }), true)}
              </div>
            )}
          </div>
        </div>
      )}

      {!isFixedHeader && <DataTable orders={orders} onCancel={onCancel} />}

      {!isFixedHeader && orders.length === 0 && (
        <div className="none-data">
          {customRenderEmpty(formatMessage({ id: 'pages.OpenOrderPage.empty' }), true)}
        </div>
      )}
    </Spin>
  </div>
);
