/**
 *
 * TradeHistoryBox
 *
 */

import React, { PureComponent } from 'react';
import { FormattedMessage, formatMessage } from 'umi-plugin-react/locale';
// import Moment from 'react-moment';
import moment from 'moment-timezone';
import { Spin } from 'antd';
import { connect } from 'dva';

import { identity } from '@/utils/utils';
import { getMatchedSymbol } from '@/utils/symbol';
// import Table from '@/components/Table';
// import Empty from '@/components/Empty';
import { customRenderEmpty } from '@/components/RenderEmptyConfigProvider';
// eslint-disable-next-line no-unused-vars
import { isZeroValue, isMarketPrice } from '@/utils/valueUtils';
import styles from './style.less';
// import messages from './messages';

// const MARKET_PRICE = formatMessage({ id: 'component.common.marketOrder' });
// eslint-disable-next-line no-unused-vars
const getMarketPriceMsg = () => formatMessage({ id: 'component.common.marketOrder' });

const Header = () => (
  <thead className={styles.thead}>
    <tr>
      <th className="date" title={formatMessage({ id: 'pages.OpenOrderPage.date' })}>
        <FormattedMessage id="pages.OpenOrderPage.date" />
      </th>
      <th className="symbol" title={formatMessage({ id: 'pages.OpenOrderPage.symbol' })}>
        <FormattedMessage id="pages.OpenOrderPage.symbol" />
      </th>
      <th className="type" title={formatMessage({ id: 'pages.OpenOrderPage.type' })}>
        <FormattedMessage id="pages.OpenOrderPage.type" />
      </th>
      <th className="side" title={formatMessage({ id: 'pages.OpenOrderPage.side' })}>
        <FormattedMessage id="pages.OpenOrderPage.side" />
      </th>
      <th className="price" title={formatMessage({ id: 'pages.OpenOrderPage.price' })}>
        <FormattedMessage id="pages.OpenOrderPage.price" />
      </th>
      <th className="amount" title={formatMessage({ id: 'pages.OpenOrderPage.amount' })}>
        <FormattedMessage id="pages.OpenOrderPage.amount" />
      </th>
      <th className="disFee" title={formatMessage({ id: 'pages.OpenOrderPage.disFee' })}>
        <FormattedMessage id="pages.OpenOrderPage.disFee" />
      </th>
      <th className="disFee" title={formatMessage({ id: 'pages.OpenOrderPage.vatInUsd' })}>
        <FormattedMessage id="pages.OpenOrderPage.vatInUsd" />
      </th>
      <th className="total" title={formatMessage({ id: 'pages.OpenOrderPage.total' })}>
        {formatMessage({ id: 'pages.OpenOrderPage.total' })}
      </th>
      <th className="receive" title={formatMessage({ id: 'pages.OpenOrderPage.receive' })}>
        <FormattedMessage id="pages.OpenOrderPage.receive" />
      </th>
    </tr>
  </thead>
);

const DataTable = ({ orders }) => (
  <table className="order-box-table order-box-trade-history-table">
    <Header />
    <tbody className={styles.tbody}>
      {orders.map(item => (
        <tr key={item.id}>
          <td className="date">
            {/* <Moment unix format="HH:mm:ss DD/MM/YYYY">
              {item.createdAt / 1000}
            </Moment> */}
            {moment(item.createdAt).format('HH:mm:ss DD/MM/YYYY')}
          </td>
          <td className="symbol">{item.symbol}</td>
          <td className="type">{formatMessage({ id: `pages.OpenOrderPage.type.${item.type}` })}</td>
          <td className="side">{formatMessage({ id: `pages.OpenOrderPage.side.${item.side}` })}</td>
          <td className="price">{item.price}</td>
          <td className="amount">{item.amount}</td>
          <td className="disFee">{item.DisFee}</td>
          <td className="vatInUsd">{item.vatInUsd}</td>
          <td className="total">{item.total}</td>
          <td className="receive">{item.receive}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

@connect(({ global }) => ({
  symbols: global.symbols,
}))
class TradeHistoryBox extends PureComponent {
  getRenderData = (trades, { itemCallback = identity } = {}) => {
    const { symbols } = this.props;

    return trades.map(trade => {
      let item = { ...trade };

      // eslint-disable-next-line no-unused-vars
      const { baseScale, quoteScale } = getMatchedSymbol(item.symbol.replace('/', '_'), symbols);

      const DisFee = parseFloat(item.fee);
      const price = parseFloat(item.price);
      const amount = parseFloat(item.amount);
      const vat = parseFloat(item.vat || 0);

      if (item.side === 'SELL') {
        item.total = `${(amount * price  - DisFee - vat ).toFixed(quoteScale)} ${item.feecurrency}`;
        item.receive = `${(amount * price - DisFee - vat).toFixed(quoteScale)} ${item.feecurrency}`;
      } else {
        item.total = `${(amount * price + DisFee + vat).toFixed(quoteScale)} ${item.feecurrency}`;
        item.receive = `${item.amount} ${item.symbol.split('/')[0]}`;
      }

      item.DisFee = `${DisFee.toFixed(quoteScale)} ${item.feecurrency}`;

      item = itemCallback(item);

      return item;
    });
  };

  render() {
    const { orders, loading, isFixedHeader = false } = this.props;
    // Do some prepared data
    const trades = this.getRenderData(orders);

    return (
      <div style={{ height: '100%', width: '100%', display: 'flex' }}>
        <Spin spinning={loading} wrapperClassName="order-list-spin">
          {isFixedHeader && (
            <div className="fixed-header-wrapper">
              <table className="order-box-table order-box-trade-history-table fixed-header">
                <Header />
              </table>
              <div className="scroll-pane">
                <DataTable orders={trades} />
                {trades.length === 0 && (
                  <div className="none-data">
                    {customRenderEmpty(formatMessage({ id: 'pages.TradeHistoryPage.empty' }), true)}
                  </div>
                )}
              </div>
            </div>
          )}
          {!isFixedHeader && <DataTable orders={trades} />}
          {!isFixedHeader && trades.length === 0 && (
            <div className="none-data">
              {customRenderEmpty(formatMessage({ id: 'pages.TradeHistoryPage.empty' }), true)}
            </div>
          )}
        </Spin>
      </div>
    );
  }
}

export default TradeHistoryBox;
