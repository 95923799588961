import { getFiatDepositHistory, getFiatWithdrawHistory } from '@/services/fiat';
import { showResponseError } from '@/utils/utils';

export default {
  namespace: 'fiatHistory',

  state: {
    data: {
      items: [],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    },
  },

  effects: {
    *fetchData({ payload }, { call, put }) {
      yield put({
        type: 'clearData',
      });
      const method = payload.type === 'deposit' ? getFiatDepositHistory : getFiatWithdrawHistory;
      const res = yield call(method, payload);
      if (res.status === 'success') {
        yield put({
          type: 'dataReducer',
          payload: res.data,
        });
      } else {
        showResponseError(res);
      }
    },
  },

  reducers: {
    dataReducer(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    clearData(state) {
      return {
        ...state,
        data: {
          items: [],
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        },
      };
    },
  },
};
