import React from 'react';
import { Modal } from 'antd';

import styles from './style.less';

export default function ModalContainer(props) {
  const { type, children } = props;
  let modal = null;
  if (!type) {
    const { wrapClassName = '' } = props;

    return (
      <Modal wrapClassName={`${wrapClassName} ${styles.outer}`} {...props}>
        {children}
      </Modal>
    );
  }
  const { className = '', hasBtn = true } = props;
  modal = Modal[type]();
  const updateObj = {
    className: `${className} ${styles.outer}`,
    ...props,
  };
  if (!hasBtn) {
    updateObj.className = `${className} ${styles.outer} ${styles.noBtn}`;
  }

  modal.update(updateObj);

  return modal;
}
