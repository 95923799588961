import { message } from 'antd';
import { localeMessage, showResponseError } from '@/utils/utils';
import { setFundPassword, resetFundPassword } from '@/services/api';
import { renderStateWithKey, reducerPipe } from '@/utils/modelUtils';

const renderedState = renderStateWithKey(['setFundPassword', 'resetFundPassword']);

export default {
  namespace: 'fundPassword',

  state: renderedState,

  effects: {
    *setFundPassword({ payload, callback }, { call, put }) {
      yield put({ type: 'operateRequest', stateKey: 'setFundPassword' });
      const res = yield call(setFundPassword, payload);
      if (res.status === 'success') {
        message.success(localeMessage('pages.account.setFundPasswordSuccess'));
        if (callback) callback();
        yield put({ type: 'operateSuccess', stateKey: 'setFundPassword', payload: res.data });
      } else {
        showResponseError(res);
        yield put({ type: 'operateFail', stateKey: 'setFundPassword', payload: res.message });
      }
    },

    *resetFundPassword({ payload, callback }, { call, put }) {
      yield put({ type: 'operateRequest', stateKey: 'resetFundPassword' });
      const res = yield call(resetFundPassword, payload);
      if (res.status === 'success') {
        message.success(localeMessage('pages.account.resetFundPasswordSuccess'));
        if (callback) callback();
        yield put({ type: 'operateSuccess', stateKey: 'resetFundPassword', payload: res.data });
      } else {
        showResponseError(res);
        yield put({ type: 'operateFail', stateKey: 'resetFundPassword', payload: res.message });
      }
    },
  },

  reducers: {
    getDataRequest(state, action) {
      reducerPipe(state, action, 'request');
    },
    getDataSuccess(state, action) {
      reducerPipe(state, action, 'success');
    },
    getDataFail(state, action) {
      reducerPipe(state, action, 'fail');
    },
    operateRequest(state, action) {
      reducerPipe(state, action, 'request');
    },
    operateSuccess(state, action) {
      reducerPipe(state, action, 'success');
    },
    operateFail(state, action) {
      reducerPipe(state, action, 'fail');
    },
  },
};
