/* eslint-disable */

import ws, { READYSTATE } from '@/utils/websocket';
import { getWSHost, debounceWrapper } from '@/utils/utils';

const debounceListen = debounceWrapper(({ dispatch, history, pathname }) => {
  if (/^\/trade\/[A-Z0-9]{1,}_[A-Z]{1,}$/i.test(pathname)) {
    const symbol = pathname.split('/').pop();
    if (ws.io && ws.io.readyState === READYSTATE.OPEN) {
      ws.emit({
        action: 'subscribe',
        symbol,
      });
    } else {
      ws.cleanEvents();
      ws.on('open', () => {
        ws.emit({
          action: 'subscribe',
          symbol,
        });
        dispatch({ type: 'socketIsOnline', status: true });
      });
      ws.on('close', data => {
        console.log('ws closed');
        dispatch({ type: 'socketIsOnline', status: false });
      });

      ws.on('error', error => {
        console.log('ws error', error);
      });

      ws.on('topic_snapshot', data => {
        dispatch({
          type: 'TOPIC_SNAPSHOT',
          data,
        });
      });
      ws.on('topic_prices', data => {
    //  ws.on('topic_prices', data => {
       // 数组第五位 关单的价格15.93 下拉菜单显示的内容, 第六位：成交量
        //data.ETH_USD = [1677565848574, 12.93, 13.93, 14.93, 15.93, 1, 2, 3]
        dispatch({
          type: 'TOPIC_PRICES',
          data,
        });
      });
      ws.on('topic_tick', data => {
        dispatch({
          type: 'TOPIC_TICKS',
          data,
        });
      });
      ws.on('topic_midpoint', data => {
        dispatch({
          type: 'TOPIC_MIDPOINT',
          data,
        });
      });
      ws.on('topic_bar_kk', data => {
        dispatch({
          type: 'TOPIC_BARS',
          data,
        });
      });
      ws.on('topic_order', data => {
        dispatch({
          type: 'UPDATE_ACCOUNTS',
          data,
        });
        dispatch({
          type: 'TOPIC_ORDER',
          data,
        });
      });
      ws.connect(`${getWSHost()}v1/market/notification`);
    }
  }
}, 500);

export default {
  namespace: 'socketModel',
  state: {
    isOnline: false,
  },
  reducers: {
    socketEnd() {
      ws.close();
      ws.cleanEvents();
    },
    socketIsOnline(state, { status }) {
      state.isOnline = status;
    },
    symbolSubscribe(state, { symbol }) {
      ws.emit({
        action: 'subscribe',
        symbol,
      });
    },
  },
  effects: {},
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        debounceListen({ dispatch, history, pathname });
      });
    },
    closeSocket({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (!/^\/trade\/[A-Z]{1,}_[A-Z]{1,}$/i.test(pathname)) {
          dispatch({ type: 'socketEnd' });
        }
      });
    },
  },
};
