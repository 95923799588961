import { message } from 'antd';
import {
  getAvailableMonths,
  getFundReport,
  getMonthlyReport,
  getMonthlyReportHasDetail,
  getMonthlyReportDetail,
} from '@/services/api';
import { renderStateWithKey, reducerPipe } from '@/utils/modelUtils';
import { localeMessage } from '@/utils/utils';

const renderedState = renderStateWithKey(['getMonthlyStatementsData', 'getFundReportData']);

const makeReducers = type => ({
  [`${type}Request`]: (state, action) => {
    reducerPipe(state, action, 'request');
  },
  [`${type}Success`]: (state, action) => {
    reducerPipe(state, action, 'success');
  },
  [`${type}Fail`]: (state, action) => {
    reducerPipe(state, action, 'fail');
  },
});

export default {
  namespace: 'monthlyStatements',

  state: {
    ...renderedState,
    availableMonths: [],
    monthlyReport: null,
    monthlyhasDetail: null,
    monthlyDetail: {},
  },

  effects: {
    *getMonthlyReport({ payload, callback, method }, { call, put }) {
      const res = yield call(getMonthlyReport, payload);
      if (res.status === 'success') {
        yield put({
          type: 'saveMonthlyReport',
          payload: {
            data: res.data,
            message: res.message,
          },
        });
        if (callback) callback();
        if (method) method();
      } else if (method) {
        method();
      }
    },
    *getMonthlyStatements(action, { call, put }) {
      const res = yield call(getAvailableMonths);
      if (res.status === 'success') {
        yield put({
          type: 'saveAvailableMonths',
          payload: res.data,
        });
      }
    },
    *getMonthlyReportHasDetail(_, { call, put }) {
      const res = yield call(getMonthlyReportHasDetail);
      if (res.status === 'success') {
        yield put({
          type: 'setMonthHasDetail',
          payload: res.data,
        });
      }
    },
    *getMonthlyReportDetail({ payload }, { call, put }) {
      const res = yield call(getMonthlyReportDetail, payload);
      if (res.status === 'success') {
        res.data.list.forEach(element => {
          if(element.transactionType){
            element.transactionType = element.transactionType.replace(/\s+/g,"\n")  // 换行替换空格
          }
        });
        yield put({
          type: 'setMonthDetail',
          payload: res.data,
        });
      }
    },
    *getFundReport({ payload }, { call, put }) {
      yield put({ type: 'getDataRequest', stateKey: 'getFundReportData' });
      const res = yield call(getFundReport, payload);
      if (res.status === 'success') {
        yield put({
          type: 'getDataSuccess',
          stateKey: 'getFundReportData',
          payload: res.data,
        });
      } else {
        // message.error('get monthly statements fail');
        message.error(localeMessage('pages.monthlyStatments.status.get_report_fail'));
        yield put({
          type: 'getDataFail',
          stateKey: 'getFundReportData',
          payload: res.message,
        });
      }
    },
  },

  reducers: {
    saveMonthlyReport(state, action) {
      return {
        ...state,
        monthlyReport: action.payload,
      };
    },
    saveAvailableMonths(state, action) {
      return {
        ...state,
        availableMonths: action.payload,
      };
    },
    setMonthHasDetail(state, action) {
      return {
        ...state,
        monthlyhasDetail: action.payload,
      };
    },
    setMonthDetail(state, action) {
      return {
        ...state,
        monthlyDetail: action.payload,
      };
    },
    clearData(state) {
      return {
        ...state,
        monthlyReport: null,
      };
    },
    ...makeReducers('getMonthlyStatements'),
    ...makeReducers('getData'),
  },
};
