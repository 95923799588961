import {
  getAPIKeys,
  createAPIKey,
  editAPIKey,
  deleteAPIKey,
  getAPISecretKey,
} from '@/services/api';
import { ctor } from '@/utils/utils';
import {
  makeState,
  makeReducer,
  makeDealingType,
  makeFailType,
  makeSuccType,
} from '@/utils/modelUtils';

export const KEY_APIKEYS = 'keys';
export const KEY_CREATEKEY = 'createdKey';
export const KEY_UPDATEKEY = 'editedKey';
export const KEY_DELETEKEY = 'deletedKey';

export default {
  namespace: 'apiKey',

  state: {
    ...makeState(KEY_APIKEYS),
    ...makeState(KEY_CREATEKEY),
    ...makeState(KEY_UPDATEKEY),
    ...makeState(KEY_DELETEKEY),
  },

  effects: {
    // eslint-disable-next-line
    *fetchKeys(action, { call, put }) {
      yield put({
        type: makeDealingType(KEY_APIKEYS),
      });

      const response = yield call(getAPIKeys);
      if (response.status === 'success') {
        yield put({
          type: makeSuccType(KEY_APIKEYS),
          payload: response.data,
        });
      } else {
        const msg = response.message || response.code || '-';
        yield put({
          type: makeFailType(KEY_APIKEYS),
          payload: msg,
        });
      }
    },
    *createKey({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};

      yield put({
        type: makeDealingType(KEY_CREATEKEY),
      });

      const response = yield call(createAPIKey, payload);
      if (response.status === 'success') {
        success(response.data || {});
        yield put({
          type: makeSuccType(KEY_CREATEKEY),
          payload: response.data,
        });
      } else {
        const msg = response.message || response.code || '-';
        failure(msg);
        yield put({
          type: makeFailType(KEY_CREATEKEY),
          payload: msg,
        });
      }
    },
    *editKey({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};

      yield put({
        type: makeDealingType(KEY_UPDATEKEY),
      });

      const response = yield call(editAPIKey, payload);
      if (response.status === 'success') {
        success(response.data || {});
        yield put({
          type: makeSuccType(KEY_UPDATEKEY),
          payload: response.data,
        });
      } else {
        const msg = response.message || response.code || '-';
        failure(msg);
        yield put({
          type: makeFailType(KEY_UPDATEKEY),
          payload: msg,
        });
      }
    },
    *deleteKey({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};

      yield put({
        type: makeDealingType(KEY_DELETEKEY),
      });

      const response = yield call(deleteAPIKey, payload);
      if (response.status === 'success') {
        success(response.data || {});
        yield put({
          type: makeSuccType(KEY_DELETEKEY),
          payload: response.data,
        });
      } else {
        const msg = response.message || response.code || '-';
        failure(msg);
        yield put({
          type: makeFailType(KEY_DELETEKEY),
          payload: msg,
        });
      }
    },
    *getSecretKey({ payload, resultHandler }, { call }) {
      const { success = ctor, failure = ctor } = resultHandler || {};

      const response = yield call(getAPISecretKey, payload);
      if (response.status === 'success') {
        success(response.data);
      } else {
        const msg = response.message || response.code || '-';
        failure(msg);
      }
    },
  },

  reducers: {
    ...makeReducer(KEY_APIKEYS),
    ...makeReducer(KEY_CREATEKEY),
    ...makeReducer(KEY_UPDATEKEY),
    ...makeReducer(KEY_DELETEKEY),
  },
};
