/* eslint-disable */
// import { subscribe } from '@/services/homepage';
// import { ctor } from '@/utils/utils';
// import { message } from 'antd';
import get from 'lodash/get';
import size from 'lodash/size';
// import take from 'lodash/take';
import map from 'lodash/map';
import each from 'lodash/each';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { delay } from 'dva/saga';
import Big from 'big.js';

import {
  makeState,
  makeReducer,
  // makeDealingType,
  // makeFailType,
  // makeSuccType,
} from '@/utils/modelUtils';
// import { getMarketPrices } from '@/services/homepage';
import { getOursMarketPrices } from '@/services/trade';
import { getMarketPrices, getNotification } from '@/services/homepage';
// import { getMarketTrades } from '@/services/api';
// import { pathOr } from 'ramda';

export default {
  namespace: 'homepage',
  state: {
    ...makeState('subscribe'),
    displaySymbols: [],
    activeTab: 0,
    symbolTabs: [],
    allPrice: [],
    pricesCache: {},
    notices: [],
  },

  effects: {
    *getNotification({}, { call, put }) {
      try {
        const response = yield call(getNotification);
        if (get(response, 'status') === 'success') {
          yield put({
            type: 'updateNotics',
            data: get(response, 'data', []),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    *getMarketTrades({ payload, callback }, { call, put, take, select }) {
      const { from = 'index' } = payload;
      let currencies;
      while (true) {
        currencies = yield select(state => state.global.currencies);
        if (size(currencies) > 0) {
          break;
        } else {
          yield delay(100);
        }
      }
      yield put({
        type: 'getAllPrice',
        payload: { currencies, from },
        callback,
      });
    },
    *getAllPrice({ payload }, { call, put, select }) {
      try {
        const { currencies, from } = payload;
        // getMarketPrices
        const response = yield call(getOursMarketPrices);
        // const response = yield call(from === 'home' ? getMarketPrices : getOursMarketPrices);
        if (response.status === 'success') {
          const filterUSD = filter(response.data, item => item.quote_currency === 'USD');
          const activeTab = yield select(state => state.homepage.activeTab);
          const symbolTabs = map(filterUSD, item => item.quote_currency);
          // const symbolTabs = map(response.data, item => item.quote_currency);
          const pricesCache = {};

          each(response.data, list => {
            each(list.data, item => {
              const [baseCoin, quoteCoin] = item.symbol.split('_');
              const prices = get(JSON.parse(item.prices), 4, 0);
              const fex = get(find(currencies, { name: quoteCoin }), 'fex');
              const marketPrice = fex
                ? Big(fex)
                    .times(prices)
                    .toFixed(2)
                : '--';
              if (fex) {
                pricesCache[baseCoin] = marketPrice;
              }
            });
          });

          const data = get(
            find(response.data, { quote_currency: symbolTabs[activeTab] }),
            'data',
            []
          ).map(d => ({
            symbol: d.symbol,
            detail: JSON.parse(d.prices),
            bars: map(d.bars, bar => get(JSON.parse(bar), 4, 0)),
          }));
          yield put({
            type: 'updatePricesCache',
            data: pricesCache,
          });
          yield put({
            type: 'updateAllPrice',
            data: response.data,
          });
          yield put({
            type: 'updatePrice',
            payload: data,
          });
          yield put({
            type: 'updateSymbolTabs',
            data: symbolTabs,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    *emptyMarket(_, { put }) {
      yield put({
        type: 'updateAllPrice',
        payload: [],
      });
      yield put({
        type: 'updatePricesCache',
        payload: {},
      });
    },
    // *getAllPrice({ payload, callback }, { call, put, take, select }) {
    //   try {
    //     const { currencies } = payload;
    //     const response = yield call(getMarketPrices);
    //     if (get(response, 'status') === 'success') {
    //       const formattedData = Object.keys(response.data).map(i => {
    //         const val = JSON.parse(response.data[i]);
    //         return {
    //           symbol: i,
    //           amount: val[5],
    //           detail: val,
    //         };
    //       });
    //       const enhancedPrices = formattedData.map(i => {
    //         const matchedBaseCurrencies = currencies.filter(k => k.name === i.symbol.split('_')[0]);
    //         if (matchedBaseCurrencies.length === 0) {
    //           console.error('exist base currency in prices Not included in all currencies!');
    //         }
    //         return {
    //           ...i,
    //           baseCurrency: i.symbol.split('_')[0],
    //           quoteCurrency: i.symbol.split('_')[1],
    //           fex: matchedBaseCurrencies[0].fex,
    //         };
    //       });
    //       let displaySymbols = enhancedPrices.sort((a, b) => b.amount * b.fex - a.amount * a.fex);
    //       if (displaySymbols.length > 5) {
    //         displaySymbols = displaySymbols.slice(0, 5); // 挑选出24小时美元总量前五的symbol
    //       }
    //       if (callback) {
    //         callback(displaySymbols);
    //       }
    //       yield put({
    //         type: 'updatePrice',
    //         payload: displaySymbols,
    //       });
    //     } else {
    //     }
    //   } catch (error) {
    //     console.log(error, '-----------');
    //   }
    // },
    // *subscribe({ payload, resultHandler }, { call, put }) {
    //   const { email } = payload;
    //   const { success = ctor, failure = ctor } = resultHandler || {};
    //   const response = yield call(subscribe, { email });
    //   yield put({
    //     type: makeDealingType('subscribe'),
    //   });
    //   if (response.status === 'success') {
    //     success({ email });
    //     yield put({
    //       type: makeSuccType('subscribe'),
    //       payload: { email },
    //     });
    //   } else {
    //     const error = response.message || response.code || '-';
    //     failure(error);
    //     yield put({
    //       type: makeFailType('subscribe'),
    //       payload: error,
    //     });
    //   }
    // },
  },

  reducers: {
    ...makeReducer('subscribe'),
    updatePrice(state, action) {
      state = {
        ...state,
        displaySymbols: action.payload,
      };
      return state;
    },
    updateNotics(state, { data }) {
      return {
        ...state,
        notices: data,
      };
    },
    updateSymbolTabs(state, { data }) {
      return {
        ...state,
        symbolTabs: data,
      };
    },
    updateActiveTab(state, { activeTab }) {
      state.displaySymbols = state.allPrice[activeTab].data.map(d => ({
        symbol: d.symbol,
        detail: JSON.parse(d.prices),
        bars: map(d.bars, bar => get(JSON.parse(bar), 4, 0)),
      }));
      state.activeTab = activeTab;
      // return {
      //   ...state,
      //   activeTab,
      //   displaySymbols: get(state, ['allPrice', activeTab], []),
      // };
    },
    updateAllPrice(state, { data }) {
      return {
        ...state,
        allPrice: data,
      };
    },
    updatePricesCache(state, { data }) {
      return {
        ...state,
        pricesCache: data,
      };
    },
  },
};
