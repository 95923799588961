import get from 'lodash/get';
import keys from 'lodash/keys';
import has from 'lodash/has';
import merge from 'lodash/merge';
import { message } from 'antd';
import { formatMessage } from 'umi/locale';
import moment from 'moment-timezone';

import WriteFile from '@/utils/downloadExcel';
import { paramStringify } from '@/utils/url';
import { getOrders, cancelOrders } from '@/services/orders';

export default {
  namespace: 'openOrderPage',
  state: {
    getOrders: {
      data: null,
      error: null,
    },
  },
  effects: {
    *getOrders({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });

        if (!has(payload, 'isFinalStatus')) merge(payload, { isFinalStatus: false });
        const response = yield call(getOrders, paramStringify(payload));

        yield put({
          type: 'updateOrders',
          payload: response.data,
        });
      } catch (err) {
        yield put({
          type: 'handleError',
          payload: err,
        });
      }
    },
    *cancelOrder({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });
        const response = yield call(cancelOrders, payload);
        if (response.status === 'success') {
          payload.callback();
          message.success(formatMessage({ id: 'menu.result.success' }));
        } else {
          payload.callback();
          message.error(response.message || response.code);
        }
      } catch (err) {
        payload.callback();
        message.error(err);
      }
    },
    *downloadOrders({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });
        const response = yield call(getOrders, paramStringify(payload));
        const orders = get(response, 'data.items', []);
        // Use table render data fn or fallback
        const historyList = (payload && payload.renderDataFn(orders)) || orders;
        const headers = keys(get(historyList, 0, null));
        WriteFile(
          `${moment(payload.start).format('YYYYMMDD')}-${moment(payload.end).format(
            'YYYYMMDD'
          )}__open-orders`,
          headers,
          historyList
        );
        yield put({
          type: 'downloadFinish',
          payload: true,
        });
      } catch (err) {
        console.log('Error: ', err);
        yield put({
          type: 'handleError',

          payload: err,
        });
      }
    },
  },
  reducers: {
    updateOrders(state, { payload }) {
      return {
        ...state,
        data: payload,
        fetching: false,
      };
    },
    handleError(state, { payload }) {
      return {
        ...state,
        data: {},
        error: payload,
        fetching: false,
      };
    },
    fetching(state, { payload }) {
      return {
        ...state,
        fetching: payload,
      };
    },
    downloadFinish(state) {
      return {
        ...state,
        fetching: false,
      };
    },
  },
};
