import { getBlockTradingEnableRfqApi, getQuoteApi, getRfqOrderApi, getRfqSymbolsApi, getUserProfileLimitApi, getIntervalGetQuoteApi,getSuitabilityDataApi,
  getSubmitSuitabbilityApi,
  getSuitabilityCheckStatusApi
} from '@/services/blockTrading';
import { showResponseError, swap } from '@/utils/utils';
import { replace } from 'lodash'
export default {
  namespace: 'blockTrading',

  state: {
    enableRfq: undefined,
    customerQuoteData: undefined,
    customerOrderData: undefined,
    symData: undefined,
    limitData: undefined,
    btnTxt: undefined,
    suitabilityData:undefined,
    suitabilityStatus:false,
    scoreText: '',
    score: 0
  },

  effects: {
    *getBlockTradingEnableRfq({ meta }, { call, put }) {
      // 获取开关
      const switchData = yield call(getBlockTradingEnableRfqApi);
      // // // 获取币值对
      const SymbolselectData = yield call(getRfqSymbolsApi)
      // // // 获取限制额度
      const limitData = yield call(getUserProfileLimitApi)


      if (switchData.status === 'success') {
      yield put({
        type: 'setBlockTradingEnable',
        payload: {
          "enableRfq":switchData.data.enableRfq
        }
      });
      } else {
        showResponseError(switchData);
      }

      if (SymbolselectData.status === 'success') {
        // 需要处理数据
        let sym = []
        let index = 0
       

        SymbolselectData.data.forEach((item) => {
          let label = replace(item.symbol, '-', '/')
          let sbl = replace(item.symbol, '-USD', '')
          sym.push({
            "symbol": item.symbol,
            "label": label,
            "sym": sbl,
            "index":index
          })
        })
        
        sym.forEach((item, i )=>{
          if(item.symbol === 'BTC-USD') index = i;
        })

        sym = swap(sym, 0, index )


        // 默认选中的初始值
        yield put({
          type: 'initSelectSymVle',
          payload: sym[0].sym
        });

        // 所有的币值对
        yield put({
          type: 'SaveSymbolselectData',
          payload: sym
        });


      } else {
        showResponseError(SymbolselectData);
      }

      if (limitData.status === 'success') {
        yield put({
          type: 'SavelimitData',
          payload: {
            ...limitData.data
          }
        });
      } else {
        showResponseError(SymbolselectData);
      }




    },
    *getLimitData({ meta }, { call, put }) {
      const limitData = yield call(getUserProfileLimitApi)
      if (limitData.status === 'success') {
        yield put({
          type: 'SavelimitData',
          payload: {
            ...limitData.data
          }
        });
      }
     
    },
    *getSuitabilityData({ meta }, { call, put }) {
      const suitabilityData = yield call(getSuitabilityDataApi)
      if (suitabilityData.status === 'success') {
        yield put({
          type: 'SaveSuitabilityData',
          payload: [
            ...suitabilityData.data
          ]
        });
      }
    },
    *getSuitabilityCheckStatus({ data, cb }, { call, put }) {
      const suitabilityStatusData = yield call(getSuitabilityCheckStatusApi,data)
      if (suitabilityStatusData.status === 'success') {
        yield put({
          type: 'SaveSuitabilityCheckData',
          payload: suitabilityStatusData.data
        });
        if(!suitabilityStatusData.data.check)cb()
      }
    },

    *intervalGetQuote({ mate }, { call, put }) {
      const result = yield call(getIntervalGetQuoteApi, mate.rfqid);
      yield put({
        type: "customerQuoteDataInterval",
        payload: {
          ...result.data.customerQuoteResponseData[0]
        }
      })
    },
    *getQuote({ data, datasSuccess, dataFail }, { call, put }) {
      const res = yield call(getQuoteApi, data);
      if (res.status === 'success') {
        yield put({
          type: 'customerQuoteData',
          payload: {
            ...res.data.customerQuoteResponseData[0]
          }
        });
        datasSuccess()
      } else {
        showResponseError(res);
        yield put({
          type: 'customerQuoteData',
          payload: {}
        });
        dataFail()

      }
    },
    *getOrder({ data, datasSuccess, dataFail }, { call, put }) {
      const res = yield call(getRfqOrderApi, data);
      if (res.status === 'success') {
        yield put({
          type: 'customerOrderData',
          payload: {
            ...res.data.data[0]
          }
        });
        datasSuccess()
      } else {
        showResponseError(res);
        dataFail()
      }
    },
    *dispatchSuitabilityForm({ data, dataSuccess, dataFail }, { call, put }) {
      const res = yield call(getSubmitSuitabbilityApi, data);
      if (res.status === 'success') {
        dataSuccess()
      } else {
        showResponseError(res);
        // dataFail()
      }
    },
  },

  reducers: {
    setBlockTradingEnable(state, action) {
      return {
        ...state,
        enableRfq: action.payload.enableRfq,
      };
    },
    SaveSymbolselectData(state, action) {
      return {
        ...state,
        symData: action.payload
      }
    },
    SavelimitData(state, action) {
      return {
        ...state,
        limitData: {
          ...action.payload
        }
      }
    },
    SaveSuitabilityData(state, action) {
      return {
        ...state,
        suitabilityData: [
          ...action.payload
        ]
      }
    },
    SaveSuitabilityCheckData(state, action) {
      return {
        ...state,
        suitabilityStatus: action.payload.check,
        scoreText: action.payload.scoreText,
        score: action.payload.score
      }
    },
    
    initSelectSymVle(state, action) {
      return {
        ...state,
        btnTxt: action.payload
      }
    },
    customerQuoteData(state, action) {
      return {
        ...state,
        customerQuoteData: {
          ...action.payload
        }
      };
    },
    customerQuoteDataInterval(state, action) {
      return {
        ...state,
        customerQuoteData: {
          ...action.payload
        }
      };
    },
    clearQuote(state) {
      return {
        ...state,
        customerQuoteData: undefined
      }
    },
    customerOrderData(state, action) {
      return {
        ...state,
        customerOrderData: {
          ...action.payload
        }
      }
    }
  },
};
