import CryptoJS from 'crypto-js';
import { forgotPwd, reSendResetCode, resetPwd, updatePwd, changePwd } from '@/services/api';

export default {
  namespace: 'password',

  state: {
    email: '',
  },

  effects: {
    *forgot({ payload, meta }, { call }) {
      const email = payload.email.toLocaleLowerCase();
      const { grecaptcha } = payload;
      const data = {
        email,
        grecaptcha,
      };

      const response = yield call(forgotPwd, data);
      if (response.status === 'success') {
        meta.success();
      } else {
        meta.fail(response);
      }
    },

    *reSendCode({ payload, meta }, { call }) {
      const response = yield call(reSendResetCode, payload);
      if (response.status === 'success') {
        meta.success();
      } else {
        meta.fail(response);
      }
    },

    *reset({ payload, meta }, { call, select }) {
      let email = yield select(state => state.password.email);
      email = decodeURIComponent(email || payload.email);
      const passwordCrypto = CryptoJS.HmacSHA256(
        payload.password,
        payload.password || ''
      ).toString();
      const data = {
        email,
        vcode: payload.code,
        newPassword: passwordCrypto,
      };
      const response = yield call(resetPwd, data);
      if (response.status === 'success') {
        meta.success();
      } else {
        meta.fail(response);
      }
    },
    *change({ payload, meta }, { call }) {
      const oldPasswordCrypto = CryptoJS.HmacSHA256(
        payload.oldPassword,
        payload.oldPassword || ''
      ).toString();
      const newPasswordCrypto = CryptoJS.HmacSHA256(
        payload.newPassword,
        payload.newPassword || ''
      ).toString();
      const data = {
        oldPassword: oldPasswordCrypto,
        newPassword: newPasswordCrypto,
      };
      const response = yield call(changePwd, data);
      if (response.status === 'success') {
        meta.success();
      } else {
        meta.fail(response);
      }
    },

    *update({ payload, meta }, { call, put }) {
      const email = decodeURIComponent(payload.email);
      const data = {
        newPassword: CryptoJS.HmacSHA256(payload.newPassword, email).toString(),
        oldPassword: CryptoJS.HmacSHA256(payload.oldPassword, email).toString(),
      };

      const response = yield call(updatePwd, data);
      if (response.status === 'success') {
        yield put({
          type: 'auth/emptyTokenAndProfile',
        });
        meta.success();
      } else {
        meta.fail(response);
      }
    },
  },

  reducers: {
    email(state, action) {
      return {
        ...state,
        email: action.payload,
      };
    },
  },
};
