import { message } from 'antd';
import {
  getAvailableMonths,
  getInvoiceInfo,
  getInvoiceInfoList,
  getInvoiceMonthStatus
} from '@/services/api';
import { localeMessage } from '@/utils/utils';

export default {
  namespace: 'invoice',

  state: {
    availableMonths: [],
    list: [],
    info: null,
    monthStatus: null
  },

  effects: {
    *getInvoiceAvailableMonths(action, { call, put }) {
      const res = yield call(getAvailableMonths);
      if (res.status === 'success') {
        yield put({
          type: 'setAvailableMonths',
          payload: res.data,
        });
      }
    },
    *getInvoiceInfo({ payload, callback }, { call, put }) {
      const res = yield call(getInvoiceInfo, payload);
      if (res.status === 'success') {
        yield put({
          type: 'setInfo',
          payload: {
            data: res.data,
            message: res.message,
          },
        });
        if (callback) callback(res.data.invoiceNumber);
      }
    },
    *getInvoiceList({ payload, callback }, { call, put }) {
      const res = yield call(getInvoiceInfoList, payload);
      if (res.status === 'success') {
        yield put({
          type: 'setList',
          payload: res.data,
        });
        if (callback) callback();
      }
    },
    *getInvoiceMonthStatus(_, { call, put }) {
      const res = yield call(getInvoiceMonthStatus);
      if (res.status === 'success') {
        yield put({
          type: 'setMonthStatus',
          payload: res.data,
        });
      }
    },
  },

  reducers: {
    setAvailableMonths(state, action) {
      return {
        ...state,
        availableMonths: action.payload,
      }
    },
    setInfo(state, action) {
      return {
        ...state,
        info: action.payload,
      };
    },
    setList(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    setMonthStatus(state, action) {
      return {
        ...state,
        monthStatus: action.payload,
      };
    },
    clearData(state) {
      return {
        ...state,
        info: null,
      };
    },
  },
};
