import { message } from 'antd';
import { formatMessage } from 'umi/locale';
import * as api from '@/services/api';
import { renderStateWithKey, reducerPipe } from '@/utils/modelUtils';

const renderedState = renderStateWithKey([
  'getHistoryData',
  'changeFundPasswordForTrade',
  'controlEmailNotification',
  'getClientLimit',
]);

export default {
  namespace: 'account',

  state: renderedState,

  effects: {
    *getData({ stateKey }, { call, put }) {
      yield put({ type: 'getDataRequest', stateKey });
      const res = yield call(api[stateKey]);
      if (res.status === 'success') {
        yield put({ type: 'getDataSuccess', stateKey, payload: res.data });
      } else {
        yield put({ type: 'getDataFail', stateKey, payload: res.message });
      }
    },

    *operate({ stateKey, payload, callback }, { call, put }) {
      yield put({ type: 'operateRequest', stateKey });
      const res = yield call(api[stateKey], payload);
      if (res.status === 'success') {
        message.success(formatMessage({ id: 'account.operate.successfully' }));
        if (callback) callback();
        yield put({ type: 'operateSuccess', stateKey });
      } else {
        /*
        if (res.code && ['GA_INVALID'].includes(res.code)) {
          message.error(formatMessage({ id: `account.operate.${res.code}` }));
        } else {
          message.error(formatMessage({ id: 'account.operate.error' }));
        }
        */

        if (res.code !== undefined) {
          message.error(res.message);
        } else {
          message.error(formatMessage({ id: 'account.operate.error' }));
        }
        yield put({ type: 'operateFail', stateKey, payload: res.message });
      }
    },
  },

  reducers: {
    getDataRequest(state, action) {
      reducerPipe(state, action, 'request');
    },
    getDataSuccess(state, action) {
      reducerPipe(state, action, 'success');
    },
    getDataFail(state, action) {
      reducerPipe(state, action, 'fail');
    },
    operateRequest(state, action) {
      reducerPipe(state, action, 'request');
    },
    operateSuccess(state, action) {
      reducerPipe(state, action, 'success');
    },
    operateFail(state, action) {
      reducerPipe(state, action, 'fail');
    },
  },
};
