import { proofAddressUpload } from '@/services/kyc';
import { ctor } from '@/utils/utils';

import {
  makeState,
  makeReducer,
  makeDealingType,
  makeFailType,
  makeSuccType,
} from '@/utils/modelUtils';

export const NS_UPLOAD = 'uploaded';
export const NS_UPLOAD_FILES = 'uploadedFiles';

// const getFormData = (file, type = 1) => {
//   const formData = new FormData();
//   formData.append('file', file);
//   formData.append('type', type);
//   return formData;
// };
//
// const parseFilesResponse = (filesResponse, files) => {
//   let hasFailCount = 0;
//   const succStatus = 'success';
//   const count = filesResponse.length;
//   const errors = [];
//
//   const seqResult = filesResponse.map((r, i) => {
//     const { status, code, message, data } = r;
//     let item;
//     if (status !== succStatus) {
//       hasFailCount += 1;
//       const error = code || message || '<error>';
//       item = { error, fileName: files[i].name };
//       errors.push(error);
//     } else {
//       item = (data && data[0]) || { fileName: files[i].name };
//     }
//     return item;
//   });
//
//   return {
//     isFailure: hasFailCount === count,
//     result: seqResult,
//     errors,
//   };
// };

export default {
  namespace: 'kycFileUploader',

  state: {
    ...makeState(NS_UPLOAD),
    ...makeState(NS_UPLOAD_FILES),
  },

  effects: {
    *upload({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};

      yield put({ type: makeDealingType(NS_UPLOAD) });

      const response = yield call(proofAddressUpload, payload);
      if (response.status === 'success') {
        const data = response.data || true;
        success(data);
        yield put({
          type: makeSuccType(NS_UPLOAD),
          payload: data,
        });
      } else {
        const error = response.code || response.message || '-';
        failure(error);
        yield put({
          type: makeFailType(NS_UPLOAD),
          payload: error,
        });
      }
    },
  },

  reducers: {
    ...makeReducer(NS_UPLOAD),
    ...makeReducer(NS_UPLOAD_FILES),
  },
};
