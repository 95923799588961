import CryptoJS from 'crypto-js';
import trim from 'lodash/trim';
import { sendEmailCode, reSendEmailCode, register, registEnable } from '@/services/api';

export default {
  namespace: 'register',

  state: {
    registEnable: false,
  },

  effects: {
    *sendCode({ payload, meta }, { call }) {
      const email = payload.email.toLocaleLowerCase();
      const {
        registerType,
        // username,
        grecaptcha,
        companyName = null,
        firstName = null,
        lastName = null,
        ageAgreement,
        userAgreement,
        userAgreementTime,
        ageAgreementTime,
      } = payload;
      const data =
        registerType === 'INDIVIDUAL'
          ? {
              email: trim(email),
              // username: trim(username),
              firstName: trim(firstName),
              lastName: trim(lastName),
              registerType,
              grecaptcha,
              password: CryptoJS.HmacSHA256(payload.password, payload.password).toString(),
              refId: payload.referralId,
              ageAgreement,
              userAgreement,
              userAgreementTime,
              ageAgreementTime,
            }
          : {
              email: trim(email),
              // username: trim(username),
              companyName: trim(companyName),
              registerType,
              grecaptcha,
              password: CryptoJS.HmacSHA256(payload.password, payload.password).toString(),
              refId: payload.referralId,
              ageAgreement,
              userAgreement,
              userAgreementTime,
              ageAgreementTime,
            };

      const response = yield call(sendEmailCode, data);
      if (response.status === 'success') {
        meta.success();
      } else {
        meta.fail(response);
      }
    },

    *reSendCode({ payload, meta }, { call }) {
      const email = payload.email.toLocaleLowerCase();
      const data = {
        email,
      };

      const response = yield call(reSendEmailCode, data);
      if (response.status === 'success') {
        meta.success();
      } else {
        meta.fail(response);
      }
    },

    *register({ payload, meta }, { call }) {
      const response = yield call(register, payload);
      if (response.status === 'success') {
        // localStorage.setItem('email', payload.email);
        // eslint-disable-next-line no-underscore-dangle
        // yield window.g_app._store.dispatch({
        //   type: 'auth/saveTokenToStateAndLocalStorage',
        //   payload: response,
        // });
        // eslint-disable-next-line no-underscore-dangle
        // const profileRes = yield window.g_app._store.dispatch({
        //   type: 'auth/fetchProfile',
        // });
        // if (profileRes.status === 'success') {
        // reloadAuthorized();
        localStorage.removeItem('codeExpiredTime');
        localStorage.removeItem('registerInfo');
        meta.success();
        // } else {
        //   meta.fail(response);
        // }
      } else {
        meta.fail(response);
      }
    },

    *directRegister({ payload, meta }, { call }) {
      const response = yield call(register, payload);
      if (response.status === 'success') {
        // localStorage.setItem('email', payload.email);
        // eslint-disable-next-line no-underscore-dangle
        // yield window.g_app._store.dispatch({
        //   type: 'auth/saveTokenToStateAndLocalStorage',
        //   payload: response,
        // });
        // eslint-disable-next-line no-underscore-dangle
        // const profileRes = yield window.g_app._store.dispatch({
        //   type: 'auth/fetchProfile',
        // });
        // if (profileRes.status === 'success') {
        // reloadAuthorized();
        localStorage.removeItem('codeExpiredTime');
        localStorage.removeItem('registerInfo');
        // }
      } else {
        if (localStorage.getItem('MATRIX_TOKEN')) {
          window.location.href = '/';
        } else {
          // eslint-disable-next-line no-underscore-dangle
          yield window.g_app._store.dispatch({
            type: 'auth/emptyTokenAndProfile',
            idDirect: 'no',
          });
        }
      }
      meta(response.status);
    },
    *fetchRegistEnable({}, { call, put }) {
      const response = yield call(registEnable);
      if (response.status === 'success') {
        yield put({
          type: 'updateRegistEnable',
          payload: response.data,
        });
      }
    },
  },

  reducers: {
    updateRegistEnable(state, { payload }) {
      return {
        ...state,
        registEnable: payload,
      };
    },
  },
};
