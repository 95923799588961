export const isNodeEnvAs = id => process.env.NODE_ENV === id;

export const PROD_ENV = isNodeEnvAs('production');

// Regard as development when not production mode
export const DEV_ENV = !PROD_ENV;

export const useIntlOrDefaultFallback = (intl, id, defaultMessage) => {
  const { messages = null } = intl || {};
  return (messages && messages[id]) || defaultMessage;
};

export const scrollToTop = (ele = document.documentElement, scrolling = true) => {
  if (ele) {
    if (scrolling) {
      ele.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      ele.scrollIntoView();
    }
  }
};

export const scrollToTopAsync = (ele = document.documentElement, timeout = 0, scrolling = true) => {
  setTimeout(() => scrollToTop(ele, scrolling), timeout);
};

export const hasFormError = errors =>
  typeof errors === 'object' ? Object.keys(errors).some(key => errors[key] !== undefined) : false;
