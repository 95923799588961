import { delay } from 'dva/saga';
// import { formatMessage } from 'umi-plugin-react/locale';

import { bookOrder, getSymbolFee } from '@/services/orderBook';
import { ctor, showResponseError } from '@/utils/utils';
import {
  makeState,
  makeReducer,
  makeDealingType,
  makeFailType,
  makeSuccType,
} from '@/utils/modelUtils';

export const DEFAULT_TAKER = 0.002;
export const DEFAULT_MAKER = 0.001;

const getSafeSymbolTradeFee = current => {
  const defaultFee = { taker: DEFAULT_TAKER, maker: DEFAULT_MAKER };

  if (!current) return defaultFee;

  return {
    ...defaultFee,
    ...current,
  };
};

/*
// NOTE: do not use constant, as message is configured by async
// eslint-disable-next-line arrow-body-style
const getDictMessage = () => {
  return {
    USER_CANNOT_TRADE: formatMessage({
      id: 'pages.trade.bookOrder.code.USER_CANNOT_TRADE',
    }),
  };
};
*/

export const RESET_PRICE = undefined;

export const ORDER_BUY = 'orderBuy';
export const ORDER_SELL = 'orderSell';
export const SYMBOL_TRADE_FEE = 'symbolTradeFee';

export default {
  namespace: 'tradeOrderBook',
  state: {
    ...makeState(ORDER_BUY),
    ...makeState(ORDER_SELL),
    ...makeState(SYMBOL_TRADE_FEE, false, getSafeSymbolTradeFee()),
    newPrice: RESET_PRICE,
  },

  effects: {
    *buy({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};
      const response = yield call(bookOrder, payload);
      // const MESSAGES = getDictMessage();

      yield put({
        type: makeDealingType(ORDER_BUY),
      });

      if (response.status === 'success') {
        success(payload);
        yield put({
          type: makeSuccType(ORDER_BUY),
          payload,
        });
      } else {
        /*
        const error =
          MESSAGES[response.message] ||
          MESSAGES[response.code] ||
          response.message ||
          response.code ||
          '-';
          */
        const error = response.message || response.code || '-';

        failure(error);

        // showResponseError(response);

        yield put({
          type: makeFailType(ORDER_BUY),
          payload: error,
        });
      }
    },
    *sell({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};
      const response = yield call(bookOrder, payload);
      // const MESSAGES = getDictMessage();

      yield put({
        type: makeDealingType(ORDER_SELL),
      });

      if (response.status === 'success') {
        success(payload);
        yield put({
          type: makeSuccType(ORDER_SELL),
          payload,
        });
      } else {
        /*
        const error =
          MESSAGES[response.message] ||
          MESSAGES[response.code] ||
          response.message ||
          response.code ||
          '-';
          */
        const error = response.message || response.code || '-';

        failure(error);

        // showResponseError(response);

        yield put({
          type: makeFailType(ORDER_SELL),
          payload: error,
        });
      }
    },
    *getSymbolTradeFee({ payload }, { call, put }) {
      const response = yield call(getSymbolFee, payload);
      yield put({
        type: makeDealingType(SYMBOL_TRADE_FEE),
      });
      if (response.status === 'success') {
        yield put({
          type: makeSuccType(SYMBOL_TRADE_FEE),
          payload: getSafeSymbolTradeFee(response.data),
        });
      } else {
        showResponseError(response);
        yield put({
          type: makeSuccType(SYMBOL_TRADE_FEE),
          payload: getSafeSymbolTradeFee(),
        });
      }
    },
    *updatePrice({ payload }, { put, call }) {
      yield put({
        type: 'setPrice',
        payload: RESET_PRICE,
      });
      yield call(delay, 10);
      yield put({
        type: 'setPrice',
        payload,
      });
    },
  },

  reducers: {
    ...makeReducer(ORDER_BUY),
    ...makeReducer(ORDER_SELL),
    ...makeReducer(SYMBOL_TRADE_FEE),
    setPrice(state, { payload }) {
      return {
        ...state,
        newPrice: payload,
      };
    },
  },
};
