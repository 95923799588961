import request from '@/utils/request';
import { getWSHost } from '@/utils/utils';

export async function getMarketPrices() {
  return request('https://ticker.matrix.co/v1/market/prices', { prefix: '' });
}
export async function getNotification() {
  return request('/v1/news/info');
}

export const wsURL = `${getWSHost()}v1/market/notification`;
