import { stringify } from 'qs';
import request from '@/utils/request';

export async function signIn(data) {
  return request(`/v1/sign-in`, {
    method: 'POST',
    data,
  });
}
export async function gaSignIn(data) {
  return request(`/v1/ga-sign-in`, {
    method: 'POST',
    data,
  });
}

export async function seTimezone(data) {
  return request(`/v1/json/time-zone`, {
    method: 'POST',
    data,
  });
}

export async function signOut(data) {
  return request(`/v1/sign-out`, {
    method: 'POST',
    data,
  });
}

export async function sendEmailCode(data) {
  return request(`/v1/vcode/register`, {
    method: 'POST',
    data,
  });
}
export async function reSendEmailCode(data) {
  return request(`/v1/vcode/register-resend`, {
    method: 'POST',
    data,
  });
}
export async function reSendResetCode(data) {
  return request(`/v1/json/find-back-password-resend`, {
    method: 'POST',
    data,
  });
}
export async function resetPwd(data) {
  return request(`/v1/json/find-back-password/reset`, {
    method: 'POST',
    data,
  });
}
export async function changePwd(data) {
  return request(`/v1/json/user/change-password`, {
    method: 'POST',
    data,
  });
}
export async function updatePwd(data) {
  return request(`/v1/json/user/change-password`, {
    method: 'POST',
    data,
  });
}
export async function register(data) {
  return request(`/v1/register`, {
    method: 'POST',
    data,
  });
}
export async function forgotPwd(data) {
  return request(`/v1/json/find-back-password`, {
    method: 'POST',
    data,
  });
}

export async function getProfile() {
  return request(`/v1/json/user/profile`);
}

// account

export async function getHistoryData() {
  return request(`/v1/json/user/records`);
}

export async function changeFundPasswordForTrade(params) {
  return request('/v1/fund-password/validtime/', {
    method: 'POST',
    data: params,
  });
}

export async function createOrUpdateAntiFishingCode(params) {
  return request('/v1/antiPhishingCode/createOrUpdate', {
    method: 'POST',
    data: params,
  });
}

export async function controlEmailNotification(params) {
  return request(`/v1/user/edit/notification?${stringify(params)}`);
}

// ga
export async function getGaCode() {
  return request('/v1/google/user/security/getSecret');
}

export async function sendBindGaEmailCode(params) {
  return request(`/v1/vcode/${params.type}-ga`, {
    method: 'POST',
  });
}

export async function bindGa(data) {
  const submitData = data;
  const { type } = submitData;
  delete submitData.type;
  return request(`/v1/google/user/security/${type}GA`, {
    method: 'POST',
    data: submitData,
  });
}

export async function toggleGa(data) {
  return request(`/v1/google/user/security/${data.operateType}`, {
    method: 'POST',
  });
}

export async function getCryptoAssets() {
  return request(`/v1/user/accounts`);
}
export async function getMarketTrades() {
  return request(`/v1/market/trades`);
}

export async function tokenRefresh() {
  return request(`/v1/token/refresh`, {
    method: 'POST',
  });
}

export async function getAPIKeys() {
  return request(`/v1/user/api-keys`);
}

export async function createAPIKey(data) {
  return request(`/v1/user/api-keys`, {
    method: 'POST',
    data,
  });
}

export async function editAPIKey(data) {
  return request(`/v1/user/api-keys/update`, {
    method: 'POST',
    data,
  });
}

export async function deleteAPIKey(data) {
  return request(`/v1/user/api-keys/delete`, {
    method: 'POST',
    data,
  });
}

export async function getAPISecretKey(params) {
  return request(`/v1/user/api-keys/get-api-secret?${stringify(params)}`);
}

export async function getUnReadMsgCount() {
  return request(`/v1/messages/count-unread`);
}

export async function getMessageList(params) {
  return request(`/v1/messages?${stringify(params)}`);
}
export async function getMessageDetail(id) {
  return request(`/v1/messages/${id}`);
}

export async function getDepositedAmount() {
  return request(`/v1/user/deposit/amount`);
}
export async function getDepositAddress(params) {
  return request(`/v1/user/deposit/address-record?${stringify(params)}`);
}
export async function addRefundAddress(data) {
  return request('/v1/user/deposit/addDepositRefundAddress', {
    method: 'POST',
    data,
  });
}
export async function getMinAmount(currency) {
  return request(`/v1/user/deposit/depositMinimumAmount?currency=${currency}`);
}

export async function getWithdrawnAmount() {
  return request(`/v1/user/withdraw/amount`);
}
export async function getWithdrawHistoryList(params) {
  return request(`/v1/user/withdraw/listWithdrawRequest?${stringify(params)}`);
}
export async function getWithdrawData(currency) {
  return request(`/v1/user/withdraw/withdraw_prepare?currency=${currency}`);
}
export async function getWithdrawAddressList(params) {
  if (typeof params === 'string') {
    return request(`/v1/user/withdraw/listWithdrawAddress?currency=${params}&pageSize=100`);
  }
  return request(`/v1/user/withdraw/listWithdrawAddress?${stringify(params)}`);
}
export async function validateWithdrawLimit(params) {
  return request(`/v1/user/withdraw/validateWithdrawAmount?${stringify(params)}`);
}
// export async function withdrawSubmit(params) {
//   return request(`/v1/user/withdraw/submit?${stringify(params)}`, {
//     method: 'POST',
//   });
// }
export async function withdrawSubmit(data) {
  return request(`/v1/user/withdraw/submit`, {
    method: 'POST',
    data,
  });
}
export async function sendWithdrawEmailCode(data) {
  return request('/v1/vcode/withdraw', {
    method: 'POST',
    data,
  });
}

// fund-password
export async function setFundPassword(data) {
  return request('/v1/fund-password', {
    method: 'POST',
    data,
  });
}

export async function resetFundPassword(data) {
  return request('/v1/fund-password', {
    method: 'POST',
    data,
  });
}

export async function registEnable() {
  return request('/v1/isRegister');
}

export async function setFlagInfo(params) {
  return request(`/v1/asserts/set-flag?${stringify(params)}`);
}

export async function getFlagInfo() {
  return request('/v1/asserts/get-flag');
}

export async function getAssetsMarketPrices() {
  return request('/v1/market/prices/all');
}
export async function getPriceIncomeBy24Hours() {
  return request('/v1/user/getPriceIncomeBy24Hours');
}

export async function gaCheck(data) {
  return request(`/v1/ga-check`, {
    method: 'POST',
    data,
  });
}

export async function getClientLimit() {
  return request('/v1/user/limit-rule/get/configured-and-used');
}

export async function getBalance() {
  return request('/v1/user/balance');
}

// monthly report
export async function getAvailableMonths() {
  return request('/v1/fundReport/listReportMonth');
}

export async function getFundReport(params) {
  return request(`/v1/fundReport/userFundReport?${stringify(params)}`);
}

export async function getMonthlyReport(params) {
  return request(`/v1/user/account/monthly/report?${stringify(params)}`);
}

export async function getMonthlyReportHasDetail() {
  return request('/v1/user/account/monthly/report/data');
}

export async function getMonthlyReportDetail(params) {
  return request(`/v1/user/account/monthly/report/detail?${stringify(params)}`);
}

export async function getCountryList() {
  return request('/v1/kyc/country/list');
}

// invoice
export async function getInvoiceInfo(params) {
  return request(`/v1/report/invoice/info?${stringify(params)}`);
}

export async function getInvoiceInfoList(params) {
  return request(`/v1/report/invoice/info/detail?${stringify(params)}`);
}

export async function getInvoiceMonthStatus() {
  return request('/v1/report/invoice/report/detail');
}


export async function cookieChooseSet(data) {
  let { userId, cookieChoose } = data
  return request(`/v1/cookie-choose/${userId}/${cookieChoose}`, {
    method: 'POST',
  });
}

