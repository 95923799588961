export const isZeroValue = str => {
  const val = Number(str);
  return str === 0 || val === 0;
};

export const isMarketPrice = type => {
  if (type === 'MARKET') return true;
  return false;
};

export default {
  isZeroValue,
};
