import { stringify } from 'qs';
import request from '@/utils/request';

//获取用户RFQ开启状态
export async function getBlockTradingEnableRfqApi() {
  return request(`/v1/rfq/user/enableRfq`);
}

//获取用的币值对
export async function getRfqSymbolsApi() {
  return request(`/v1/rfq/symbols`);
}

//获取用的币值对
export async function getUserProfileLimitApi() {
  return request(`/v1/rfq/user/userProfileLimit`);
}
//获取用的币值对
export async function getIntervalGetQuoteApi(rfqid) {
  return request(`/v1/rfq/quote/latest/${rfqid}`);
}

export async function getSuitabilityDataApi() {
  return request(`/v1/manage/kyc/rfq/list`);
}
export async function getSuitabilityCheckStatusApi(data) {
  return request(`/v1/manage/kyc/rfq/check?${stringify(data)}`);
}


export async function getQuoteApi(data) {
  return request('/v1/rfq/quote/', {
    method: 'POST',
    data,
  });
}

export async function getRfqOrderApi(data) {
  return request('/v1/rfq/order', {
    method: 'POST',
    data,
  });
}
export async function getSubmitSuitabbilityApi(data) {
  return request('/v1/manage/kyc/rfq/submit', {
    method: 'POST',
    data,
  });
}




