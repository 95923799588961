import { getMessageList, getMessageDetail } from '@/services/api';
import { showResponseError } from '@/utils/utils';

export default {
  namespace: 'message',

  state: {
    list: {
      status: 'success',
      data: {
        items: [],
        pagination: {
          pageNumber: 1,
          pageSize: 20,
        },
      },
    },
    detail: {},
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(getMessageList, payload);
      if (response.status === 'success') {
        yield put({
          type: 'list',
          payload: response,
        });
      } else {
        showResponseError(response);
      }
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(getMessageDetail, payload);
      if (response.status === 'success') {
        yield put({
          type: 'detail',
          payload: response.data,
        });
        if (!response.data.readed) {
          // eslint-disable-next-line no-underscore-dangle
          yield window.g_app._store.dispatch({
            type: 'global/fetchUnReadMsgCount',
          });
        }
      } else {
        showResponseError(response);
      }
    },
  },
  reducers: {
    list(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    detail(state, action) {
      return {
        ...state,
        detail: action.payload,
      };
    },
  },
};
