/*eslint-disable */
import URL from 'url-parse';
import qs from 'qs';

const replaceSearch = (url, key, values) => {
  if (!(url instanceof URL)) {
    throw new Error('should be URL instance.');
  }

  if (key in url.query) {
    url.query[key] = values;
  }

  return url.toString();
};

const paramStringify = obj => qs.stringify(obj);
const paramParse = strParam => qs.parse(strParam);

export default (url, parse = false) => new URL(url, !!parse);

export { URL, replaceSearch, paramStringify, paramParse };
