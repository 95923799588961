import get from 'lodash/get';
import once from 'lodash/once';
import { getAccounts } from '@/services/user';

const initOnce = once(dispatch => {
  dispatch({
    type: 'updateAccountsBySocket',
  });
});

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {},
    accounts: undefined,
  },
  effects: {
    *getAccounts(_, { put, call, select }) {
      const token = yield select(state => state.auth.token);
      if (token) {
        const response = yield call(getAccounts);
        if (get(response, 'status') === 'success') {
          yield put({ type: 'updateAccounts', accounts: get(response, 'data') });
        }
      }
    },
    // *updateAccountsBySocket(_, { throttle, put }) {
    //   // eslint-disable-next-line
    //   yield throttle(2000, 'UPDATE_ACCOUNTS', function*() {
    //     yield put({
    //       type: 'getAccounts',
    //     });
    //   });
    // },
  },
  reducers: {
    saveCurrentUser(state, action) {
      return { ...state, currentUser: action.payload || {} };
    },
    updateAccounts(state, { accounts }) {
      state.accounts = accounts; // eslint-disable-line no-param-reassign
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        if (/^\/trade/i.test(pathname)) {
          initOnce(dispatch);
        }
        if (
          pathname.startsWith('/assets/fiat') ||
          pathname.startsWith('/assets/crypto') ||
          pathname.startsWith('/password') ||
          pathname.startsWith('/message/list') ||
          pathname.startsWith('/message/detail') ||
          pathname.startsWith('/api')
        ) {
          return;
        }
        dispatch({ type: 'getAccounts' });
      });
    },
  },
};
export default UserModel;
