// Simple defer impl.
export default () => {
  let resolve;
  let reject;

  const promise = new Promise((resolve_, reject_) => {
    resolve = resolve_;
    reject = reject_;
  });

  return {
    resolve,
    reject,
    promise,
  };
};

export const thenableWithResolved = (a = true) => Promise.resolve(a);

export const thenableWithRejected = message => {
  let ex;

  if (ex instanceof Error) {
    ex = message;
  } else {
    ex = new Error(message);
  }

  return Promise.reject(ex);
};
