import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/.umi-production/LocaleWrapper.jsx';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/monthlystatements/detail/:date',
    component: __IS_BROWSER
      ? dynamic({
          loader: () =>
            import(/* webpackChunkName: "p__MonthlyStatements__Detail" */ '../MonthlyStatements/Detail'),
          loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
            .default,
        })
      : require('../MonthlyStatements/Detail').default,
    Routes: [require('../../../routes/PrivateRoute').default],
    exact: true,
  },
  {
    path: '',
    component: __IS_BROWSER
      ? dynamic({
          loader: () =>
            import(/* webpackChunkName: "layouts__DefaultLayout__default" */ '../../layouts/DefaultLayout/default'),
          loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/DefaultLayout/default').default,
    routes: [
      {
        path: '/register/login_code/:email?/:vcode?',
        Routes: [require('../../../routes/RegisterInRoute').default],
        exact: true,
      },
      {
        path: '/password/reset/:email',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "p__Password__Reset" */ '../Password/Reset'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../Password/Reset').default,
        Routes: [require('../../../routes/PrivateRoute').default],
        exact: true,
      },
      {
        path: '/fund-password/set',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "p__FundPassword__Set" */ '../FundPassword/Set'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../FundPassword/Set').default,
        Routes: [require('../../../routes/PrivateRoute').default],
        exact: true,
      },
      {
        path: '/fund-password/reset',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "p__FundPassword__Reset" */ '../FundPassword/Reset'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../FundPassword/Reset').default,
        Routes: [require('../../../routes/PrivateRoute').default],
        exact: true,
      },
      {
        path: '/fund-password/close',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "p__FundPassword__Close" */ '../FundPassword/Close'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../FundPassword/Close').default,
        Routes: [require('../../../routes/PrivateRoute').default],
        exact: true,
      },
      {
        path: '/fund-password/open',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "p__FundPassword__Open" */ '../FundPassword/Open'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../FundPassword/Open').default,
        Routes: [require('../../../routes/PrivateRoute').default],
        exact: true,
      },
      {
        path: '/trade/:symbol?',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "p__Trade" */ '../Trade'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../Trade').default,
        isExact: true,
        exact: true,
      },
      {
        path: '/home',
        redirect: '/',
        exact: true,
      },
      {
        path: '/',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "layouts__DefaultLayout__home" */ '../../layouts/DefaultLayout/home'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/DefaultLayout/home').default,
        exact: true,
        routes: [
          {
            path: '/home',
            redirect: '/',
            exact: true,
          },
          {
            path: '/',
            name: 'homepage',
            icon: 'smile',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__HomePage" */ '../HomePage'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../HomePage').default,
            exact: true,
          },
        ],
      },
      {
        path: '/blockTrading',
        redirect: '/blockTrading/home',
        exact: true,
      },
      {
        path: '/blockTrading',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "layouts__DefaultLayout__home" */ '../../layouts/DefaultLayout/home'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/DefaultLayout/home').default,
        routes: [
          {
            path: '/blockTrading/home',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__BlockTrading" */ '../BlockTrading'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../BlockTrading').default,
            exact: true,
          },
        ],
      },
      {
        path: '/',
        component: __IS_BROWSER
          ? dynamic({
              loader: () =>
                import(/* webpackChunkName: "layouts__DefaultLayout__index" */ '../../layouts/DefaultLayout/index'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/DefaultLayout/index').default,
        routes: [
          {
            path: '/api',
            name: 'api',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__API" */ '../API'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../API').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/kyc',
            name: 'kycNew',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Kyc" */ '../Kyc'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Kyc').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/kyc-new',
            name: 'kycNew',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__KycNew" */ '../KycNew'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../KycNew').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/kyc-individual',
            name: 'kycIndividual',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Kyc__Individual" */ '../Kyc/Individual'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Kyc/Individual').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/kyc-institution',
            name: 'kycInstitution',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Kyc__Institution" */ '../Kyc/Institution'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Kyc/Institution').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/kyc-update',
            name: 'kycUpdate',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__KycUpdate" */ '../KycUpdate'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../KycUpdate').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/kyc-individual-update',
            name: 'kycIndividualUpdate',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__KycUpdate__Individual" */ '../KycUpdate/Individual'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../KycUpdate/Individual').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/kyc-institution-update',
            name: 'kycInstitutionUpdate',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__KycUpdate__Institution" */ '../KycUpdate/Institution'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../KycUpdate/Institution').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/password/update',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Password__Update" */ '../Password/Update'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Password/Update').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/ga/set',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__GA__Set" */ '../GA/Set'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../GA/Set').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/ga/change',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__GA__Change" */ '../GA/Change'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../GA/Change').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/anti-fishing/set',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__AntiFishing__Set" */ '../AntiFishing/Set'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../AntiFishing/Set').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/anti-fishing/update',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__AntiFishing__Update" */ '../AntiFishing/Update'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../AntiFishing/Update').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/account',
            name: 'account',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Account__Account" */ '../Account/Account.js'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Account/Account.js').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/tax-invoice',
            name: 'taxInvoice',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Invoice" */ '../Invoice'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Invoice').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/assets/crypto',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Crypto" */ '../Crypto'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Crypto').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/assets/fiat',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Fiat" */ '../Fiat'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Fiat').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/fiat/history/:currency',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__FiatHistory" */ '../FiatHistory'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../FiatHistory').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/fiat/deposit/:currency',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__FiatDeposit" */ '../FiatDeposit'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../FiatDeposit').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/fiat/withdraw/:currency',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__FiatWithdraw" */ '../FiatWithdraw'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../FiatWithdraw').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/monthly-statements',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__MonthlyStatements" */ '../MonthlyStatements'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../MonthlyStatements').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/message/list',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Message__List" */ '../Message/List'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Message/List').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/message/detail/:id',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__Message__Detail" */ '../Message/Detail'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../Message/Detail').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/deposit/:symbol?',
            name: 'deposit',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__CryptoDeposit" */ '../CryptoDeposit'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../CryptoDeposit').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/withdraw/:symbol?',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__CryptoWithdraw" */ '../CryptoWithdraw'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../CryptoWithdraw').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/withdraw/address/:symbol?',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__CryptoWithdraw__Address" */ '../CryptoWithdraw/Address'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../CryptoWithdraw/Address').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/openOrder',
            name: 'openorder',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__OpenOrderPage" */ '../OpenOrderPage'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../OpenOrderPage').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/orderhistory',
            name: 'orderhistory',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__OrderHistoryPage" */ '../OrderHistoryPage'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../OrderHistoryPage').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/tradehistory',
            name: 'tradehistory',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__TradeHistoryPage" */ '../TradeHistoryPage'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../TradeHistoryPage').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            path: '/blockTrade',
            name: 'blockTrade',
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__BlockTradePage" */ '../BlockTradePage'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../BlockTradePage').default,
            Routes: [require('../../../routes/PrivateRoute').default],
            exact: true,
          },
          {
            component: __IS_BROWSER
              ? dynamic({
                  loader: () =>
                    import(/* webpackChunkName: "p__404" */ '../404'),
                  loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? dynamic({
              loader: () => import(/* webpackChunkName: "p__404" */ '../404'),
              loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    component: __IS_BROWSER
      ? dynamic({
          loader: () => import(/* webpackChunkName: "p__404" */ '../404'),
          loading: require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/components/PageLoading/index')
            .default,
        })
      : require('../404').default,
    exact: true,
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
