import get from 'lodash/get';
import { formatMessage } from 'umi-plugin-react/locale';
import { message } from 'antd';
import { paramStringify } from '@/utils/url';
import { getTrades, getOrders, cancelOrders } from '@/services/orders';

import { TAB_OPEN_ORDER, TAB_HISTORY_ORDER, TAB_TRADE_HISTORY } from '@/components/OrderBox';

const getList = (orders = [], pagination = {}) => ({
  orders,
  pagination,
});

const getTabsInitedData = () => ({
  data: {
    [TAB_OPEN_ORDER]: getList(),
    [TAB_HISTORY_ORDER]: getList(),
    [TAB_TRADE_HISTORY]: getList(),
  },
});

export default {
  namespace: 'orderList',
  state: {
    ...getTabsInitedData(),
    boxType: false,
    fetching: false,
    error: '',
    type: '',
  },
  reducers: {
    updateOrders(state, { payload }) {
      const { activeTab = 0, orders, pagination } = payload;
      // eslint-disable-next-line no-param-reassign
      state.data[activeTab] = getList(orders, pagination);
      return state;
    },
    resetOrders(state) {
      return {
        ...state,
        ...getTabsInitedData(),
        fetching: false,
        error: '',
      };
    },
    handleError(state, { payload }) {
      const { error } = payload;

      return {
        ...state,
        ...getTabsInitedData(),
        error,
        fetching: false,
      };
    },
  },
  effects: {
    *getOrderList({ payload }, { call, put, select }) {
      try {
        const token = yield select(state => state.auth.token);
        if (!token) {
          return;
        }

        const symbol = yield select(state => state.trade.symbol);
        const { activeTab } = payload;
        let typeData = { orderType: 'BUY_LIMIT,BUY_MARKET,SELL_LIMIT,SELL_MARKET' };
        let getDataAPIService = getOrders;

        switch (activeTab) {
          case 0:
            typeData = {
              ...typeData,
              isFinalStatus: false,
              status: 'SUBMITTED,SEQUENCED,PARTIAL_FILLED',
            };
            break;
          case 1:
            typeData = {
              ...typeData,
              isFinalStatus: true,
              status: 'FULLY_FILLED,PARTIAL_CANCELLED,FULLY_CANCELLED',
            };
            break;
          case 2:
            getDataAPIService = getTrades;
            typeData = {
              ...typeData,
              orderType: 'BUY_LIMIT,BUY_MARKET,SELL_LIMIT,SELL_MARKET',
            };
            break;
          default:
            break;
        }

        /*
        const start = moment()
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        const end = moment().format('YYYY-MM-DD');
        */

        // eslint-disable-next-line prefer-const
        let defaultPayload = {
          pageSize: 30,
          pageNumber: 1,
          // start: dateToTimestamp(start), // time stamp
          // end: dateToTimestamp(end), // time stamp
          symbol,
          ...typeData,
        };

        /*
        // `activeTab == 0` should be not filter by time
        if (activeTab !== 0) {
          defaultPayload = {
            ...defaultPayload,
            start: dateToTimestamp(start), // time stamp
            end: dateToTimestamp(end), // time stamp
          };
        }
        */

        const response = yield call(getDataAPIService, paramStringify(defaultPayload));
        const orders = get(response, 'data.items', []);
        const pagination = get(response, 'data.pagination', {});

        yield put({
          type: 'updateOrders',
          payload: {
            orders,
            pagination,
            activeTab: payload.activeTab,
          },
        });
      } catch (e) {
        yield put({
          type: 'handleError',
          payload: {
            error: e,
            activeTab: payload.activeTab,
          },
        });
      }
    },
    *cancelOrder({ payload }, { call }) {
      try {
        const response = yield call(cancelOrders, payload);
        if (response.status === 'success') {
          payload.callback();
          message.success(formatMessage({ id: 'menu.result.success' }));
        } else {
          payload.callback();

          /*
          const { code, message: errorMessage } = response || {};
          const messages = getCodeMessages();
          const msg = messages[code] || messages[errorMessage] || errorMessage || code || 'Failure';
          message.error(msg);
          */
          const { message: errorMessage } = response || {};
          message.error(errorMessage || formatMessage({ id: 'menu.result.fail' }));
        }
      } catch (err) {
        payload.callback();
        message.error(err);
      }
    },
  },
};
