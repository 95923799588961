import map from 'lodash/map';
import get from 'lodash/get';

export default function WriteFile(filename = 'exmaple', finalHeaders = [], data = []) {
  const head = `${finalHeaders.join(',')}\n`;
  const content = map(
    data,
    item => `${map(finalHeaders, title => get(item, title, '')).join(',')}\n`
  );
  const csv = `\ufeff${head}${content.join(' ')}`;
  const csvFile = new Blob([csv], { type: 'text/csv,charset=UTF-8' });
  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(csvFile));
  const date = new Date().getTime();
  link.setAttribute('download', `${filename}${date}.csv`);
  link.click();
}
