import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  app.use(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/node_modules/dva-immer/dist/index.js')());
  app.model({ namespace: 'auth', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/auth.js').default) });
app.model({ namespace: 'common', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/common.js').default) });
app.model({ namespace: 'crypto', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/crypto.js').default) });
app.model({ namespace: 'global', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/global.js').default) });
app.model({ namespace: 'homepage', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/homepage.js').default) });
app.model({ namespace: 'login', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/login.js').default) });
app.model({ namespace: 'orders', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/orders.js').default) });
app.model({ namespace: 'register', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/register.js').default) });
app.model({ namespace: 'setting', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/setting.js').default) });
app.model({ namespace: 'user', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/user.js').default) });
app.model({ namespace: 'websocket', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/models/websocket.js').default) });
app.model({ namespace: 'monthlyStatements', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/MonthlyStatements/models/monthlyStatements.js').default) });
app.model({ namespace: 'password', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Password/models/password.js').default) });
app.model({ namespace: 'fundPassword', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/FundPassword/models/fundPassword.js').default) });
app.model({ namespace: 'book', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Trade/models/book.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Trade/models/index.js').default) });
app.model({ namespace: 'blockTrading', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/BlockTrading/models/blockTrading.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/API/models/index.js').default) });
app.model({ namespace: 'kycFileUploader', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Kyc/models/kycFileUploader.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Kyc/Individual/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Kyc/Institution/models/index.js').default) });
app.model({ namespace: 'ga', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/GA/models/ga.js').default) });
app.model({ namespace: 'antiFishing', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/AntiFishing/models/antiFishing.js').default) });
app.model({ namespace: 'account', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Account/models/account.js').default) });
app.model({ namespace: 'invoice', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Invoice/models/invoice.js').default) });
app.model({ namespace: 'fiat', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Fiat/models/fiat.js').default) });
app.model({ namespace: 'fistHistory', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/FiatHistory/models/fistHistory.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/FiatDeposit/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/FiatWithdraw/models/index.js').default) });
app.model({ namespace: 'message', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/Message/models/message.js').default) });
app.model({ namespace: 'deposit', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/CryptoDeposit/models/deposit.js').default) });
app.model({ namespace: 'withdraw', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/CryptoWithdraw/models/withdraw.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/OpenOrderPage/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/OrderHistoryPage/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/TradeHistoryPage/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/var/lib/jenkins/workspace/CI-Benchmark-matrix-front/src/pages/BlockTradePage/models/index.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
