import get from 'lodash/get';
import keys from 'lodash/keys';
import { message } from 'antd';
import moment from 'moment-timezone';

import WriteFile from '@/utils/downloadExcel';
import { getBlockTradeApi } from '@/services/orders';
import { paramStringify } from '@/utils/url';
import { formatMessage } from 'umi/locale';
export default {
  namespace: 'blockTradePage',
  state: {
    data:[],
  },
  effects: {
    *downloadOrders({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });
        const response = yield call(getBlockTradeApi, payload);
        const orders = get(response, 'data', []);
        // Use table render data fn or fallback
        const historyList = (payload && payload.renderDataFn(orders)) || orders;

        const headers = keys(get(historyList, 0, null));
        WriteFile(
          `${moment(payload.start).format('YYYYMMDD')}-${moment(payload.end).format(
            'YYYYMMDD'
          )}__trade`,
          headers,
          historyList
        );
        yield put({
          type: 'downloadFinish',
          payload: true,
        });
      } catch (err) {
        console.log('Error: ', err);
        yield put({
          type: 'handleError',

          payload: err,
        });
      }
    },
    *getBlockTradeOrders({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });
        let response = yield call(getBlockTradeApi, payload);
        let {side} = payload
        if(response.status === 'success'){
          let vb = ''
          if(side === 'sell'){
              response.data.forEach(item=>{
                vb = item.orderAmt
                item.orderAmt = item.cumAmt
                item.cumAmt = vb
              })
          }
          yield put({ 
            type: 'updateBlockTrade',
            payload: response.data,
          });
        }
      } catch (err) {
        yield put({
          type: 'handleError',
          payload: err,
        });
      }
    },
   
  },
  reducers: {
    updateBlockTrade(state, { payload }) {
      return {
        ...state,
        data: payload,
        fetching: false,
      };
    },
    handleError(state, { payload }) {
      return {
        ...state,
        data: payload,
        fetching: false,
      };
    },
    fetching(state, { payload }) {
      return {
        ...state,
        fetching: payload,
      };
    },
    downloadFinish(state, { payload }) {
      return {
        ...state,
        fetching: false,
      };
    },
    
  },
};
