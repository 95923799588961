/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import request, { extend } from 'umi-request';
import isPlainObject from 'lodash/isPlainObject';
import { getLocale, formatMessage } from 'umi-plugin-react/locale';
import { log, getApiEnv, getHostnameSuffix } from '@/utils/utils';
import { translateResultWithIntlInThenable } from './requestUtil';

/*
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  // 504: '网关超时。',
  504: 'Network Error',
};
*/

const codeMessage = {
  200: formatMessage({
    id: 'request.errors.status.200',
    defaultMessage: '服务器成功返回请求的数据。',
  }),
  201: formatMessage({ id: 'request.errors.status.201', defaultMessage: '新建或修改数据成功。' }),
  202: formatMessage({
    id: 'request.errors.status.202',
    defaultMessage: '一个请求已经进入后台排队（异步任务）。',
  }),
  204: formatMessage({ id: 'request.errors.status.204', defaultMessage: '删除数据成功。' }),
  400: formatMessage({
    id: 'request.errors.status.400',
    defaultMessage: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  }),
  401: formatMessage({
    id: 'request.errors.status.401',
    defaultMessage: '用户没有权限（令牌、用户名、密码错误）。',
  }),
  403: formatMessage({
    id: 'request.errors.status.403',
    defaultMessage: '用户得到授权，但是访问是被禁止的。',
  }),
  404: formatMessage({
    id: 'request.errors.status.404',
    defaultMessage: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  }),
  406: formatMessage({ id: 'request.errors.status.406', defaultMessage: '请求的格式不可得。' }),
  410: formatMessage({
    id: 'request.errors.status.410',
    defaultMessage: '请求的资源被永久删除，且不会再得到的。',
  }),
  422: formatMessage({
    id: 'request.errors.status.422',
    defaultMessage: '当创建一个对象时，发生一个验证错误。',
  }),
  500: formatMessage({
    id: 'request.errors.status.500',
    defaultMessage: '服务器发生错误，请检查服务器。',
  }),
  502: formatMessage({ id: 'request.errors.status.502', defaultMessage: '网关错误。' }),
  503: formatMessage({
    id: 'request.errors.status.503',
    defaultMessage: '服务不可用，服务器暂时过载或维护。',
  }),
  // 504: formatMessage({ id: 'request.errors.status.504', defaultMessage: '网关超时。' }),
  504: formatMessage({ id: 'request.errors.status.504', defaultMessage: '网络超时' }),
};

const STATUS_FAIL = 'fail';
const STATUS_REQUEST_CANCELED = 'requestCanceled';
// const STATUS_SUCC = 'success';

const AUTH_ERROR_CODES = [
  'AUTH_AUTHORIZATION_INVALID',
  'AUTH_AUTHORIZATION_EXPIRED',
  'AUTH_IP_FORBIDDEN',
  'AUTH_SIGNIN_REQUIRED',
];

// 根据auth错误列表{AUTH_ERROR_CODES}判断
const isAuthError = code => AUTH_ERROR_CODES.indexOf(code) > -1;

/**
 * 异常处理程序
 */
const errorHandler = error => {
  console.log('error:', error);
  const { data, response } = error || {};

  if (isPlainObject(data)) {
    return {
      ...data,
      status: STATUS_FAIL,
    };
  }
  if (response) {
    return {
      message: codeMessage[response.status] || response.statusText,
      status: STATUS_FAIL,
    };
  }

  // 连续刷新页面会导致之前的请求被取消
  if (error.toString() === 'TypeError: Failed to fetch') {
    return {
      message: formatMessage({
        id: 'request.errors.message.network_error',
        defaultMessage: 'Network Error',
      }),
      status: STATUS_REQUEST_CANCELED,
    };
  }

  return {
    message: formatMessage({
      id: 'request.errors.message.network_error',
      defaultMessage: 'Network Error',
    }),
    status: STATUS_FAIL,
  };
};

request.interceptors.response.use(async response => {
  const body = await response.clone().json();

  if (body.status === 'fail' && body.code && isAuthError(body.code)) {
    // eslint-disable-next-line no-underscore-dangle
    await window.g_app._store.dispatch({
      type: 'auth/emptyTokenAndProfile',
    });
  }
  return response;
});

/**
 * 配置request请求时的默认参数
 */

export default (...args) => {
  const headers = {};
  try {
    // eslint-disable-next-line no-underscore-dangle
    const token = window.g_app._store.getState().auth.token || localStorage.getItem('MATRIX_TOKEN');
    if (token) {
      headers.Authorization = token;
    }
  } catch (e) {
    log('add authorization header error', e);
  }

  headers['Accept-Language'] = getLocale() === 'zh-CN' ? 'zh-CN' : 'en';

  let prefix = '';
  const local = window.location;
  const { NODE_ENV } = process.env;
  if (local.hostname !== 'localhost' && NODE_ENV !== 'development') {
    // prefix = `https://api-test.${getHostnameSuffix(local.hostname)}`;
    const env = getApiEnv(local);
    prefix = env
      ? `https://gw-${env}.${getHostnameSuffix(local.hostname)}`
      : `https://gw.${getHostnameSuffix(local.hostname)}`;
  }

  const req = extend({
    errorHandler, // 默认错误处理
    credentials: 'omit', // 默认请求是否带上cookie
    headers,
    prefix,
  });
  return req(...args).then(translateResultWithIntlInThenable);
};
