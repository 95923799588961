import {
  getDepositedAmount,
  getDepositAddress,
  addRefundAddress,
  getMinAmount,
} from '@/services/api';
import { showResponseError } from '@/utils/utils';
// import { DEPOSIT_MIN_AMOUNT_MAP } from '@/utils/constants';

export default {
  namespace: 'deposit',

  state: {
    historyList: {
      items: [],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    },
    depositedAmount: 0,
    minAmount: '',
    address: '',
    memoId:'',
    minDeposit:0,
  },

  effects: {
    *getMinAmount({ payload }, { call, put }) {
      const response = yield call(getMinAmount, payload);
      if (response.status === 'success') {
        yield put({
          type: 'minAmount',
          payload: response.data.minAmount,
        });
        yield put({
          type: 'minDeposit',
          payload: response.data.minDeposit,
        });
      } else {
        yield put({
            type: 'minDeposit',
            payload: 0,
          });
        showResponseError(response);
      }
      // yield put({
      //   type: 'minAmount',
      //   payload: DEPOSIT_MIN_AMOUNT_MAP[payload] || 0.001,
      // });
    },
    *getDepositedAmount({ payload }, { call, put }) {
      const response = yield call(getDepositedAmount, payload);
      if (response.status === 'success') {
        yield put({
          type: 'depositedAmount',
          payload: response.data.monthlyDeposit,
        });
      } else {
        showResponseError(response);
      }
    },
    *getDepositAddressAndRecord({ payload }, { call, put }) {
      const response = yield call(getDepositAddress, payload);
      if (response.status === 'success') {
        // 币种:XLM 时, 才会返回 memodId
        yield put({
          type:'memoId',
          payload:response.data.memoId
        })
        
        yield put({
            type: 'address',
            payload: response.data.address,
          });
        yield put({
          type: 'historyList',
          payload: response.data,
        });
      } else {
        showResponseError(response);
        yield put({
          type:'memoId',
          payload:''
        })
        yield put({
          type: 'address',
          payload: '',
        });
       
        yield put({
          type: 'historyList',
          payload: {
            items: [],
            pagination: {
              pageNumber: 1,
              pageSize: 10,
            },
          },
        });
      }
    },
    *refundAddress({ payload, metaSuccess }, { call }) {
      const response = yield call(addRefundAddress, payload);
      if (response.status === 'success') {
        metaSuccess();
      } else {
        showResponseError(response);
      }
    },
  },
  reducers: {
    minAmount(state, action) {
      return {
        ...state,
        minAmount: action.payload,
      };
    },
    depositedAmount(state, action) {
      return {
        ...state,
        depositedAmount: action.payload,
      };
    },
    address(state, action) {
      return {
        ...state,
        address: action.payload,
      };
    },
    minDeposit(state, action) {
      return {
        ...state,
        minDeposit: action.payload,
      };
    },
    memoId(state, action) {
      return {
        ...state,
        memoId: action.payload,
      };
    },
   
    historyList(state, action) {
      return {
        ...state,
        historyList: action.payload,
      };
    },
  },
};
