/**
 *
 * OrderBox
 *
 */

/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'umi-plugin-react/locale';

import OpenOrderBox from '@/components/OpenOrderBox';
import OrderHistoryBox from '@/components/OrderHistoryBox';
import TradeHistoryBox from '@/components/TradeHistoryBox';
import { Header } from './styles';
import Button from './Button';

import Dealing from '@/components/Dealing';

export const TAB_OPEN_ORDER = 0;
export const TAB_HISTORY_ORDER = 1;
export const TAB_TRADE_HISTORY = 2;

export default ({ data, activeTab, changeTabHandler, gotoOrdersPage, loading, cancelHandler }) => {
  const hasMore =
    data && data[activeTab] && data[activeTab].orders && data[activeTab].orders.length > 0;

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        height: '100%',
        width: '100%',
      }}
    >
      <Header>
        <Button
          active={activeTab == TAB_OPEN_ORDER}
          data-index={TAB_OPEN_ORDER}
          onClick={() => {
            changeTabHandler(TAB_OPEN_ORDER);
          }}
        >
          <FormattedMessage id="pages.OrderBox.openOrder" />
        </Button>
        <Button
          style={{ marginLeft: '15px' }}
          active={activeTab == TAB_HISTORY_ORDER}
          data-index={TAB_HISTORY_ORDER}
          onClick={() => {
            changeTabHandler(TAB_HISTORY_ORDER);
          }}
        >
          <FormattedMessage id="pages.OrderBox.historyOrder" />
        </Button>
        <Button
          style={{ marginLeft: '15px' }}
          active={activeTab == TAB_TRADE_HISTORY}
          data-index={TAB_TRADE_HISTORY}
          onClick={() => {
            changeTabHandler(TAB_TRADE_HISTORY);
          }}
        >
          <FormattedMessage id="pages.OpenOrderPage.tradehistory" />
        </Button>
        {loading && (
          <div style={{ marginLeft: 6, display: 'inline-block' }}>
            <Dealing />
          </div>
        )}

        <Button
          style={{
            float: 'right',
            color: '#3CBD72',
            border: 'none',
            background: 'transparent',
            display: hasMore ? 'block' : 'none',
          }}
          onClick={gotoOrdersPage}
        >
          <FormattedMessage id="pages.OrderBox.more" />
        </Button>
      </Header>
      <div style={{ flex: 1, overflow: 'hidden' }}>
        {activeTab == TAB_OPEN_ORDER && (
          <OpenOrderBox
            small
            dark
            orders={data[TAB_OPEN_ORDER].orders}
            loading={false}
            onCancel={cancelHandler}
            isFixedHeader={true}
          />
        )}

        {activeTab == TAB_HISTORY_ORDER && (
          <OrderHistoryBox
            small
            dark
            orders={data[TAB_HISTORY_ORDER].orders}
            loading={false}
            isFixedHeader={true}
          />
        )}

        {activeTab == TAB_TRADE_HISTORY && (
          <TradeHistoryBox
            small
            dark
            orders={data[TAB_TRADE_HISTORY].orders}
            loading={false}
            isFixedHeader={true}
          />
        )}
      </div>
    </div>
  );
};
