import {
  individualInfo,
  individualUpdateInfo,
  individualReviewResult,
  fetchIndividualInfo,
  kycUpdate,
  backToBasic,
  fetchKycDocumentsInfo,
  getDocumentsByBlockIds,
} from '@/services/kyc';
import { getCountryList } from '@/services/api';
import { ctor, isEditData, showResponseError } from '@/utils/utils';
import {
  makeState,
  makeReducer,
  makeDealingType,
  makeFailType,
  makeSuccType,
} from '@/utils/modelUtils';
import { size } from 'lodash';

export const NS_INFO = 'info';
export const NS_FETCH_INFO = 'fetchedInfo';
export const NS_FETCH_UPDATE_INFO = 'fetchedUpdateInfo';

export const NS_KYC_DOCUMENTS = 'kycDocuments';
export const NS_UPDATED_KYC_DOCUMENTS = 'updatedKycDocuments';

export const NS_ID = 'idVerify';
export const NS_JUMIOSUCCESS = 'jumioSuccess';
export const NS_REVIEW_RESULT = 'reviewResult';

export const NS_REQUIRED_DOCUMENT = 'requiredDocument';

export default {
  namespace: 'kycIndividual',

  state: {
    ...makeState(NS_INFO),
    ...makeState(NS_ID),
    ...makeState(NS_FETCH_INFO),

    ...makeState(NS_FETCH_UPDATE_INFO),

    ...makeState(NS_KYC_DOCUMENTS),
    ...makeState(NS_UPDATED_KYC_DOCUMENTS),

    ...makeState(NS_JUMIOSUCCESS),
    ...makeState(NS_REVIEW_RESULT),
    documents: {
      front: null,
      back: null,
      proofAddress: null,
    },
    updateDocuments: {
      front: null,
      back: null,
      proofAddress: null,
    },

    blockKycDocuments: [],
    updatedBlockKycDocuments: [],
    countryList: [],
  },

  effects: {
    *upgrade({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};
      const response = yield call(kycUpdate, payload);
      if (response.status === 'success') {
        success(payload);

        // Refresh profile
        yield put({
          type: 'auth/fetchProfile',
        });
      } else {
        const error = response.message || response.code || '-';
        const { code } = response;

        failure(error, code, response);
      }
    },
    *getCountryList(_, { call, put }) {
      const response = yield call(getCountryList);
      let data =
        response.status === 'success' && response.data ? JSON.stringify(response.data) : [];
      if (size(data)) {
        sessionStorage.setItem('jumio_country', data);
      } else {
        data = sessionStorage.getItem('jumio_country') || [];
      }

      yield put({
        type: 'setCountryList',
        data,
      });
    },
    *backToBasic({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};
      const response = yield call(backToBasic, payload);
      if (response.status === 'success') {
        success(payload);

        // Refresh profile
        yield put({
          type: 'auth/fetchProfile',
        });
      } else {
        const error = response.message || response.code || '-';
        const { code } = response;

        failure(error, code, response);
        showResponseError(response);
      }
    },

    *basicInfo({ payload, resultHandler }, { call, put }) {
      const { success = ctor, failure = ctor } = resultHandler || {};
      const handleAction = isEditData(payload) ? individualUpdateInfo : individualInfo;
      const response = yield call(handleAction, payload);
      if (response.status === 'success') {
        // Refresh profile
        yield put({
          type: 'auth/fetchProfile',
        });

        yield put({
          type: makeSuccType(NS_INFO),
          payload,
        });
        success(payload);
      } else {
        const error = response.message || response.code || '-';
        const { code } = response;

        failure(error, code, response);
        yield put({
          type: makeFailType(NS_INFO),
          payload: error,
        });
      }
    },

    *UpdateDocument({ payload, resultHandler }, { call, put }) {
      const { success = ctor } = resultHandler || {};
      const handleAction = individualUpdateInfo;
      const response = yield call(handleAction, payload);
      if (response.status === 'success') {
        // Refresh profile
        yield put({
          type: 'auth/fetchProfile',
        });

        yield put({
          type: makeSuccType(NS_REQUIRED_DOCUMENT),
          payload: response.data,
        });
        success(response.data);
      } else {
        const error = response.message || response.code || '-';
        yield put({
          type: makeFailType(NS_REQUIRED_DOCUMENT),
          payload: error,
        });
        showResponseError(response);
      }
    },

    *fetchBasicInfo({ payload, resultHandler }, { call, put }) {
      const { success = ctor } = resultHandler || {};
      const { update = false } = payload || {};
      yield put({
        type: makeDealingType(NS_FETCH_INFO),
      });
      const response = yield call(fetchIndividualInfo, payload);
      if (response.status === 'success') {
        if (update) {
          yield put({
            type: makeSuccType(NS_FETCH_UPDATE_INFO),
            payload: response.data.data,
          });
        } else {
          yield put({
            type: makeSuccType(NS_FETCH_INFO),
            payload: response.data.data,
          });
        }
        success(response.data.data);
        return response.data.data;
      }
      const error = response.message || response.code || '-';
      console.log('fetchBasicInfo: ', error);
      yield put({
        type: makeSuccType(NS_FETCH_INFO),
        payload: {},
      });
      return {};
    },

    *updateKYCDocuments({ payload }, { put }) {
      yield put({
        type: makeSuccType(NS_KYC_DOCUMENTS),
        payload: payload.data,
      });
    },

    *updateUpdatedKYCDocuments({ payload }, { put }) {
      yield put({
        type: makeSuccType(NS_UPDATED_KYC_DOCUMENTS),
        ayload: payload.data,
      });
    },

    *fetchKycDocumentsInfo({ payload, resultHandler }, { call, put }) {
      const { success = ctor } = resultHandler || {};
      const { update = false } = payload || {};

      const response = yield call(fetchKycDocumentsInfo, payload);
      if (response.status === 'success') {
        if (update) {
          yield put({
            type: makeSuccType(NS_UPDATED_KYC_DOCUMENTS),
            payload: response.data,
          });
        } else {
          yield put({
            type: makeSuccType(NS_KYC_DOCUMENTS),
            payload: response.data,
          });
        }
        success(response.data);
      } else {
        const error = response.message || response.code || '-';
        if (update) {
          yield put({
            type: makeSuccType(NS_UPDATED_KYC_DOCUMENTS),
            payload: [],
          });
        } else {
          yield put({
            type: makeSuccType(NS_KYC_DOCUMENTS),
            payload: [],
          });
        }
      }
    },

    *getDocumentsByBlockIds({ payload, resultHandler }, { call, put, select }) {
      const { success = ctor } = resultHandler || {};
      const { update = false, data = [] } = payload || {};

      const response = yield call(getDocumentsByBlockIds, data);
      let updatedBlockKycDocuments = yield select(
        state => state.kycInstitution.updatedBlockKycDocuments
      ) || [];
      let blockKycDocuments = yield select(state => state.kycInstitution.blockKycDocuments) || [];
      if (response.status === 'success') {
        if (update) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            const imgIds = (updatedBlockKycDocuments || []).map(img => img.id);
            response.data.forEach(img => {
              const { id } = img;
              if (imgIds.indexOf(id) < 0) {
                updatedBlockKycDocuments = updatedBlockKycDocuments.concat(img);
              }
            });
          }

          yield put({
            type: 'setUpdatedBlockKycDocuments',
            payload: updatedBlockKycDocuments || [],
          });
        } else {
          if (Array.isArray(response.data) && response.data.length > 0) {
            const imgIds = (blockKycDocuments || []).map(img => img.id);
            response.data.forEach(img => {
              const { id } = img;
              if (imgIds.indexOf(id) < 0) {
                blockKycDocuments = blockKycDocuments.concat(img);
              }
            });
          }

          yield put({
            type: 'setBlockKycDocuments',
            payload: blockKycDocuments || [],
          });
        }
        success(response.data);
      } else {
        const error = response.message || response.code || '-';
        // if (update) {
        //   yield put({
        //     type: makeSuccType(NS_UPDATED_KYC_DOCUMENTS),
        //     payload: [],
        //   });
        // } else {
        //   yield put({
        //     type: makeSuccType(NS_KYC_DOCUMENTS),
        //     payload: [],
        //   });
        // }
      }
    },

    *reviewResult({ payload }, { call, put }) {
      yield put({
        type: makeDealingType(NS_REVIEW_RESULT),
      });

      const response = yield call(individualReviewResult, payload);
      if (response.status === 'success') {
        yield put({
          type: makeSuccType(NS_REVIEW_RESULT),
          payload: response.data,
        });
      } else {
        const error = response.message || response.code || '-';
        yield put({
          type: makeFailType(NS_REVIEW_RESULT),
          payload: error,
        });
      }
    },
  },

  reducers: {
    ...makeReducer(NS_INFO),
    ...makeReducer(NS_ID),
    ...makeReducer(NS_JUMIOSUCCESS),
    ...makeReducer(NS_REVIEW_RESULT),
    ...makeReducer(NS_FETCH_INFO),
    ...makeReducer(NS_FETCH_UPDATE_INFO),

    ...makeReducer(NS_KYC_DOCUMENTS),
    ...makeReducer(NS_UPDATED_KYC_DOCUMENTS),

    setDocument(state, { payload }) {
      const { uid, data } = payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          [uid]: data,
        },
      };
    },

    setBlockKycDocuments(state, { payload = [] }) {
      return {
        ...state,
        blockKycDocuments: payload,
      };
    },

    setUpdatedBlockKycDocuments(state, { payload = [] }) {
      return {
        ...state,
        updatedBlockKycDocuments: payload,
      };
    },
    setCountryList(state, { data }) {
      return {
        ...state,
        countryList: data || [],
      };
    },
  },
};
