import has from 'lodash/has';
import get from 'lodash/get';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import { formatMessage } from 'umi/locale';
import moment from 'moment-timezone';

import { message } from 'antd';
import WriteFile from '@/utils/downloadExcel';
import { getOrders, cancelOrders } from '@/services/orders';
import { paramStringify } from '@/utils/url';

export default {
  namespace: 'orderHistoryPage',
  state: {
    getOrders: {
      data: null,
      error: null,
    },
  },
  effects: {
    *downloadOrders({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });

        if (!has(payload, 'isFinalStatus')) merge(payload, { isFinalStatus: true });
        const response = yield call(getOrders, paramStringify(payload));
        const orders = get(response, 'data.items', []);
        const historyList = (payload && payload.renderDataFn(orders)) || orders;
        const headers = keys(get(historyList, 0, null));
        WriteFile(
          `${moment(payload.start).format('YYYYMMDD')}-${moment(payload.end).format(
            'YYYYMMDD'
          )}__orders`,
          headers,
          historyList
        );
        yield put({
          type: 'downloadFinish',
          payload: true,
        });
      } catch (err) {
        console.log('Error: ', err);
        yield put({
          type: 'handleError',

          payload: err,
        });
      }
    },
    *getOrders({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });

        if (!has(payload, 'isFinalStatus')) merge(payload, { isFinalStatus: true });
        const response = yield call(getOrders, paramStringify(payload));

        yield put({
          type: 'updateOrders',
          // payload: {"pagination":{"pageNumber":1,"pageSize":20,"total":2},"items":[{"id":30947764,"chargeQuote":1,"features":0,"sequenceIndex":0,"createdAt":1562586658550,"previousSeqId":30331801,"refOrderId":0,"refSeqId":0,"seqId":0,"updatedAt":0,"userId":0,"version":0,"amount":100.0,"fee":0.0,"filledAmount":0.0,"makerFeeRate":0.001,"price":1.0E-4,"takerFeeRate":0.002,"triggerOn":0.0,"source":"","status":"SEQUENCED","symbol":"ETH/BTC","type":"LIMIT","filledRate":0.0,"averagePrice":0,"side":"BUY"},{"id":24606510,"chargeQuote":1,"features":0,"sequenceIndex":0,"createdAt":1562497681467,"previousSeqId":23990758,"refOrderId":0,"refSeqId":0,"seqId":0,"updatedAt":0,"userId":0,"version":0,"amount":0.369,"fee":0.0,"filledAmount":0.0,"makerFeeRate":0.001,"price":0.0273,"takerFeeRate":0.002,"triggerOn":0.0,"source":"","status":"SEQUENCED","symbol":"ETH/BTC","type":"LIMIT","filledRate":0.0,"averagePrice":0,"side":"SELL"}]}
          payload: response.data,
        });
      } catch (err) {
        yield put({
          type: 'handleError',

          payload: err,
        });
      }
    },
    *cancelOrder({ payload }, { call, put }) {
      try {
        yield put({
          type: 'fetching',
          payload: true,
        });
        const response = yield call(cancelOrders, paramStringify(payload));
        if (response.status === 'success') {
          payload.callback();
          message.success(formatMessage({ id: 'menu.result.success' }));
        } else {
          payload.callback();
          message.error(response.message || response.code);
        }
      } catch (err) {
        payload.callback();
        message.error(err);
      }
    },
  },
  reducers: {
    updateOrders(state, { payload }) {
      return {
        ...state,
        data: payload,
        fetching: false,
      };
    },
    handleError(state, { payload }) {
      return {
        ...state,
        data: payload,
        fetching: false,
      };
    },
    fetching(state, { payload }) {
      return {
        ...state,
        fetching: payload,
      };
    },
    downloadFinish(state) {
      return {
        ...state,
        fetching: false,
      };
    },
  },
};
