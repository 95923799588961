import { message } from 'antd';
import { delay } from 'dva/saga';
import { localeMessage } from '@/utils/utils';
import { getGaCode, sendBindGaEmailCode, bindGa, toggleGa } from '@/services/api';
import { renderStateWithKey, reducerPipe } from '@/utils/modelUtils';

const renderedState = renderStateWithKey([
  'getGaCodeData',
  'sendBindGaEmailCode',
  'bindGa',
  'toggleGa',
]);

export default {
  namespace: 'ga',

  state: renderedState,

  effects: {
    *getGaCode(action, { call, put }) {
      yield put({ type: 'getDataRequest', stateKey: 'getGaCodeData' });
      const res = yield call(getGaCode);
      if (res.status === 'success') {
        yield put({ type: 'getDataSuccess', stateKey: 'getGaCodeData', payload: res.data });
      } else {
        yield put({ type: 'getDataFail', stateKey: 'getGaCodeData', payload: res.message });
      }
    },
    *sendBindGaEmailCode({ payload, callback, failMethod }, { call, put }) {
      yield delay(1000);
      try {
        yield put({ type: 'operateRequest', stateKey: 'sendBindGaEmailCode' });
        const res = yield call(sendBindGaEmailCode, payload);
        if (res.status === 'success') {
          message.success(localeMessage('pages.account.bindGAEmalCodeSuccess'));
          if (callback) callback();
          yield put({ type: 'operateSuccess', stateKey: 'sendBindGaEmailCode', payload: res.data });
        } else {
          if (failMethod) failMethod();
          message.error(localeMessage('pages.account.bindGAEmalCodeFailure'));
          yield put({ type: 'operateFail', stateKey: 'sendBindGaEmailCode', payload: res.message });
        }
      } catch (err) {
        if (failMethod) failMethod();
        message.error(localeMessage('pages.account.bindGAEmalCodeFailure'));
        yield put({ type: 'operateFail', stateKey: 'sendBindGaEmailCode', payload: err });
      }
    },
    *bindGa({ payload, callback }, { call, put }) {
      const { type } = payload;
      yield put({ type: 'operateRequest', stateKey: 'bindGa' });
      const res = yield call(bindGa, payload);
      if (res.status === 'success') {
        message.success(
          localeMessage(
            type === 'change' ? 'pages.account.changeGASuccess' : 'pages.account.bindGASuccess'
          )
        );
        if (callback) callback();
        yield put({ type: 'operateSuccess', stateKey: 'bindGa', payload: res.data });
      } else {
        const failureIntlKey = 'pages.account.bindGAFailure';
        let errorMsg = localeMessage(failureIntlKey);

        if (res.code !== undefined) {
          errorMsg = res.message;
        }
        message.error(errorMsg);

        yield put({ type: 'operateFail', stateKey: 'bindGa', payload: errorMsg });
      }
    },
    *toggleGa({ payload, callback }, { call, put }) {
      yield put({ type: 'operateRequest', stateKey: 'toggleGa' });
      const res = yield call(toggleGa, payload);
      if (res.status === 'success') {
        if (callback) callback();
        message.success(localeMessage('pages.account.toggleGASuccess'));
        yield put({ type: 'operateSuccess', stateKey: 'toggleGa', payload: res.data });
      } else {
        message.error(localeMessage('pages.account.toggleGAFailure'));
        yield put({ type: 'operateFail', stateKey: 'toggleGa', payload: res.message });
      }
    },
  },

  reducers: {
    getDataRequest(state, action) {
      reducerPipe(state, action, 'request');
    },
    getDataSuccess(state, action) {
      reducerPipe(state, action, 'success');
    },
    getDataFail(state, action) {
      reducerPipe(state, action, 'fail');
    },
    operateRequest(state, action) {
      reducerPipe(state, action, 'request');
    },
    operateSuccess(state, action) {
      reducerPipe(state, action, 'success');
    },
    operateFail(state, action) {
      reducerPipe(state, action, 'fail');
    },
  },
};
