/* eslint-disable */

import React, { useState, useEffect } from 'react';
import Redirect from 'umi/redirect';
import { message } from 'antd';
import { connect } from 'dva';
import get from 'lodash/get';

// @connect(({ auth }) => ({
//   token: auth.token,
// }))

const PrivateRoute = props => {
  const [hasRequest, setHasRequest] = useState(false);
  const [registerSucess, setRegisterSucess] = useState(false);
  const { match, dispatch } = props;
  const email = get(match, 'params.email', '');
  const vcode = get(match, 'params.vcode', '');

  const usrRegister = data => {
    dispatch({
      type: 'register/directRegister',
      payload: data,
      meta: resStatus => {
        const isSuccess = resStatus === 'success';
        setRegisterSucess(isSuccess);
        setHasRequest(true);
      },
    });
  };

  useEffect(() => {
    if (email && vcode) {
      usrRegister({
        email: email.toLocaleString(),
        code: vcode,
      });
    }
  }, [email, vcode]);

  const pageTo = !registerSucess ? '/?Register' : '/?Login';
  if (hasRequest) {
    // return token ? <Redirect to="/" /> : <Redirect to="/login" />;
    return <Redirect to={pageTo} />;
  }
  return <div></div>;
};
export default connect()(PrivateRoute);
// class PrivateRoute extends React.Component {
//   state = {
//     hasRequest: false,
//     registerSucess: false,
//   };

//   componentDidMount() {
//     const { match } = this.props;
//     const email = get(match, 'params.email', '');
//     const vcode = get(match, 'params.vcode', '');
//     if (email && vcode) {
//       this.usrRegister({
//         email: email.toLocaleString(),
//         code: vcode,
//       });
//     }
//   }

//   usrRegister = data => {
//     const { dispatch } = this.props;
//     dispatch({
//       type: 'register/directRegister',
//       payload: data,
//       meta: resStatus => {
//         this.setState({
//           hasRequest: true,
//           registerSucess: resStatus === 'success'
//         });
//       },
//     });
//   };

//   render() {
//     const { hasRequest,registerSucess } = this.state;
//     const pageTo = !registerSucess ? '/?Register' : '/?Login';
//     if (hasRequest) {
//       // return token ? <Redirect to="/" /> : <Redirect to="/login" />;
//       return <Redirect to={pageTo} />;
//     }
//     return <div></div>;
//   }
// }

// export default PrivateRoute;
