import { stringify } from 'qs';
import request from '@/utils/request';

// common
export async function getFiatAssert() {
  return request(`/v1/user/accounts`);
}

export async function getWithdrawnAmount() {
  return request(`/v1/user/withdraw/amount`);
}

// history
export async function getFiatDepositHistory(params) {
  return request(`/v1/fiat/user/deposit/history?${stringify(params)}`);
}

export async function getFiatWithdrawHistory(params) {
  return request(`/v1/fiat/user/withdraw/history?${stringify(params)}`);
}

// deposit
export async function agreeExemption() {
  return request('/v1/json/agree/exemption', {
    method: 'POST',
  });
}
export async function getMessage() {
  return request('/v1/fiat/user/deposit/message');
}

// three ways to deposit
export async function getDepositInfo(params) {
  return request(`/v1/matrix/bank/account/info?${stringify(params)}`);
}

// withdraw
export async function fetchFiatRule() {
  return request('/v1/fiat/user/withdraw/withdraw_prepare?currency=USD');
}

export async function sendFiatWithdrawEmailCode(data) {
  return request('/v1/vcode/fiat/withdraw', {
    method: 'POST',
    data,
  });
}

export async function fiatWithdrawSubmit(data) {
  return request('/v1/fiat/user/withdraw/submit', {
    method: 'POST',
    data,
  });
}
// 获取当前可提现额度
export async function fiatWithdrawLimit() {
  return request('/v1/fiat/user/withdraw/limit');
}


//获取用户RFQ开启状态