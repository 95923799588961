import request from '@/utils/request';

export async function bookOrder(data) {
  return request(`/v1/trade/orders`, {
    method: 'POST',
    data,
  });
}

export async function getSymbolFee(data) {
  const { symbol } = data || {};
  const url = `/v1/trade/getFeeRate?symbol=${symbol}`;
  return request(url, {
    method: 'GET',
  });
}

export async function updateOrderConfirmFlag(data) {
  return request(`/v1/user/updateOrderConfirmFlag`, {
    method: 'POST',
    data,
  });
}
