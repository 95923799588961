import { message } from 'antd';
import { localeMessage } from '@/utils/utils';
import { createOrUpdateAntiFishingCode } from '@/services/api';
import { renderStateWithKey, reducerPipe } from '@/utils/modelUtils';

const renderedState = renderStateWithKey(['createOrCreateAntiFishingCode']);

export default {
  namespace: 'antiFishing',

  state: renderedState,

  effects: {
    *operate({ payload, stateKey, callback }, { call, put }) {
      const intlKey = Object.keys(payload).length > 2 ? 'update' : 'set';
      try {
        yield put({ type: 'operateRequest', stateKey });
        const res = yield call(createOrUpdateAntiFishingCode, payload);
        if (res.status === 'success') {
          message.success(localeMessage(`antiFishing.${intlKey}.success`));
          if (callback) callback();
          yield put({ type: 'operateSuccess', stateKey, payload: res.data });
        } else {
          /*
          message.error(
            localeMessage(
              res.code ? `public.code.error.${res.code}` : `antiFishing.${intlKey}.error`
            )
          );
          */

          if (res.code !== undefined) {
            message.error(res.message);
          } else {
            message.error(localeMessage(`antiFishing.${intlKey}.error`));
          }

          yield put({ type: 'operateFail', stateKey, payload: res.code });
        }
      } catch (err) {
        message.error(localeMessage(`antiFishing.${intlKey}.error`));
        yield put({ type: 'operateFail', stateKey, payload: err });
      }
    },
  },

  reducers: {
    operateRequest(state, action) {
      reducerPipe(state, action, 'request');
    },
    operateSuccess(state, action) {
      reducerPipe(state, action, 'success');
    },
    operateFail(state, action) {
      reducerPipe(state, action, 'fail');
    },
  },
};
