import { stringify } from 'qs';
import request from '@/utils/request';

export async function withdrawAddAddress(data) {
  return request(`/v1/user/withdraw/add-withdraw-address`, {
    method: 'POST',
    data,
  });
}
export async function withdrawDeleteAddress(data) {
  return request(`/v1/user/withdraw/delete-withdraw-address`, {
    method: 'POST',
    data,
  });
}

export async function allWithdrawalApi(params) {
  return request(`/v1/user/withdraw/submit/preview?${stringify(params)}`, {
    method: 'GET',
  });
}
