import {
  getWithdrawnAmount,
  getWithdrawHistoryList,
  getWithdrawData,
  getWithdrawAddressList,
  validateWithdrawLimit,
  sendWithdrawEmailCode,
  withdrawSubmit,
} from '@/services/api';
import { showResponseError } from '@/utils/utils';
import { withdrawAddAddress, withdrawDeleteAddress, allWithdrawalApi } from '@/services/withdraw';
// import { paramStringify } from '@/utils/url';

export default {
  namespace: 'withdraw',

  state: {
    historyList: {
      items: [],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    },
    withdrawnAmount: {},
    data: {
      rule: {},
      account: {},
      currencyInfo: {},
    },
    addressList: {
      listWithdrawAddressDtoList: [],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    },
    withdrawAmountObj:{}
  },

  effects: {
    *getWithdrawnAmount({ payload }, { call, put }) {
      const response = yield call(getWithdrawnAmount, payload);
      if (response.status === 'success') {
        yield put({
          type: 'withdrawnAmount',
          payload: response.data,
        });
      } else {
        showResponseError(response);
      }
    },
    *getWithdrawData({ payload }, { call, put }) {
      const response = yield call(getWithdrawData, payload);
      if (response.status === 'success') {
        yield put({
          type: 'data',
          payload: response.data,
        });
      } else {
        showResponseError(response);
      }
    },
    *getAddressList({ payload }, { call, put }) {
      const response = yield call(getWithdrawAddressList, payload);
      if (response.status === 'success') {
        yield put({
          type: 'addressList',
          payload: response.data,
        });
      } else {
        showResponseError(response);
      }
    },
    *getWithdrawHistoryList({ payload }, { call, put }) {
      const response = yield call(getWithdrawHistoryList, payload);
      if (response.status === 'success') {
        yield put({
          type: 'historyList',
          payload: response.data,
        });
      } else {
        showResponseError(response);
      }
    },
    *validateLimit({ payload, metaSuccess }, { call }) {
      const response = yield call(validateWithdrawLimit, payload);
      if (response.status === 'success') {
        metaSuccess();
      } else {
        showResponseError(response);
      }
    },
    *submit({ payload, meta }, { call }) {
      const response = yield call(withdrawSubmit, payload);
      if (response.status === 'success') {
        meta.success();
      } else {
        showResponseError(response);
        meta.fail();
      }
    },
    *sendWithdrawEmailCode({ payload, metaSuccess, metaFail }, { call }) {
      const response = yield call(sendWithdrawEmailCode, payload);
      if (response.status === 'success') {
        metaSuccess();
      } else {
        if (metaFail) metaFail();
        showResponseError(response);
      }
    },
    *deleteAddress({ payload, meta }, { call }) {
      const response = yield call(withdrawDeleteAddress, payload);
      if (response.status === 'success') {
        meta.success();
      } else {
        meta.fail();
        showResponseError(response);
      }
    },
    *addAddress({ payload, meta }, { call }) {
      const response = yield call(withdrawAddAddress, payload);
      if (response.status === 'success') {
        meta.success();
      } else {
        showResponseError(response);
      }
    },
    *getAllWithdrawals({ payload, meta }, { call, put }){
      const response = yield call(allWithdrawalApi, payload);
      if (response.status === 'success') {
        yield put({
          type: 'setWithdrawAmountObj',
          payload: response.data,
        });
        meta.success()
      } else {
        meta.fail()
        showResponseError(response);
      }
    }
  },
  reducers: {
    minAmount(state, action) {
      return {
        ...state,
        minAmount: action.payload,
      };
    },
    withdrawnAmount(state, action) {
      return {
        ...state,
        withdrawnAmount: action.payload,
      };
    },
    data(state, action) {
      return {
        ...state,
        data: {
          rule: action.payload.rule || {},
          account: action.payload.account || {},
          currencyInfo:
            (action.payload.currencies || []).filter(
              item => item.name === action.payload.currency
            )[0] || {},
        },
      };
    },
    addressList(state, action) {
      return {
        ...state,
        addressList: action.payload,
      };
    },
    historyList(state, action) {
      return {
        ...state,
        historyList: action.payload,
      };
    },
    setWithdrawAmountObj(state, action){
      return{
        ...state,
        withdrawAmountObj:action.payload,
      }
    }
  },
};
