/* eslint-disable */
import isArray from 'lodash/isArray';
import has from 'lodash/has';
import set from 'lodash/set';

// Refer: https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket/readyState#%E5%80%BC
export const READYSTATE = {
  OPEN: window.WebSocket.OPEN,
  CONNECTING: window.WebSocket.CONNECTING,
  CLOSING: window.WebSocket.CLOSING,
  CLOSED: window.WebSocket.CLOSED,
};

/**
 * 集中处理socket消息
 */
class WebsocketIO {
  constructor() {
    this.events = {}; // 注册事件队列
    this.io = null; // websocket实例
    this.timeout = 5000;
    this.timer = null;
    this.responseTimer = null;
    this.lockReconnect = false;
  }

  /**
   * 通过事件名称监听事件
   * @param {String} event 事件名称
   * @param {Function} fn 回调
   */
  on(event, fn) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(fn);
  }

  /**
   * 发送数据到socket服务器
   * @param {Object} data 发送数据
   */
  emit(data) {
    // Fix `Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.`
    if (this._canEmit()) {
      this.io.send(JSON.stringify(data));
    }
  }

  _canEmit() {
    return this.io && this.io.readyState === READYSTATE.OPEN;
  }

  /**
   * 关闭socket通道
   */
  close() {
    try {
      while (this.io.readyState === READYSTATE.OPEN) {
        clearTimeout(this.timer);
        clearTimeout(this.responseTimer);
        this.io.close();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  /**
   * 移除监听事件
   * @param {String} event 事件名称
   * @param {Function} fn 回调函数
   */
  remove(event, fn) {
    this._remove(event, fn);
  }

  /**
   *
   * 相对on
   * @alias remove
   * @param {String} event 事件名称
   * @param {Function} fn 回调函数
   */

  off(event, fn) {
    this._remove(event, fn);
  }

  _remove(event, fn) {
    const actions = this.events[event];
    if (actions) {
      const index = actions.indexOf(fn);
      // eslint-disable-next-line eqeqeq
      if (index !== -1) {
        this.events[event] = actions
          .slice(0, index)
          .concat(actions.slice(index + 1, actions.length));
      }
    }
  }

  /**
   * 心跳检测
   */
  heartBeat() {
    clearTimeout(this.timer);
    clearTimeout(this.responseTimer);
    this.timer = setTimeout(() => {
      this.emit({ action: 'ping' });
      this.responseTimer = setTimeout(() => {
        this.connect(null, 'reconnect');
      }, this.timeout);
    }, this.timeout);
  }

  /**
   * 断线重连
   */
  reconnect() {
    if (this.lockReconnect) {
      return;
    }
    this.lockReconnect = true;
    setTimeout(() => {
      this.close();
      this.connect();
      this.lockReconnect = false;
    }, this.timeout);
  }

  /**
   * 监听socket通道建立事件
   * @param {Object} data socket通道建立时服务器下发数据,内部方法
   */
  _onopen(data) {
    const { open } = this.events;
    if (isArray(open)) {
      open.forEach(fn => {
        fn(data);
      });
    }
    this.heartBeat();
  }

  /**
   * 监听socket服务器message
   * @param {Object} signal socket服务器下发数据,内部方法
   */
  _onmessage(signal) {
    let { data } = signal;
    data = JSON.parse(data);
    if (has(data, 'pong')) {
      this.heartBeat();
    } else if (isArray(data)) {
      const [action, params] = data;
      this.events[action] &&
        this.events[action].forEach(fn => {
          fn(params);
        });
    }
  }

  /**
   * 关闭socket通道
   */
  _onclose(data) {
    const { close } = this.events;
    if (isArray(close)) {
      close.forEach(fn => {
        fn(data);
      });
    }
  }

  /**
   * socket链接发生错误
   */
  _onerror(data) {
    const { error } = this.events;
    if (isArray(error)) {
      error.forEach(fn => {
        fn(data);
      });
    }
    if (data.readyState !== READYSTATE.OPEN) {
      this.reconnect();
    }
  }

  cleanEvents() {
    delete this.events;
    this.events = {};
  }

  /**
   * 建立socket链接
   * @param {String} url websocket地址
   */
  connect(url, isReconnect) {
    if (!this.wsUrl) {
      this.wsUrl = url;
    }
    if (isReconnect !== 'reconnect' && this.io && this.io.readyState === READYSTATE.OPEN) {
      return;
    }
    this.io = new WebSocket(this.wsUrl);
    this.io.onopen = this._onopen.bind(this);
    this.io.onmessage = this._onmessage.bind(this);
    this.io.onclose = this._onclose.bind(this);
    this.io.onerror = this._onerror.bind(this);

    window['IO'] = this.io;
  }
}

export default new WebsocketIO();
