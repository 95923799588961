/* eslint-disable */
import styled from 'styled-components';

// opacity: 0.7;
const Cancel = styled.span`
  color: #3cbd72;

  cursor: pointer;
  & :hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;

export { Cancel };
