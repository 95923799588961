/* eslint-disable */

import React, { Fragment } from 'react';
import Redirect from 'umi/redirect';
import { connect } from 'dva';

@connect(({ auth }) => ({
  token: auth.token,
}))
class PrivateRoute extends React.Component {
  render() {
    const { token, children } = this.props;
    if (!token) {
      // return <Redirect to="/login" />;
      return <Redirect to="/" />;
    }
    return <Fragment>{children}</Fragment>;
  }
}

export default PrivateRoute;
