/* eslint-disable */

import get from 'lodash/get';
import keys from 'lodash/keys';
import replace from 'lodash/replace'
import { getMarketData } from '@/services/trade';
import { TIME_FRAMES } from '@/utils/trade';
import moment from 'moment-timezone';

export let handleReceive;

/**
 * tradingview datafeed class
 */
export default class DataFeed {
  constructor(options) {
    this.options = options;
  }

  save(callback){
    callback(data)
  }
  // 服务器端配置
  onReady(callback) {
    setTimeout(() => {
      callback({
        exchanges: [],
        symbols_types: [],
        support_marks: false,
        support_time: false,
        // supported_resolutions: ["S",1,60,"D"],
        supported_resolutions: keys(TIME_FRAMES),  // k线周期数组，单位为分钟，也就是分线日线等
      });
    }, 0);
  }
  
  // 数据解析
  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolvedErrorCallback) {
    const quoteScale = get(this.options, 'quoteScale', 4);
    const baseScale = get(this.options, 'baseScale', 4);
    let pricescale = parseInt(`1${'0'.repeat(quoteScale)}`);
    // let volumescale = parseInt(`1${'0'.repeat(baseScale)}`);
    pricescale = isNaN(pricescale) ? 100 : pricescale;
    // volumescale = isNaN(volumescale) ? 100 : volumescale;
    const timer = setTimeout(() => {
      const symbolInfo = {
        name: symbolName.replace('_', '/'),
        ticker: symbolName,
        description: symbolName.replace('_', '/'),
        type: 'bitcoin',
        session: '24x7',
        data_status: 'streaming',
        minmov: 1,
        pricescale,
        volume_precision: baseScale,
        has_intraday: true, //分钟数据
        // intraday_multipliers: ["1", "5", "15"],
        has_seconds: true, //秒线数据
        has_daliy: true, // 日k线数据
        has_weekly_and_monthly: true,// 月k线数据
        has_no_volume: false,

        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      onSymbolResolvedCallback(symbolInfo);
    }, 0);
  }

  /**
   * get initial data 渲染初次视图数据将K线数据渲染到页面上
   */
  getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
    let ds = [];
    let data = [];
    if (!firstDataRequest) {
      setTimeout(() => {
        onHistoryCallback([], { noData: true });
      }, 0);
    } else {
        //请求数据, 封装数据1
        //console.log("symbolInfo.ticker", symbolInfo.ticker);
        //console.log("TIME_FRAMES[resolution]", TIME_FRAMES[resolution]);
        symbolInfo.ticker = replace(symbolInfo.ticker, '/', '_');
      getMarketData(symbolInfo.ticker, TIME_FRAMES[resolution])
        // .then(response => response.json())
        .then(responseJson => {
          if (responseJson.data.bars.length > 0) {
            data = responseJson.data.bars.map(item => {
              ds = item.slice(1, -1).split(',');
              return {
                time: parseFloat(ds[0]),// 时间戳
                open: parseFloat(ds[1]),// 开
                high: parseFloat(ds[2]),// 高
                low: parseFloat(ds[3]), // 低
                close: parseFloat(ds[4]), // 收
                volume: parseFloat(ds[5]), // 量
              };
            });
            onHistoryCallback(data, { noData: false });
          } else {
            onHistoryCallback([], { noData: true });
          }
        })
        .catch(error => onHistoryCallback([], { noData: true }));
    }
  }

  /**
   * subscribe bars data update 新数据更新
   */
  subscribeBars(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) {
    handleReceive = data => {
     //onRealtimeCallback 实时回调函数,实时更新数据
    // console.log('更新时间',moment(data.time).format('YYYY-MM-DD HH:mm:ss'))
    // console.log("实时更新",data)
      onRealtimeCallback(data);
    };
  }

  /**
   * remove subscribe
   */
  unsubscribeBars(subscriberUID) {
    handleReceive = () => {};
    // console.log(subscriberUID)
  }
}
