const fallback = {
  baseMinimum: 0.001,
  baseName: '-',
  baseScale: 6,
  name: '-',
  quoteMinimum: 0.00001,
  quoteName: '-',
  quoteScale: 6,
};

export const getMatchedSymbol = (compare, symbols = []) => {
  const found = symbols.find(item => item.name === compare);
  if (found) return found;
  return fallback;
};

export default {
  getMatchedSymbol,
};
