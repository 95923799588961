import invariant from 'invariant';

const identity = k => k;

// Default suffix
const DEFAULT_SUFFIX = ['Dealing', 'Succ', 'Fail'];
// Default preprocess
const DEFAULT_PREPROCESS = [identity, identity, identity];

/* eslint-disable */
const getReducerType = (key, type) => {
  // TODO capitalize ...
  return [key, type].join('');
};
/* eslint-enable */

const isHasFieldSet = r => r !== undefined && r !== null;

// Make state
export const makeState = (name, dealing = false, data = null, error = null) => {
  const key = name.trim();
  invariant(key !== '', 'It should be a non-empty name.');

  return {
    [key]: {
      dealing,
      data,
      error,
    },
  };
};

export const makeDealingType = (key, type = DEFAULT_SUFFIX[0]) => getReducerType(key, type);
export const makeSuccType = (key, type = DEFAULT_SUFFIX[1]) => getReducerType(key, type);
export const makeFailType = (key, type = DEFAULT_SUFFIX[2]) => getReducerType(key, type);

// Make reducer
export const makeReducer = (
  name,
  opt = {
    suffix: DEFAULT_SUFFIX,
    preprocess: DEFAULT_PREPROCESS,
  }
) => {
  const key = name.trim();
  invariant(key !== '', 'It should be a non-empty name.');

  const { suffix = DEFAULT_SUFFIX, preprocess = DEFAULT_PREPROCESS } = opt;
  invariant(suffix.length === preprocess.length, 'It should be one-to-one arrays.');

  const dealingSuffix = suffix[0] || DEFAULT_SUFFIX[0];
  const succSuffix = suffix[1] || DEFAULT_SUFFIX[1];
  const failSuffix = suffix[2] || DEFAULT_SUFFIX[2];

  const dealingPreprocess = preprocess[0] || DEFAULT_PREPROCESS[0];
  const succPreprocess = preprocess[1] || DEFAULT_PREPROCESS[1];
  const failPreprocess = preprocess[2] || DEFAULT_PREPROCESS[2];

  return {
    // Dealing
    [`${key}${dealingSuffix}`]: (state, action) => {
      const nextState = makeState(key, true);
      return {
        ...state,
        ...dealingPreprocess(nextState, state, action),
      };
    },
    // Success
    [`${key}${succSuffix}`]: (state, action) => {
      const result = isHasFieldSet(action.payload) ? action.payload : '<data>';

      const nextState = makeState(key, false, result);
      return {
        ...state,
        ...succPreprocess(nextState, state, action),
      };
    },
    // Failure
    [`${key}${failSuffix}`]: (state, action) => {
      const error = isHasFieldSet(action.payload) ? action.payload : '<error>';
      const nextState = makeState(key, false, null, error);
      return {
        ...state,
        ...failPreprocess(nextState, state, action),
      };
    },
  };
};

/* render state with stateKeys */
export const renderStateWithKey = stateKeys =>
  stateKeys.reduce((acc, i) => {
    const basicState = { loading: false, loaded: false, error: null };
    if (/.*List$/.test(i)) {
      acc[i] = {
        ...basicState,
        data: {
          content: [],
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        },
      };
    } else if (/.*Detail$/.test(i) || /.*Data$/.test(i)) {
      acc[i] = {
        ...basicState,
        data: null,
      };
    } else {
      acc[i] = basicState;
    }
    return acc;
  }, {});

/*
  reducer pipe,
  return part of state according to stateKey and api status[request, success, fail]
 */
export const reducerPipe = (state, action, apiStatus = 'request', saveResponse = true) => {
  const copiedState = state;
  if (apiStatus === 'request') {
    const { stateKey } = action;
    copiedState[stateKey] = {
      ...copiedState[stateKey],
      loading: true,
      loaded: false,
      error: null,
    };
  } else if (apiStatus === 'success') {
    const { stateKey, payload } = action;
    copiedState[stateKey] = {
      ...copiedState[stateKey],
      loading: false,
      loaded: true,
      data: payload,
    };
    if (!saveResponse) delete copiedState[stateKey].data;
  } else if (apiStatus === 'fail') {
    const { stateKey, payload } = action;
    copiedState[stateKey] = {
      ...copiedState[stateKey],
      loading: false,
      loaded: true,
      error: payload,
    };
  }

  return {
    ...state,
    ...copiedState,
  };
};
