import request from '@/utils/request';

export async function getTrades() {
  return request('/v1/market/trades');
}

export async function getOursMarketPrices() {
  return request('/v1/market/prices');
}

export async function getMarketSnapshot(symbol) {
  return request(`/v1/market/snapshot/${symbol}`);
}
export async function getMarketMidpoint(symbol) {
  return request(`/v1/market/midpoint/${symbol}`);
}

export async function getMarketTicks(symbol) {
  return request(`/v1/market/ticks/${symbol}`);
}

export async function getMarketData(symbol, type) {
//  return request(`/v1/market/bars/${symbol}/${type}`);
  return request(`/v1/market/bars_kk/${symbol}/${type}`);
}

export async function getMarketTrades() {
  return request('/v1/market/symbol-names');
}
